import React from "react";
import { Form, Spinner } from "react-bootstrap";
import baseUrl from "../../../Api/baseUrl";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import csvDownload from "json-to-csv-export";
import Modal from 'react-modal';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainTemplate from "../../../components/MainTemplate";
import { Link, useNavigate } from "react-router-dom";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { customModalStyles, formatDateString, gridOptions } from "../../../utils/helperFunctin";
import Select from 'react-select';
import { get_Current_bets } from "../../../Api/api";
import ReactModal from "react-modal";
import ReactSelect from "react-select";

const Bookmaker = () => {
	const casinoRef = useRef(null)
	const bettingRef = useRef(null)
	const navigate = useNavigate()

	const token = localStorage.getItem("token");
	const user = JSON.parse(localStorage.getItem("user"))	
	const [bets, setBets] = useState([]);
	const [filter, setFilter] = useState({})
	const [modal, setModal] = useState()
	const [loader, setLoader] = useState(false)
    const [matches, setMatches] = useState([])
	const [users, setUsers] = useState([])


	const columnDefs = [
		{ headerName: 'Sr.No.', valueGetter: 'node.rowIndex + 1', width: 80 },
		{ 
			headerName: 'Selection Name',  
			cellRenderer: (params) => params?.data?.BookmakerBackupBet?.[0]?.runners.find(e => +e?.selectionId === +params?.data?.selectionId)?.runnerName,
			valueGetter: (params) => params?.data?.BookmakerBackupBet?.[0]?.runners.find(e => +e?.selectionId === +params?.data?.selectionId)?.runnerName
		},
		{ headerName: 'Match Id', width: 100, field: 'match_id' },
		{ headerName: 'Match Name', cellRenderer: (params) => params?.data?.BookmakerBackupBet?.[0]?.event.name},
		{ headerName: 'Rem', cellRenderer: (params) => params?.data?.BookmakerBackupBet?.[0]?.rem || "-"},
		{ headerName: 'Stake', width: 100, field: 'stake' },
		{ headerName: 'Odds', width: 100, field: 'odds' },
		{ headerName: 'Type', width: 100, field: 'type' },
		{ headerName: 'Status', width: 100, field: 'status' },
		{ headerName: 'Player Name', field: 'user_id.name', width: 150 },
		{ headerName: 'Username', field: 'user_id.username', width: 250 },
		{ headerName: 'Phone', field: 'user_id.phone', width: 150 },
		{ headerName: 'Email', field: 'user_id.email', width: 200 },
		{ headerName: 'Created At', field: 'createdAt', valueGetter: (params) => formatDateString(params.value) },
		{ headerName: 'Action', cellRenderer: (params) => <button className="btn btn-danger btn-sm" onClick={() => setModal(params?.data)}>Abandon</button> },
	];

	const customStyles = {
		content: {
		  top: '50%',
		  left: '50%',
		  right: 'auto',
		  bottom: 'auto',
		  marginRight: '-50%',
		  transform: 'translate(-50%, -50%)'
		},
		overlay: {
			background: "rgba(0,0,0,0.7)",
			zIndex: 999999
		}
	};

	const getData = async () => {
		try{
			setLoader(true)
			const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
			let apiUrl = (user?.type === "admin"  ? (baseUrl + get_Current_bets + "?betType=bookmaker&") : (baseUrl + "/admin/affiliate-user-currentbets?gametype=bookmaker&") )
				+ `${filter?.search ? `search=${filter?.search}&` : ""}`
				+ `${filter?.bookmakerSearch ? `bookmakerSearch=${filter?.bookmakerSearch}&` : ""}`
				+ `${filter?.matchSearch ? `matchSearch=${filter?.matchSearch}&` : ""}`
				+ `${filter?.status ? `status=${filter?.status}&` : ""}`
				+ `${filter?.match && filter?.match?.id ? `matchId=${filter?.match?.id}&` : ""}`
				+ `${filter?.user ? `userId=${filter?.user?._id}&` : ""}`
			const res = await axios.get(apiUrl, headers)
			if(res?.data?.data){
				setLoader(false)
				setBets(res?.data?.data?.reverse())
			}
		} catch(err){
			console.log(err)
			setLoader(false)
		}
	}

    // const getMatchList = async () => {
    //     try{
    //         const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
	// 		let apiUrl = baseUrl + "/admin/list_matches"
	// 		const res = await axios.get(apiUrl, headers)
	// 		if(res?.data?.data){
	// 			setLoader(false)
	// 			setMatches(res?.data?.data?.map(el => ({id: el?.event?.id, name: el?.event?.name, label:el?.event?.name })))
	// 		}
    //     } catch(err) {
    //         console.log(err)
    //     }
    // }

	const abondonBet = async betId => {
		try{
			setModal({...modal, loader: true})
			const token = localStorage.getItem("token");
			const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
			const res = await axios.post(baseUrl + "/admin/user-match-abandon", {status: "bookmaker", betId }, headers)
			if(res?.data?.data){
				toast.success('Bet Abandoned', { position: "top-center", autoClose: 2000 });
				setModal()
				getData()
			}
		} catch(err){
			console.log(err)
      		setModal({...modal, loader: false})
		}
	}
	

	const handleCSVDownload = () => csvDownload({ 
		data: bets?.map(el => ({
            id: el?._id,
            "RunnerName": el?.BookmakerBackupBet[0]?.runners.find(e => +e?.selectionId === +el?.selectionId)?.runnerName,
            "match_id": el?.match_id,
            "match Name": el?.BookmakerBackupBet[0]?.event.name,
            "stake": el?.stake,
			"odds": el?.odds,
            "type": el?.type,
            "Rem": el?.BookmakerBackupBet[0]?.rem,
            "status": el?.status,
            "name": el?.user_id?.name,
            "email": el?.user_id?.email,
            "phone": el?.user_id?.phone,
            "username": el?.user_id?.username,
            "createdAt": formatDateString(el?.createdAt)
		})),
		delimiter: ",", 
		filename:"Betkaro-Bookmaker-"+formatDateString(new Date())
	})

	const getUserData = async () => {
		try{
			const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
			let apiUrl = ""
			if(user?.type === "affiliateAdmin"){
				apiUrl = baseUrl + "/admin/affiliate-user-list?" 				
			} else {
				apiUrl = baseUrl + "/admin/all_user_list?" 
			}
			
			const res = await axios.get(apiUrl, headers)
			if(res?.data?.data){
				setUsers(res?.data?.data?.map(el => ({...el, value:el?._id, label: el?.name+", "+el?.email+", "+el?.phone+", "+el?.username})))
			}
		} catch(err){
			console.log(err)
		}
	}

	useEffect(() => {
		getData()
	}, [])

    useEffect(() => {
        // getMatchList()
		getUserData()
    }, [])

	return (
		<MainTemplate
            categoryName="Bookmaker Bets"
            categoryList={["Live Bets", "Bookmaker Bets"]}
		>
		<div>
		<div className="">
			<div className="row ml-1 mb-2">
				<div className="col-md-8 ">
					<div className="row">
						<div className="col-3">
							<label><small>Match Search</small></label>
							<input className="form-control " type="text" placeholder="Search" onChange={e => setFilter({...filter, matchSearch: e.target.value})}/>
						</div>
						<div className="col-3">
							<label><small>Bookmaker Search</small></label>
							<input className="form-control " type="text" placeholder="Search" onChange={e => setFilter({...filter, bookmakerSearch: e.target.value})}/>
						</div>
						{/* <div className="col-4">
							<label><small>Search</small></label>
							<input className="form-control " type="text" placeholder="Search" onChange={e => setFilter({...filter, search: e.target.value})}/>
						</div> */}
						{/* <div className="col-3">
							<label><small>Match Status</small></label>
                            <select className="form-control " name="status" onChange={e => setFilter({...filter, status: e.target.value})}>
                                <option value="" selected>Select Match status</option>
                                <option value="declared">Declared</option>
                                <option value="undeclared">Undeclared</option>
                            </select>
						</div> */}
						<div className="col-6">
							<label><small>Select User</small></label>
							<ReactSelect className="basic-single" isClearable={true} options={users} value={filter?.user} onChange={e => setFilter({...filter, user: e})} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}/>
						</div>
						{/* <div className="col-3">
							<label><small>Match</small></label>
							<Select className="basic-single" isClearable={true} options={matches} value={filter?.match} onChange={e => setFilter({...filter, match: e})} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}/>
						</div> */}
					</div>
				</div>
				<div className="col-md-4 d-flex align-items-end justify-content-end">
						<button type="button" className="btn btn-warning btn-sm px-5 me-1" onClick={getData}>Search</button>
						<button type="button" className="btn btn-primary btn-sm px-5" onClick={handleCSVDownload}>Export</button>
				</div>	
			</div>

			<div className="row">
				<div className="col-lg-12">
					<div className="col-lg-12 grid-margin stretch-card">
						<div className="card">
							<div className="ag-theme-alpine" style={{ width: '100%' }}>
								<AgGridReact
									columnDefs={columnDefs}
									rowData={bets}
									domLayout={gridOptions.domLayout}
									enableCellTextSelection={true}
									pagination={gridOptions.pagination}
									paginationPageSize={gridOptions.paginationPageSize}
									suppressCellSelection={true}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ToastContainer />

		<ReactModal
			isOpen={modal}
			onRequestClose={() => setModal()}
			style={customModalStyles}
		>
			<h1 className="text-center mb-4">Abandon Bet</h1>
			<h5 className="text-danger text-center">This action is irreversible</h5>
			<div className="">Runner Name: {modal?.RunnerName}</div>
			<div className="">Match Id: {modal?.match_id}</div>
			<div className="">Stake: {modal?.stake}</div>
			<div className="">Odds: {modal?.odds	}</div>
			<div className="">Type: {modal?.type}</div>
			<div className="">Status: {modal?.status}</div>
			<div className="">Player Name: {modal?.user_id?.name}</div>
			<div className="">Player Phone: {modal?.user_id?.phone}</div>
			<div className="">Player Email: {modal?.user_id?.email}</div>
			<div className="">Created At: {modal?.createdAt ? formatDateString(modal?.createdAt) : "-"}</div>

		<div className="row mt-3">
			<div className="col-6">
			<button className="btn btn-warning w-100" onClick={() => setModal()}>Cancel</button>
			</div>
			<div className="col-6">
			<button type="button" disabled={modal?.loader} onClick={() => abondonBet(modal?._id)} className="btn btn-danger w-100" >
			{
				modal?.loader
				?
				<div class="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm" role="status" />
				:
				"Abandon"
			}
			</button>
			</div>
		</div>
				
			</ReactModal>
		</div>
		</MainTemplate>
	);
};

export default Bookmaker;