import React from 'react'
import { Form } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import MainTemplate from '../../../components/MainTemplate';


function ProfitAndLoss() {
  return (
    <MainTemplate
      categoryName="Reports"
      categoryList={["Reports", "Profit & Loss"]}
    >
    <div>
      <div className="">
        <div className="row ml-1 mb-3">
          <div className="col-md">
            <Form.Group>
              <label htmlFor="exampleInputEmail1" className="col-form-label">Search By Client Name</label>
              <Form.Control type="email" className="form-control form-control-sm" id="exampleInputEmail1" placeholder="search client" />
            </Form.Group>
          </div>
          <div className="col-md">
            <Form.Group>
              <label className="col-form-label">From</label>
              <div className="">
                <DatePicker className="form-control form-control-sm w-100"
                // selected={this.state.startDate}
                // onChange={this.handleChange}
                />
              </div>
            </Form.Group>
          </div>
          <div className="col-md">
            <Form.Group>
              <label className="col-form-label">To</label>
              <div className="">
                <DatePicker className="form-control form-control-sm w-100"
                // selected={this.state.startDate}
                // onChange={this.handleChange}
                />
              </div>
            </Form.Group>
          </div>
          <div className='col-md d-flex align-items-end'>
            <button type="submit" className="btn btn-warning btn-sm ">Load</button>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="">
                  <div className="table-responsive">
                    <table className="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th> Sr.No. </th>
                          <th>Game Name </th>
                          <th> Game Type </th>
                          <th>Profit & Loss</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td> 1. </td>
                          <td> Casino</td>
                          <td> 1.211321402 </td>
                          <td> 1.211321402 </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    </MainTemplate>
  )
}

export default ProfitAndLoss