import React from "react";
import { Form, Spinner } from "react-bootstrap";
import baseUrl from "../../../Api/baseUrl";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import csvDownload from "json-to-csv-export";
import Modal from 'react-modal';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainTemplate from "../../../components/MainTemplate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { formatDateString, gridOptions } from "../../../utils/helperFunctin";
import ReactSelect from "react-select";


const UserWalletHistory = () => {

	const token = localStorage.getItem("token");
	const { state: filterUser } = useLocation();

	const [transactions, setTransactions] = useState([]);
	const [filter, setFilter] = useState({user: filterUser?filterUser:""})
	const [users, setUsers] = useState([])

	const columnDefs = [
		{ headerName: 'Sr.No.', field: 'srNo', width: 80, valueGetter: 'node.rowIndex + 1', sortable: true },
		{ headerName: 'Type', field: 'type',  width: 120, 	 sortable: true },
		{ headerName: 'Amount', field: 'amount',  width: 120, sortable: true },
		{ 
			headerName: 'Collected %', 
			width: 120, 
			cellRenderer: (params) => params?.data?.collectedAmount ? params?.data?.collectedAmount + "%" : "",  
			valueGetter: (params) => params?.data?.collectedAmount ? params?.data?.collectedAmount + "%" : "",  
			sortable: true 
		},
		{ 
			headerName: 'Collected rate',
			width: 150,  
			cellRenderer: (params) => (
				params?.data?.amount && params?.data?.collectedAmount
				? 
				Number(parseFloat(params?.data?.amount * params?.data?.collectedAmount /100)?.toFixed(2))
				: 
				""
			), 
			valueGetter: (params) => (
				params?.data?.amount && params?.data?.collectedAmount
				? 
				 Number(parseFloat(params?.data?.amount * params?.data?.collectedAmount /100)?.toFixed(2))
				: 
				""
			),  
			sortable: true 
		}, // collectedAmount key
		{ headerName: 'Balance', field: 'balance',  width: 150, sortable: true },
		{ headerName: 'Agent Username', cellRenderer: params => params?.data?.agentDetails?.username || "-", valueGetter: params => params?.data?.agentDetails?.username || "-" },
		{ headerName: 'Agent Name', cellRenderer: params => params?.data?.agentDetails?.name || "-", valueGetter: params => params?.data?.agentDetails?.name || "-" },
		{ headerName: 'Agent Phone', cellRenderer: params => params?.data?.agentDetails?.phone || "-", valueGetter: params => params?.data?.agentDetails?.phone || "-"},
		{ headerName: 'Source', field: 'source',  sortable: true },
		{ headerName: 'Description', field: 'description',  sortable: true },
		{
			headerName: 'Created At', 
			field: 'timestamp', 
			valueGetter: (params) => params.data?.timestamp ? formatDateString(params.data?.timestamp) : "",
			cellRenderer: (params) => formatDateString(params?.data?.timestamp)
		}
	]
	  
	const getData = async () => {
		try{
			const headers = { 
				headers: { "Content-Type": "application/json", 
				"x-access-token": token,
			}}
			const res = await axios.get(
				baseUrl + "/admin/user-wallet-history?" 
				+ `${filter?.user?._id ? `userId=${filter?.user?._id}&` : ""}`
				+ `${filter?.dateFrom ? `dateFrom=${filter?.dateFrom}&` : ""}`
				+ `${filter?.dateTo ? `dateTo=${filter?.dateTo}&` : ""}`
				+ `${filter?.minAmount ? `minAmount=${filter?.minAmount}&` : ""}`
				+ `${filter?.maxAmount ? `maxAmount=${filter?.maxAmount}&` : ""}`
				, headers
			)
			if(res?.data?.data){
				setTransactions(res?.data?.data?.[0])
			}
		} catch(err){
			console.log(err)
		}
	}

    const getUsersData = async () => {
		try{
      const token = localStorage.getItem("token");
			const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
			const res = await axios.get(baseUrl + "/admin/all_user_list", headers)
			if(res?.data?.data){
				setUsers(res?.data?.data?.reverse()?.map(el => ({...el, value:el?._id, label: el?.name+", "+el?.email+", "+el?.phone})))
			}
		} catch(err){
			console.log(err)
		}
	}

	const handleCSVDownload = () => filter?.user?._id
	?
	csvDownload({ 
		data: transactions?.transactions?.map(el => ({
			username: filter?.user?.username,
			name: filter?.user?.name,
			phone: filter?.user?.phone,
			email: filter?.user?.email,
			userId: filter?.user?._id,
			type: el?.type,
			amount: el?.amount,
			"collected_%": el?.collectedAmount ? el?.collectedAmount + "%" : "-",
			collected_rate: el?.amount && el?.collectedAmount
				? 
				Number(parseFloat(el?.amount * el?.collectedAmount /100)?.toFixed(2))
				: 
				"",
			balance: el?.balance,
			agentName: el?.agentDetails?.name,
			agentUsername: el?.agentDetails?.username,
			agentPhone: el?.agentDetails?.phone,
			source: el?.source,
			createdAt: formatDateString(el?.timestamp)
		})),
		delimiter: ",", 
		filename:`Betkaro-User-${filter?.user?.username}-${filter?.user?.phone}-${filter?.user?.email}-`+formatDateString(new Date())
	})
	:
	""

	useEffect(() => {
		getData()
	}, [])

    useEffect(() => {
        getUsersData()
    }, [])

	return (
		<MainTemplate
			categoryName="User Wallet History"
			categoryList={["Manage Users", "User Wallet History"]}
		>
			<div>
				<div className="">
					<div className="row ml-1 mb-2">
						<div className="col-3 mb-1">
							<label><small>Search User</small></label>
							<ReactSelect className="basic-single w-100" isClearable={true} options={users} value={filter?.user} onChange={e => setFilter({...filter, user: e})}/>
						</div>
						<div className="col-md-3">
							<label><small>Wallet Balance</small></label>
							<div className="d-flex">
								<input className="form-control form-control-sm me-1" type="number" placeholder="Min Balance" onChange={e => setFilter({...filter, minAmount: e.target.value})}/>									
								<input className="form-control form-control-sm" type="number" placeholder="Max Balance" onChange={e => setFilter({...filter, maxAmount: e.target.value})}/>									
							</div>
						</div>
						<div className="col-md-3">
							<div className="row">
								<label className="col-6"><small>Date From</small></label>
								<label className="col-6"><small>Date To</small></label>
							</div>
							<div className="d-flex">
								<input className="form-control form-control-sm" type="date" max={new Date().toISOString().split('T')[0]} onChange={e => setFilter({...filter, dateFrom: e.target.value})}/>
								<input className="form-control form-control-sm" type="date" max={new Date().toISOString().split('T')[0]} onChange={e => setFilter({...filter, dateTo: e.target.value})}/>
							</div>
						</div>
						<div className="col-md-3 d-flex align-items-end justify-content-end pb-2">
							<button type="button" className="btn btn-warning btn-sm w-50 me-2" onClick={getData}>Search</button>
							<button type="button" className="btn btn-primary btn-sm w-50 me-2" onClick={handleCSVDownload}>Export</button>
						</div>
						
						
					</div>

					<div className="row">
						<div className="col-lg-12">
							<div className="col-lg-12 grid-margin stretch-card">
								<div className="card">
									<div className="ag-theme-alpine affiliateAgent" style={{ width: '100%' }}>
										<AgGridReact
											columnDefs={columnDefs}
											rowData={transactions?.transactions || []}
											rowClassRules={ {'locked': params => params?.data?.status === "LOCKED"}}
											domLayout={gridOptions.domLayout}
											enableCellTextSelection={true}
											pagination={gridOptions.pagination}
											paginationPageSize={gridOptions.paginationPageSize}
											suppressCellSelection={true}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ToastContainer />
			</div>
		</MainTemplate>
	);
};

export default UserWalletHistory;
