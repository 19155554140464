import React, {useRef} from "react";
import { Button, Form } from "react-bootstrap";
import baseUrl from "../../../Api/baseUrl";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import csvDownload from "json-to-csv-export";
import MainTemplate from "../../../components/MainTemplate";
import { withdraRequest, withdraRequestUpdate, withdraw_image_upload } from "../../../Api/api";
import { toast } from "react-toastify";
import { customModalStyles, formatDateString, gridOptions } from "../../../utils/helperFunctin";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import ReactModal from "react-modal";
import ReactSelect from "react-select";

const WithdrawalList = () => {

	const statusList = [
		{value: "pending", label: "Pending"},
		{value: "approved", label: "Approved"},
		{value: "rejected", label: "Rejected"}
	]

    const [users, setUsers] = useState([])
    const [selectedUserId, setSelectedUserId] = useState("")
    const [withdraRequestListData, setWithdraRequestListData] = useState({data: [],loader: false});
	const [status, setStatus] = useState("pending")
	const [modal, setModal] = useState()
	const [filter, setFilter] = useState({status: statusList?.[0]})

    const fileInputRef = useRef(null);

	const columnDefs = [
		{ headerName: 'Sr.No.', valueGetter: 'node.rowIndex + 1', width: 80 },
		{ headerName: 'UserName', field: 'user_id.username' },
		{ headerName: 'Name', field: 'user_id.name', width: 170  },
		{ headerName: 'Email', field: 'user_id.email' },
		{ headerName: 'Phone', field: 'user_id.phone', width: 130 },
		{ headerName: 'Withdrawn Amount', field: 'amount', width: 170 },
		{ headerName: 'Type', field: 'type' },
		{ headerName: 'CreatedAt', field: 'createdAt', valueGetter: (params) => formatDateString(params.data?.createdAt) },
		{ headerName: 'Status', field: 'status' },
		{
			headerName: 'Image',
			field: 'pow_image',
			cellRenderer: (params) => (
				params.data.pow_image 
				? 
				<img src={params.data.pow_image} width='100px' height='100px' onClick={() => setModal({...params?.data,index: params.rowIndex})} /> 
				: 
				<p className="text-danger">Upload Required</p>
			),
		},
		{
		  headerName: 'Upload',
		  field: 'upload',
		  cellRenderer: (params) => (
			<div onClick={() => setModal({...params?.data, index: params.rowIndex})}>
				<button className="btn btn-warning">Update</button>
			</div>
		  ),
		},
	];

    const handleGetWithdrawal = async ( ) => {
        try {
            setWithdraRequestListData({ ...withdraRequestListData, loader: true })
            const token = localStorage.getItem("token");
            const responce = await axios.get(
				baseUrl + "/admin/all_user_withdrawals?"
				+ `${filter?.search ? `search=${filter?.search}&` : ""}`
				+ `${filter?.dateFrom ? `dateFrom=${filter?.dateFrom}&` : ""}`
				+ `${filter?.dateTo ? `dateTo=${filter?.dateTo}&` : ""}`
				+ `${filter?.status ? `status=${filter?.status?.value}&` : ""}`
				+ `${filter?.minAmount ? `minAmount=${filter?.minAmount}&` : ""}`
				+ `${filter?.maxAmount ? `maxAmount=${filter?.maxAmount}&` : ""}`
				+ `${filter?.user ? `user=${filter?.user?._id}&` : ""}`
				, { headers: { "x-access-token": token, }, 
			})
            if (responce.status == 200) {
                setWithdraRequestListData({ ...withdraRequestListData, data: responce?.data?.data?.reverse(), loader: false })
            }

        } catch (error) {
            console.log(error, 'error');
            setWithdraRequestListData({ ...withdraRequestListData, loader: false })
        }
    }

	const getUsersData = async () => {
		try{
            const token = localStorage.getItem("token");
			const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
			const res = await axios.get(baseUrl + "/admin/all_user_list", headers)
			if(res?.data?.data){
				setUsers(res?.data?.data?.reverse()?.map(el => ({...el, value:el?._id, label: el?.name+", "+el?.email+", "+el?.phone})))
			}
		} catch(err){
			console.log(err)
		}
	}

    const handleApprove = async status => {
		if(!status){
			return toast.error("Status required", { position: "top-center", autoClose: 2000 });
		}
        try {
            if(status === "approved" && !(modal?.image || modal?.pow_image)){
                return toast.error('Image Upload required', { position: "top-center", autoClose: 2000 });
            }
            const token = localStorage.getItem("token");
            const headers = {headers: {"Content-Type": "application/json","x-access-token": token,},};
            const responce = await axios.put(baseUrl + withdraRequest + `/${modal?._id}` + "/" + (status ? status: ""), {}, headers);
            if (responce.status === 200) {
                handleGetWithdrawal();
                if(status === "rejected"){
					toast.success('Rejected ', { position: "top-center", autoClose: 2000 });
				} else if(status === "approved"){
					toast.success('Approved ', { position: "top-center", autoClose: 2000 });
				}
                setModal()
            }
        } catch (error) {
            handleGetWithdrawal();
            toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
        }
    };

    const handleUpload = async (file) => {
        try {
            if (file) {
                setModal({ ...modal, image: file, loader: true })
                const id = modal._id;
                const token = localStorage.getItem("token");
                const headers = {headers: {"x-access-token": token}};

                const formdata = new FormData()
                formdata.append('pow_image', file)
                const responce = await axios.post(baseUrl + withdraw_image_upload + `/${id}`, formdata, headers);
                if (responce.status === 200) {
                    toast.success('uploaded', { position: "top-center", autoClose: 2000 });
                    setModal({ ...modal, image: file, loader: false })
                }
            } else {
                toast.error('Please Select Image', { position: "top-center", autoClose: 2000 });
            }
        } catch (error) {
            toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
            setModal({ ...modal, loader: false })
        }
    }

	const handleCSVDownload = () => csvDownload({ 
		data: withdraRequestListData?.data?.map(el => ({
			username: el?.user_id?.username ? el?.user_id?.username : "-",
			name: el?.user_id?.name ? el?.user_id?.name : "-",
			email: el?.user_id?.email ? el?.user_id?.email : "-",
			phone: el?.user_id?.phone ? el?.user_id?.phone : "-",
			depositAmount: el?.amount,
			type: el?.type,
			status: el?.status,
			BankAccountName: el?.bankDetails?.accountHolderName,
			accountNumber: el?.bankDetails?.accountNumber,
			bankName: el?.bankDetails?.bankname,
			branch: el?.bankDetails?.branch,
			ifsc: el?.bankDetails?.ifsc,
			remarks: el?.bankDetails?.remarks,
			createdAt: formatDateString(el?.createdAt)
		})) ,delimiter: ",", filename:"Betkaro-Withdrawls-"+status+"-"+formatDateString(new Date()) 
	})

	useEffect(() => {
        getUsersData()
		handleGetWithdrawal()  
    }, []);

    // useEffect(() => {
		  
    // }, [filter]);

	return (
		<MainTemplate
			categoryName="Withdrawal List"
			categoryList={["Finance", "Withdrawal List"]}
    	>
		<div>
		<div className="">
			{/* <div className="row ml-1 mb-2">
				<div className="col-lg-4">
					<select onChange={e => setStatus(e.target.value)} className="form-control form-control-sm" id="exampleFormControlSelect3">
						<option value="pending" selected>Pending</option>
						<option value="approved">Approved</option>
						<option value="rejected">Rejected</option>
					</select>
				</div>
				<div className="col-lg-4">
					<select onChange={e => setSelectedUserId(e.target.value || "")} className="form-control form-control-sm" id="exampleFormControlSelect3">
						<option value="" selected>Select User</option>
						{
							users?.map((user, index) => <option value={user?._id} index={index}>{user?.name} - {user?.email} - {user?.phone}</option>)
						}
					</select>
				</div>
				<div className="col-lg d-flex align-items-end">
					<button type="button" className="btn btn-warning btn-sm  btn-fw" onClick={() => csvDownload({ data: withdraRequestListData?.data?.map(el => ({
						username: el?.user_id?.username ? el?.user_id?.username : "-",
						name: el?.user_id?.name ? el?.user_id?.name : "-",
						email: el?.user_id?.email ? el?.user_id?.email : "-",
						phone: el?.user_id?.phone ? el?.user_id?.phone : "-",
						withdrawnAmount: el?.amount,
						type: el?.type,
						status: el?.status,
						type: el?.type,
						createdAt: new Date(el?.createdAt).toLocaleString()
					})) ,delimiter: ",", filename:"Betkaro-Withdrawals-"+status+"-"+(new Date()).toLocaleString() })}>Export</button>
				</div>
			</div> */}

			<div className="row mb-3">
				<div className="col-3 mb-1">
					<label><small>Search</small></label>
					<input className="form-control form-control-sm" type="text" placeholder="Search" onChange={e => setFilter({...filter, search: e.target.value})}/>
				</div>
				<div className="col-3 mb-1">
					<label><small>Date From</small></label>
					<input className="form-control form-control-sm" type="date" max={new Date().toISOString().split('T')[0]} onChange={e => setFilter({...filter, dateFrom: e.target.value})}/>
				</div>
				<div className="col-3 mb-1">
					<label><small>Date To</small></label>
					<input className="form-control form-control-sm" type="date" max={new Date().toISOString().split('T')[0]} onChange={e => setFilter({...filter, dateTo: e.target.value})}/>
				</div>
				<div className="col-3 mb-1">
					<label><small>Withdraw Amount</small></label>
					<div className="d-flex">
						<input className="form-control form-control-sm me-1" type="number" placeholder="Min" onChange={e => setFilter({...filter, minAmount: e.target.value})}/>									
						<input className="form-control form-control-sm" type="number" placeholder="Max" onChange={e => setFilter({...filter, maxAmount: e.target.value})}/>									
					</div>
				</div>
				<div className="col-3 mb-1">
					<label><small>Select Status</small></label>
					<ReactSelect options={statusList} defaultValue={statusList?.[0]} className="basic-single" value={filter?.status} onChange={e => setFilter({...filter, status: e})} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}} />						
				</div>
				<div className="col-3 mb-1">
					<label><small>Select User</small></label>
					<ReactSelect className="basic-single" isClearable={true} options={users} value={filter?.user} onChange={e => setFilter({...filter, user: e})} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}} />
				</div>
				<div className="col-3 mb-1 d-flex align-items-end justify-content-end">
					<button type="button" className="btn btn-warning btn-sm w-100" onClick={handleGetWithdrawal}>Search</button>
				</div>
				<div className="col-3 mb-1 d-flex align-items-end justify-content-end">
					<button type="button" className="btn btn-primary btn-sm w-100" onClick={handleCSVDownload}>Export</button>
				</div>
			</div>

			<div className="row">
			<div className="col-lg-12">
				<div className="col-lg-12 grid-margin stretch-card">
				<div className="card">

					<div className="ag-theme-alpine" style={{ width: '100%' }}>
						<AgGridReact
							columnDefs={columnDefs}
							rowData={withdraRequestListData?.data}
							domLayout={gridOptions.domLayout}
							enableCellTextSelection={true}
							pagination={gridOptions.pagination}
							paginationPageSize={gridOptions.paginationPageSize}
							suppressCellSelection={true}
							rowHeight={100}
						/>
					</div>
				</div>
				</div>
			</div>
			</div>
		</div>
		</div>
		<ReactModal
			isOpen={modal}
			onRequestClose={() => setModal()}
			style={customModalStyles}
			contentLabel="Example Modal"
		>
			<h1 className="text-center mb-5">Update Withdraw</h1>
			<div className="row">
				<div className="col-6">
					<div className="mb-2 fw-bold">User Details</div>
					<div className="mb-2">Id: {modal?.user_id?._id}</div>
					<div className="mb-2">Name: {modal?.user_id?.name}</div>
					<div className="mb-2">Email: {modal?.user_id?.email}</div>
					<div className="mb-2">Phone: {modal?.user_id?.phone}</div>
					<div className="mb-2">Username: {modal?.user_id?.username}</div>
				</div>
				<div className="col-6">
					<div className="mb-2 fw-bold">Bank Details</div>
					<div className="mb-2">Bank Holder Name: {modal?.bankDetails?.accountHolderName}</div>
					<div className="mb-2">Account Number: {modal?.bankDetails?.accountNumber}</div>
					<div className="mb-2">Bank Name: {modal?.bankDetails?.bankname}</div>
					<div className="mb-2">Bank Branch: {modal?.bankDetails?.branch}</div>
					<div className="mb-2">Bank IFSC: {modal?.bankDetails?.ifsc}</div>
					<div className="mb-2">Remarks: {modal?.bankDetails?.remarks}</div>
				</div>
			</div>
			<hr />
			<div className="row">
				<div className="col-6">
					<div className="mb-4">Withdraw amount: <span className="fw-bold">{modal?.amount}</span></div>
				</div>
				<div className="col-6 position-relative">
					<input id="file-input" ref={fileInputRef} style={{ position: 'absolute',opacity: 0 }} type="file" onChange={e => handleUpload(e.target?.files?.[0])} />
					{
						modal?.image || modal?.pow_image
						?
						<img onClick={e => fileInputRef?.current?.click()} src={modal?.image ? URL?.createObjectURL(modal?.image) : modal?.pow_image} width={150} />
						:
						<button className="btn btn-warning" onClick={e => fileInputRef?.current?.click()}>Upload</button>
					}
				</div>
			</div>
			<p className="fw-bold"> Select Status</p>
			<select onChange={e => handleApprove(e.target.value)} className="form-control form-control-sm mt-2 p-2 border border-warning" id="exampleFormControlSelect3">
				<option value="" selected>Select Status</option>
				<option value="approved">Approved</option>
				<option value="rejected">Rejected</option>
			</select>
			{/* <button type="button" disabled={modal?.loader} className="btn btn-warning w-100" 
			onClick={() => handleApprove()}
			>
				{
					modal?.loader
					?
					<div class="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm" role="status" />
					:
					"Approve"
				}
			</button> */}
		</ReactModal>
		</MainTemplate>
	);
};

export default WithdrawalList;
