import React from "react";
import { Button, Form } from "react-bootstrap";
import baseUrl from "../../../Api/baseUrl";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import csvDownload from "json-to-csv-export";
import MainTemplate from "../../../components/MainTemplate";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { customModalStyles, formatDateString, gridOptions } from "../../../utils/helperFunctin";
import { AgGridReact } from "ag-grid-react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import ReactSelect from "react-select";

const DepositList = () => {
	const token = localStorage.getItem("token");
	const user = useSelector((state) => state.userReducer);
	const statusList = [
		{value: "pending", label: "Pending"},
		{value: "approved", label: "Approved"},
		{value: "rejected", label: "Rejected"}
	]

	const [data, setData] = useState([]);
	const [loader, setIsLoading] = useState(false)
	const [search, setSearch] = useState("")
	const [selectedUserId, setSelectedUserId] = useState("")
	const [users, setUsers] = useState([])
	const [status, setStatus] = useState("pending")
	const [modal, setModal] = useState()
	const [filter, setFilter] = useState({status: statusList?.[0]})

	const getData = async () => {
		try{
			const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
			const res = await axios.get(
				baseUrl + "/admin/all_user_deposit?"
				+ `${filter?.search ? `search=${filter?.search}&` : ""}`
				+ `${filter?.dateFrom ? `dateFrom=${filter?.dateFrom}&` : ""}`
				+ `${filter?.dateTo ? `dateTo=${filter?.dateTo}&` : ""}`
				+ `${filter?.status ? `status=${filter?.status?.value}&` : ""}`
				+ `${filter?.minAmount ? `minAmount=${filter?.minAmount}&` : ""}`
				+ `${filter?.maxAmount ? `maxAmount=${filter?.maxAmount}&` : ""}`
				+ `${filter?.user ? `user=${filter?.user?._id}&` : ""}`
				, headers
			)
			if(res?.data?.data){
				setData(res?.data?.data?.reverse())
			}
		} catch(err){
			console.log(err)
		}
	}
	const getUsersData = async () => {
		try{
			setIsLoading(true)
            const token = localStorage.getItem("token");
			const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
			const res = await axios.get(baseUrl + "/admin/all_user_list", headers)
			if(res?.data?.data){
				setUsers(res?.data?.data?.reverse()?.map(el => ({...el, value:el?._id, label: el?.name+", "+el?.email+", "+el?.phone})))
			}
			setIsLoading(false)
		} catch(err){
			setIsLoading(false)
			console.log(err)
		}
	}
	const handleAdminApprove = async (request, status) => {
		if(!status){
			return toast.error("Status required", { position: "top-center", autoClose: 2000 });
		}
		try {
		  const id = request._id;
		  const token = localStorage.getItem("token");
		  const headers = {
			headers: {
			  "Content-Type": "application/json",
			  "x-access-token": token,
			},
		  };
		  const responce = await axios.put(`${baseUrl}/admin/payment_request_update/${id}/` + (status? status : ""), {}, headers);
		  if (responce?.status == 200) {
			getData()
			if(status === "rejected"){
				toast.success('Rejected ', { position: "top-center", autoClose: 2000 });
			} else if(status === "approved"){
				toast.success('Approved ', { position: "top-center", autoClose: 2000 });
			}
		  } else {
		  }
		} catch (error) {
		  toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
		}
	};

	const handleCSVDownload = () => csvDownload({ 
		data: data?.map(el => ({
			username: el?.user_id?.username ? el?.user_id?.username : "-",
			name: el?.user_id?.name ? el?.user_id?.name : "-",
			email: el?.user_id?.email ? el?.user_id?.email : "-",
			phone: el?.user_id?.phone ? el?.user_id?.phone : "-",
			depositAmount: el?.amount,
			type: el?.type,
			status: el?.status,
			createdAt: formatDateString(el?.createdAt)
		})) ,delimiter: ",", filename:"Betkaro-Deposits-"+status+"-"+formatDateString(new Date()) 
	})

	const columnDefs = [
		{ headerName: 'Sr.No.', valueGetter: 'node.rowIndex + 1', width: 80 },
		{ headerName: 'UserName', field: 'user_id.username', },
		{ headerName: 'Name', field: 'user_id.name', },
		{ headerName: 'Email', field: 'user_id.email', },
		{ headerName: 'Phone', field: 'user_id.phone', },
		{ headerName: 'Deposit Amount', field: 'amount', },
		{ headerName: 'Type', field: 'type', },
		{ headerName: 'Status', field: 'status', },
		{ 
			headerName: 'Created At', 
			field: 'createdAt',  
			valueGetter: (params) => formatDateString(params.data?.createdAt)},
		{
			headerName: 'Image',
			field: 'image',
			rowHeight: 150,
			cellRenderer: (params) => {
				return <img src={params.value} width='100px' height='100px' alt="User" onClick={() => setModal(params?.value)} />;
			}
		},
		{
		  headerName: 'Action',
		  field: 'status',
		  cellRenderer: (params) => {
			return params.value === 'approved'
				? <div><Button className="bg-success">Approved</Button></div>
				: (
					<select onChange={(e) => handleAdminApprove(params.data, e.target.value)} className="form-control form-control-sm" id="exampleFormControlSelect3">
						<option value="" selected>Select Status</option>
						<option value="approved">Approved</option>
						<option value="rejected">Rejected</option>
					</select>
				);
			}
		}
	];

	useEffect(() => {
		getData()
		getUsersData()
	}, [])
	

	return (
		<MainTemplate
			categoryName="Deposit List"
			categoryList={["Finance", "Deposit List"]}
		>
		<div>
		<div className="">
			<div className="row mb-3">
				<div className="col-3 mb-1">
					<label><small>Search</small></label>
					<input className="form-control form-control-sm" type="text" placeholder="Search" onChange={e => setFilter({...filter, search: e.target.value})}/>
				</div>
				<div className="col-3 mb-1">
					<label><small>Date From</small></label>
					<input className="form-control form-control-sm" type="date" max={new Date().toISOString().split('T')[0]} onChange={e => setFilter({...filter, dateFrom: e.target.value})}/>
				</div>
				<div className="col-3 mb-1">
					<label><small>Date To</small></label>
					<input className="form-control form-control-sm" type="date" max={new Date().toISOString().split('T')[0]} onChange={e => setFilter({...filter, dateTo: e.target.value})}/>
				</div>
				<div className="col-3 mb-1">
					<label><small>Deposit Amount</small></label>
					<div className="d-flex">
						<input className="form-control form-control-sm me-1" type="number" placeholder="Min" onChange={e => setFilter({...filter, minAmount: e.target.value})}/>									
						<input className="form-control form-control-sm" type="number" placeholder="Max" onChange={e => setFilter({...filter, maxAmount: e.target.value})}/>									
					</div>
				</div>
				<div className="col-3 mb-1">
					<label><small>Select Status</small></label>
					<ReactSelect options={statusList} defaultValue={statusList?.[0]} className="basic-single" value={filter?.status} onChange={e => setFilter({...filter, status: e})} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}} />
				</div>
				<div className="col-3 mb-1">
					<label><small>Select User</small></label>
					<ReactSelect className="basic-single" isClearable={true} options={users} value={filter?.user} onChange={e => setFilter({...filter, user: e})} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}} />
				</div>
				<div className="col-3 mb-1 d-flex align-items-end justify-content-end">
					<button type="button" className="btn btn-warning btn-sm w-100" onClick={getData}>Search</button>
				</div>
				<div className="col-3 mb-1 d-flex align-items-end justify-content-end">
					<button type="button" className="btn btn-primary btn-sm w-100" onClick={handleCSVDownload}>Export</button>
				</div>
			</div>

			<div className="row">
			<div className="col-lg-12">
				<div className="col-lg-12 grid-margin stretch-card">
				<div className="card">
					<div className="">
						<div className="ag-theme-alpine" style={{ width: '100%' }}>
							<AgGridReact
								columnDefs={columnDefs}
								rowData={data}
								domLayout={gridOptions.domLayout}
								enableCellTextSelection={true}
								pagination={gridOptions.pagination}
								paginationPageSize={gridOptions.paginationPageSize}
								suppressCellSelection={true}
								rowHeight={100}
							/>
						</div>
					</div>
				</div>
				</div>
			</div>
			</div>
		</div>
		</div>

		<ReactModal
			isOpen={modal}
			onRequestClose={() => setModal()}
			style={customModalStyles}
			contentLabel="Example Modal iamge"
		>
			<div className="d-flex align-items-center justify-content-center">
				<img src={modal} />
			</div>
		</ReactModal>
		</MainTemplate>
	);
};

export default DepositList;
