import React, { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import logo from "../../assets/images/betkarologo.svg"
import axios from 'axios';
import { useState } from 'react';
import baseUrl from '../../Api/baseUrl';
import { loginPath } from '../../Api/api';
import { useLocation} from "react-router-dom"
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { addUser } from '../../redux/reducers/userSlice';

const Login = () => {

	const navigate = useNavigate();
    const dispatch = useDispatch();

	const [user, setUser] = useState({ username: "", password: "", loader: false })
	const [userError, setUserError] = useState({ username: "", password: "" })
	const [PasswordVisible, setPasswordVisible] = useState("no");

	const handleChange = (e) => {
		setUser({ ...user, [e.target.name]: e.target.value })
		setUserError({ ...userError, [e.target.name]: null })

	}

	const handleValidation = () => {
		const newErrors = {}
		if (user.username == '') {
			newErrors.username = 'please enter username'
		}
		if (user.password == '') {
			newErrors.password = 'please enter password'
		}
		return newErrors
	}
	const login = async (e) => {
		e.preventDefault()
		const handleValidationObject = handleValidation();
		if (Object.keys(handleValidationObject).length > 0) {
			setUserError(handleValidationObject);
		} else {
			setUser({ ...user, loader: true })
			try {
				const res = await axios.post(baseUrl + loginPath, user)
				if (res?.status == 200) {
					setUser({ ...user, loader: false })
					const user1 = res?.data?.data
                    dispatch(addUser(user1));
					toast.success('login success', { position: "top-center", autoClose: 2000 });
					// setTimeout(() => {
						localStorage.setItem("token", res?.data?.data?.token)
						localStorage.setItem("user", JSON.stringify(user1))
					// }, 1000);
				}
			} catch (error) {
				setUser({ ...user, loader: false })
				toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
			}
		}
	}

	const handlePasswordVisible = () => {
		setPasswordVisible("no");
	};
	const handlePasswordNotVisible = () => {
		setPasswordVisible("yes");
	};

	return (
		<div>
			<ToastContainer />
			<div className="d-flex align-items-center auth px-0 bg-light" style={{height: "100vh"}}>
				<div className="row w-100 mx-0">
					<div className="col-lg-4 mx-auto">
						<div className="auth-form-light text-left py-5 px-4 px-sm-5 bg-light card shadow">
							<div className="brand-logo d-flex justify-content-center mb-5">
								<img src={logo} alt="logo" />
							</div>
							<h4>Hello! let's get started</h4>
							<h6 className="font-weight-light">Sign in to continue.</h6>
							<Form className="pt-3" onSubmit={login}>
								<Form.Group className="search-field mb-3">
									<Form.Control autoComplete='off' name='username' onChange={handleChange} type="text" placeholder="Username" size="lg" className={`h-auto ${userError.username ? 'border border-danger' : ''}`} />
									<span className='text-danger' >{userError.username}</span>
								</Form.Group>
								<div className='d-flex w-100' >
									<div className='col-12 p-0' >
										<Form.Group className="">
											<Form.Control
												type={
													PasswordVisible == "yes" ? "text" : "password"
												}
												autoComplete='off' name='password' onChange={handleChange} placeholder="Password" size="lg" className={`h-auto ${userError.username ? 'border border-danger' : ''}`} />
											<span className='text-danger' >{userError.password}</span>
										</Form.Group>
									</div>
									<div className='col-0 mt-3' >
										{PasswordVisible == "no" && (
											<div
												onClick={handlePasswordNotVisible}
												style={{
													position: "relative",
													right: "30px",
													top: "0px",
												}}
											>
												<AiOutlineEyeInvisible
													size={20}
												></AiOutlineEyeInvisible>
											</div>
										)}
										{PasswordVisible == "yes" && (
											<div
												onClick={handlePasswordVisible}
												style={{
													position: "relative",
													right: "30px",
													top: "0px",
												}}
											>
												<AiOutlineEye size={20}></AiOutlineEye>
											</div>
										)}
									</div>
								</div>
								<div className="mt-3">
									<button className="btn w-100 btn-warning font-weight-medium auth-form-btn">SIGN IN
										{
											user.loader && <div class="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm" role="status"></div>
										}
									</button>
								</div>
							</Form>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login
