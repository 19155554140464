import React from "react";
import { Form, Spinner } from "react-bootstrap";
import baseUrl from "../../Api/baseUrl";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import csvDownload from "json-to-csv-export";
import Modal from 'react-modal';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainTemplate from "../../components/MainTemplate";
import { Link, useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { formatDateString, gridOptions } from "../../utils/helperFunctin";


const AffiliateProgram = () => {

	const navigate = useNavigate()
	const token = localStorage.getItem("token");

	const [users, setUsers] = useState([]);
	const [filter, setFilter] = useState({})
	const [modal, setModal] = useState({show: false, data: {}})
	const [loader, setLoader] = useState(false)

	const customStyles = {
		content: {
		  top: '50%',
		  left: '50%',
		  right: 'auto',
		  bottom: 'auto',
		  marginRight: '-50%',
		  transform: 'translate(-50%, -50%)'
		},
		overlay: {
			background: "rgba(0,0,0,0.7)",
			zIndex: 999999
		}
	};

	const showTransaction = (agent) => navigate("/agent-wallet-history", {
		state: {
			...agent, 
			value: agent?._id, 
			label: agent?.name+", "+agent?.email+", "+agent?.phone
		}
	})

	const showUsers = (user) => navigate("/users-list", {
		state: {
			...user, 
			value: user?._id, 
			label: user?.name+", "+user?.email+", "+user?.phone
		}
	})

	const columnDefs = [
		{ headerName: 'Sr.No.', field: 'srNo', width: 80, valueGetter: 'node.rowIndex + 1', sortable: true },
		{ headerName: 'Name', field: 'name', width: 130, sortable: true },
		{ headerName: 'Email', field: 'email', width: 190, sortable: true },
		{ headerName: 'Username', field: 'username', width: 150, sortable: true },
		{ headerName: 'Phone', field: 'phone',  sortable: true, width: 120, },
		{ headerName: 'Total Users', 
			valueGetter: (params) => params.data?.totalUserCount,
			cellRenderer: (params) => <span className="d-flex justify-content-between">
				{params?.data?.totalUserCount}
				<button className="btn btn-warning btn-sm" onClick={() => showUsers(params?.data)}> Show All </button>
			</span> 
		},
		{ 
			headerName: 'Created At', 
			field: 'createdAt', 
			valueGetter: (params) => params.data?.createdAt ? formatDateString(params.data?.createdAt) : "",
			cellRenderer: (params) => formatDateString(params?.data?.createdAt)
		},
		{
			headerName: 'Status',
			field: 'status',
			cellRenderer: (params) => (
				<select value={params?.data?.status} className={`form-control form-control-sm`} onChange={e => updateAgentStatus(params?.data, e.target.value)}>
					<option value=''>Select Status</option>
					<option value='ACTIVE'>Active</option>
					<option value='SUSPENDED'>Suspended</option>
					<option value='LOCKED'>Blocked</option>
				</select>
			)
		},
		{ headerName: 'Commission', field: 'comission',  sortable: true, width: 120,},
		{ 
			headerName: 'Wallet Amount',
			field: 'walletAmount',
			sortable: true,
			width: 280,
			cellRenderer: (params) => (
				<div className="d-flex align-items-center justify-content-between">
					{String(params?.data?.walletId?.balance)}
					<span>
					<button className="btn btn-warning btn-sm me-2" onClick={() => setModal({ show: true, data: params?.data, type: "deposit" })}>
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
						<path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
						</svg>
						Add
					</button>
					<button className="btn btn-danger btn-sm" onClick={() => setModal({ show: true, data: params?.data, type: "withdraw" })}>
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
						<path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
						</svg>
						Withdraw
					</button>
					</span>
				</div>
			)
		},
		{ 
			headerName: 'Show Transactions',
			cellRenderer: (params) => <button className="btn btn-warning btn-sm" onClick={()=> showTransaction(params?.data)}> Show All Transaction </button>
		},	
		{ 
			headerName: 'Action', 
			sortable: true,
			width: 100,
			cellRenderer: (params) => (
				params?.data?.status === "LOCKED"
				?
				<button className="btn btn-warning btn-sm" onClick={() => enableUser(params?.data?._id)}>Enable</button>
				:
				<div className="d-flex align-items-center justify-content-center">
					<span className="px-2" onClick={() => navigate('/add-agent',{state:params?.data})}>
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
							<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z"/>
						</svg>
					</span>
					<span className="px-2" onClick={() => setModal({ show: true, data: params?.data, type: "delete" })}>
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
							<path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
						</svg>
					</span>
				</div>
			)
		},
	];
	  
	const getData = async () => {
		try{
			setLoader(true)
			const headers = { 
				headers: { "Content-Type": "application/json", 
				"x-access-token": token,
			}}
			const res = await axios.get(
				baseUrl + "/admin/affiliate-agent-list?" 
				+ `${filter?.search ? `search=${filter?.search}&` : ""}`
				+ `${filter?.status ? `status=${filter?.status}&` : ""}`
				+ `${filter?.dateFrom ? `dateFrom=${filter?.dateFrom}&` : ""}`
				+ `${filter?.dateTo ? `dateTo=${filter?.dateTo}&` : ""}`
				+ `${filter?.minAmount ? `minAmount=${filter?.minAmount}&` : ""}`
				+ `${filter?.maxAmount ? `maxAmount=${filter?.maxAmount}&` : ""}`
				, headers
			)
			if(res?.data?.data){
				setLoader(false)
				setUsers(res?.data?.data?.reverse())
			}
		} catch(err){
			console.log(err)
			setLoader(false)
		}
	}

	const saveAmount = async (el) => {
		try{
			if(!el?.addAmount){
				return
			}
			setModal({...modal, loader: true})
			const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
			const res = await axios.post(baseUrl + "/admin/reacharge-affiliate-agent", {agentId: el?._id, creditAmount : el?.addAmount,collectedAmount:el?.collectedAmount}, headers)
			if(res?.data?.data){
				toast.success("Updated", { position: "top-center", autoClose: 2000 });
				setModal({})
				getData()
			}
		} catch(err){
			setModal({...modal, loader: false})
			console.log(err)
		}
	}

	const withdrawAmount = async (el) => {
		try{
			if(!el?.debitAmount){
				return
			}
			setModal({...modal, loader: true})
			const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
			const res = await axios.post(baseUrl + "/admin/withdraw-affiliate-agent", {agentId: el?._id, debitAmount : el?.debitAmount, commission: el?.commission}, headers)
			if(res?.data?.data){
				toast.success("Updated", { position: "top-center", autoClose: 2000 });
				setModal({})
				getData()
			}
		} catch(err){
			setModal({...modal, loader: false})
			console.log(err)
		}
	}

	const updateAgentStatus = async (agent, status) => {
		try {
			const headers = { headers: { "x-access-token": token } }
			const userData = {_id: agent?._id, phone: agent?.phone, email: agent?.email, status}
			let res = await axios.put(baseUrl + '/admin/edit-affiliate-agent', userData, headers)
			if (res?.status == 200) {
				toast.success('Status Updated', { position: "top-center", autoClose: 2000 });
				getData()
			}
		} catch (error) {
			toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
		}
	}

	const deleteAgent = async agentId => {
		try{
			if(!agentId){
				return
			}
			setModal({...modal, loader: true})
			const headers = { "Content-Type": "application/json", "x-access-token": token}
			const res = await axios.delete(baseUrl + "/admin/delete-affiliate-agent", {headers, data: {id: agentId}} )
			if(res?.data?.data){
				toast.success("Deleted", { position: "top-center", autoClose: 2000 });
				setModal({})
				getData()
			}
		} catch(err){
			setModal({...modal, loader: false})
			console.log(err)
		}
	}

	const enableUser = async agentId => {
		try{
			if(!agentId){
				return
			}
			setModal({...modal, loader: true})
			const headers = {headers: { "Content-Type": "application/json", "x-access-token": token}}
			const res = await axios.put(baseUrl + "/admin/enable-affiliate-agent", {id: agentId}, headers )
			if(res?.data?.data){
				toast.success("Agent Restored", { position: "top-center", autoClose: 2000 });
				setModal({})
				getData()
			}
		} catch(err){
			setModal({...modal, loader: false})
			console.log(err)
		}
	}

	const handleCSVDownload = () => csvDownload({ 
		data: users?.map(el => ({
			id: el?._id,
			name: el?.name,
			phone: el?.phone,
			email: el?.email,
			username: el?.username,
			balance: el?.walletId?.balance,
			commission: el?.comission ? el?.comission : "-",
		})),
		delimiter: ",", 
		filename:"Betkaro-AgentList-"+formatDateString(new Date())
	})

	useEffect(() => {
		getData()
	}, [])

	return (
		<MainTemplate
			categoryName="Agent list"
			categoryList={["Manage Users", "Agent list"]}
		>
			<div>
				<div className="">
					<div className="row ml-1 mb-2">
						<div className="col-md-3">
							<label><small>Search</small></label>
							<input className="form-control form-control-sm" type="text" placeholder="Search" onChange={e => setFilter({...filter, search: e.target.value})}/>
						</div>
						<div className="col-md-3">
							<label><small>Date From</small></label>
							<input className="form-control form-control-sm" type="date" max={new Date().toISOString().split('T')[0]} onChange={e => setFilter({...filter, dateFrom: e.target.value})}/>
						</div>
						<div className="col-md-3">
							<label><small>Date To</small></label>
							<input className="form-control form-control-sm" type="date" max={new Date().toISOString().split('T')[0]} onChange={e => setFilter({...filter, dateTo: e.target.value})}/>
						</div>
						<div className="col-md-3 d-flex align-items-end">
							<button type="button" className="btn btn-primary btn-sm w-50 me-2" onClick={handleCSVDownload}>Export</button>
							<Link to="/add-agent"><button type="button" className="btn btn-warning btn-sm px-3" >Add Agent</button></Link>
						</div>
						<div className="col-md-3">
							<label><small>Wallet Balance</small></label>
							<div className="d-flex">
								<input className="form-control form-control-sm me-1" type="number" placeholder="Min Balance" onChange={e => setFilter({...filter, minAmount: e.target.value})}/>									
								<input className="form-control form-control-sm" type="number" placeholder="Max Balance" onChange={e => setFilter({...filter, maxAmount: e.target.value})}/>									
							</div>
						</div>
						<div className="col-md-3">
							<label><small>Status</small></label>
							<select value={filter?.status} className={`form-control form-control-sm`} onChange={e => setFilter({...filter, status: e.target.value})}>
								<option value=''>Select Status</option>
								<option value='ACTIVE'>Active</option>
								<option value='SUSPENDED'>Suspended</option>
								<option value='LOCKED'>Blocked</option>
							</select>
						</div>
						<div className="col-md-3 d-flex align-items-end">
							<button type="button" className="btn btn-warning btn-sm w-50 me-2" onClick={getData}>Search</button>
						</div>
						
					</div>

					<div className="row">
						<div className="col-lg-12">
							<div className="col-lg-12 grid-margin stretch-card">
								<div className="card">
									<div className="ag-theme-alpine affiliateAgent" style={{ width: '100%' }}>
										<AgGridReact
											columnDefs={columnDefs}
											rowData={users}
											rowClassRules={ {'locked': params => params?.data?.status === "LOCKED"}}
											domLayout={gridOptions.domLayout}
											enableCellTextSelection={true}
											pagination={gridOptions.pagination}
											paginationPageSize={gridOptions.paginationPageSize}
											suppressCellSelection={true}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ToastContainer />

				<Modal
					isOpen={modal?.show && modal?.type === "deposit"}
					onRequestClose={() => setModal({})}
					style={customStyles}
					contentLabel="Example Modal 1"
				>
					<h1 className="text-center mb-5 ">Add Money</h1>
					<div className="mb-2">Email: {modal?.data?.email}</div>
					<div className="mb-2">Phone: {modal?.data?.phone}</div>
					<div className="mb-4">Wallet Amount: {modal?.data?.walletId?.balance}</div>
					
					<span className="fw-bold">Enter Point</span>
					<input 
						className="form-control w-100 mb-2 p-2 border-dark" 
						type="number" 
						placeholder="Enter point to add"  
						value={modal?.data?.addAmount} 
						onChange={e => setModal({...modal, data: {...modal?.data, addAmount: e.target.value}})}
						maxLength={8}
						onInput={(event) => {
							if (event.target.value.length > event.target.maxLength) {
								event.target.value = event.target.value.slice(0, event.target.maxLength)
							}
						}}
					/>
					<span className="fw-bold">Collected rate (in %)</span>
					<input 
						className="form-control w-100 mb-2 p-2 border-dark" 
						type="number" 
						placeholder="Enter collected rate"  
						value={modal?.data?.collectedAmount} 
						onChange={e => setModal({...modal, data: {...modal?.data, collectedAmount: e.target.value}})}
						maxLength={3}
						onInput={(event) => {
							if(event.target.value < 1){
								event.target.value = 1
								return;
							}
							if(event.target.value > 100){
								event.target.value = 100
								return;
							}
							if (event.target.value.length > event.target.maxLength) {
								event.target.value = event.target.value.slice(0, event.target.maxLength)
							}
						}}
					/>
					{
						!isNaN(modal?.data?.addAmount) && !isNaN(modal?.data?.collectedAmount)
						?
						<small>Collected Cash: ₹{modal?.data?.addAmount*modal?.data?.collectedAmount/100 }</small>
						:
						""
					}
					<button type="button" disabled={modal?.loader} className="mt-2 w-100 btn btn-success " onClick={() => saveAmount(modal?.data)}>
						{
							modal?.loader
							?
							<div class="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm" role="status" />
							:
							"Add"
						}
					</button>
				</Modal>

				<Modal
					isOpen={modal?.show && modal?.type === "withdraw"}
					onRequestClose={() => setModal({})}
					style={customStyles}
					contentLabel="Withdraw modal"
				>
					<h1 className="text-center mb-5 ">Withdraw Money</h1>
					<div className="mb-2">Email: {modal?.data?.email}</div>
					<div className="mb-2">Phone: {modal?.data?.phone}</div>
					<div className="mb-4">Wallet Amount: {modal?.data?.walletId?.balance}</div>
					
					<span className="fw-bold">Debit Amount</span>
					<input 
						className="form-control w-100 mb-2 p-2 border-dark" 
						type="number" 
						placeholder="Enter point to add"  
						value={modal?.data?.debitAmount} 
						onChange={e => setModal({...modal, data: {...modal?.data, debitAmount: e.target.value}})}
						maxLength={8}
						onInput={(event) => {
							if (event.target.value.length > event.target.maxLength) {
								event.target.value = event.target.value.slice(0, event.target.maxLength)
							}
						}}
					/>
					{
						!isNaN(modal?.data?.walletId?.balance) && !isNaN(modal?.data?.debitAmount)
						?
						<small>Remaining Agent Balance: ₹{modal?.data?.walletId?.balance - modal?.data?.debitAmount }</small>
						:
						""
					}
					<br />
					<span className="fw-bold">Withdraw Rate (in %)</span>
					<input 
						className="form-control w-100 mb-2 p-2 border-dark" 
						type="number" 
						placeholder="Withdraw Rate"  
						value={modal?.data?.commission} 
						onChange={e => setModal({...modal, data: {...modal?.data, commission: e.target.value}})}
						maxLength={3}
						onInput={(event) => {
							if(event.target.value < 1){
								event.target.value = 1
								return;
							}
							if(event.target.value > 100){
								event.target.value = 100
								return;
							}
							if (event.target.value.length > event.target.maxLength) {
								event.target.value = event.target.value.slice(0, event.target.maxLength)
							}
						}}
					/>
					{
						!isNaN(modal?.data?.debitAmount) && !isNaN(modal?.data?.commission)
						?
						<small>Payout Cash: ₹{modal?.data?.debitAmount * modal?.data?.commission /100 }</small>
						:
						""
					}
					<button type="button" disabled={modal?.loader} className="mt-2 w-100 btn btn-warning " onClick={() => withdrawAmount(modal?.data)}>
						{
							modal?.loader
							?
							<div class="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm" role="status" />
							:
							"Withdraw"
						}
					</button>
				</Modal>

				<Modal
					isOpen={modal?.show && modal?.type === "delete"}
					onRequestClose={() => setModal({})}
					style={customStyles}
					contentLabel="Example Modal"
				>
					<h1 className="text-center mb-4">Delete Agent</h1>
					<div className="mb-2">Email: {modal?.data?.email}</div>
					<div className="mb-2">Phone: {modal?.data?.phone}</div>
					<div className="mb-4">Wallet Amount: {modal?.data?.walletId?.balance}</div>
					
					<h5 className="text-danger mb-4">Are You sure you want to delete this agent?</h5>
					<div className="row">
						<div className="col-6">
							<button type="button" className="w-100 btn btn-success" onClick={() => setModal({})}> Cancel </button>
						</div>
						<div className="col-6">
							<button type="button" disabled={modal?.loader} className=" w-100 btn btn-danger  " onClick={() => deleteAgent(modal?.data?._id)}>
							{
								modal?.loader
								?
								<div class="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm" role="status" />
								:
								"Delete"
							}
							</button>
						</div>
						
						
					</div>
					
				</Modal>
			</div>
		</MainTemplate>
	);
};

export default AffiliateProgram;
