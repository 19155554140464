import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { fetchAllEvents, sports_Status } from '../../Api/api'
import baseUrl from '../../Api/baseUrl'
import './Admins.css'
import { useNavigate } from "react-router-dom";
import MainTemplate from '../../components/MainTemplate'
import { Spinner } from 'react-bootstrap'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { formatDateString, gridOptions } from '../../utils/helperFunctin'
import ReactSelect from 'react-select'
import csvDownload from 'json-to-csv-export'

function SportsSetting() {

    const navigate = useNavigate()
    const [admins, setAdmins] = useState([])
    const [loader, setLoader] = useState(false)
    const [filter, setFilter] = useState({})

    const columnDefs = [
        { headerName: 'Sr.No.', valueGetter: 'node.rowIndex + 1',  },
        { headerName: 'User Name', field: 'username' },
        { headerName: 'Phone', field: 'phone' },
        { headerName: 'Role', field: 'role' },
        { headerName: 'Access', field: 'access', cellRenderer: params => params.value?.map((a, index) => (
            <span key={index} className="me-1 p-1 badge badge-pill badge-warning">{a}</span>
          )) 
        },
        {
            headerName: 'Created At',
            valueGetter: (params) => formatDateString(params?.data?.createdAt),
            cellRenderer: (params) => formatDateString(params?.data?.createdAt),
        },
        {
            headerName: 'Action',
            cellRenderer: (params) => (
                <button className="btn btn-primary btn-sm" onClick={() => navigate("/add-admin", { pathname: '/home', state: { admin: params.data, update: true }})} >
                Edit
                </button>
            ),
        },
        
    ];

    const getAdmins = async () =>{
        try {
            setLoader(true)
            const token = localStorage.getItem("token");
            const headers = {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": token,
                },
            };
            const res = await axios.get(
                baseUrl+ "/admin/subadmin_list?"
				+ `${filter?.admin?._id ? `admin=${filter?.admin?._id}&` : ""}`
                , headers)
            if(res?.data?.data){
                setAdmins(res?.data?.data?.map(el => ({...el, value:el?._id , label:  el?.username + ", " + el?.phone})))
                setLoader(false)
            }
        } catch (err){
            console.log(err)
            setLoader(false)
            toast.error('Something went wrong', { position: "top-center", autoClose: 2000 });
        }
    } 

    const handleCSVDownload = () => csvDownload({ 
		data: admins?.map(el => ({
			username: el?.username,
			phone: el?.phone,
			role: el?.role,
			access: el?.access,
			createdAt: formatDateString(el?.createdAt),
		})),
		delimiter: ",", 
		filename:"Betkaro-AdminList-"+formatDateString(new Date())
	})

    useEffect(() => {
        getAdmins()
    }, [filter])

    return (
        <MainTemplate
            categoryName="Admins List"
            categoryList={["Manage Users", "Admins List"]}
        >
        <div>
            <div className="row mb-2">
                <div className="col-3 mb-1">
                    <label><small>Select Admin</small></label>
                    <ReactSelect className="basic-single" isClearable={true} options={admins} value={filter?.admin} onChange={e => setFilter({...filter, admin: e})} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}/>
                </div>
                <div className="col-3 mb-1 d-flex align-items-end ">
                    <button type="button" className="btn btn-warning px-5 mb-1" onClick={getAdmins}>Export</button>
                </div>
                <div className="col-6 mb-1 d-flex align-items-end justify-content-end">
                    <button type="button" className="btn btn-primary px-5 mb-1  me-1" onClick={handleCSVDownload}>Export</button>
                    <button type="button" className="btn btn-warning px-5 mb-1" onClick={() => navigate("/add-admin")}>Add Admin</button>
                </div>
            </div>
            <div className="">
                <div className="row">
                    <div className="ag-theme-alpine affiliateAgent" style={{ width: '100%' }}>
                        <AgGridReact
                            columnDefs={columnDefs}
                            rowData={admins}
                            domLayout={gridOptions.domLayout}
                            enableCellTextSelection={true}
                            pagination={gridOptions.pagination}
                            paginationPageSize={gridOptions.paginationPageSize}
                            suppressCellSelection={true}
                        />
                    </div>
                </div>
            </div>
        </div>
        </MainTemplate>
    )
}

export default SportsSetting