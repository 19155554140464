import React, { useState } from 'react'
import MainTemplate from '../../../components/MainTemplate'
import axios from 'axios';
import baseUrl from '../../../Api/baseUrl';
import { useEffect } from 'react';
import Select from "react-select"
import { Spinner } from 'react-bootstrap';
import csvDownload from 'json-to-csv-export';
import { formatDateString, gridOptions } from '../../../utils/helperFunctin';
import { AgGridReact } from 'ag-grid-react';
import ReactSelect from 'react-select';

function CasinoSetting() {
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"))

  const [users, setUsers] = useState([]);
  const [casinoData, setCasinoData] = useState({loader: false, data: []})
	const [search, setSearch] = useState("")
	const [filter, setFilter] = useState({})

  const columnDefs = [
    { headerName: 'Sr.No.', valueGetter: 'node.rowIndex + 1', width: 80},
    { headerName: 'Image', width: 150, cellRenderer: (params) => <img src={params?.data?.ImageFullPath} width="100"/> },
    { headerName: 'Game', width: 150, field: 'gameName' },
    { headerName: 'Merchant', width: 150, field: 'MerchantName' },
    { headerName: 'Username', field: 'user_id.username' },
    { headerName: 'name', field: 'user_id.name' },
    { headerName: 'email', field: 'user_id.email' },
    { headerName: 'phone', field: 'user_id.phone' },
    { headerName: 'Stake', width: 80, field: 'stake' },
    { headerName: 'Profit/Loss', width: 120, cellRenderer: (params) => (
      params?.data?.profit
      ?
      <span className="text-success">{params?.data?.profit}</span>
      :
      params?.data?.loss
      ?
      <span className="text-danger">-{params?.data?.loss}</span>
      :
      ""
    ) },
    { headerName: 'Debit/Credit', width: 120, cellRenderer: (params) => (
      params?.data?.type === "credit"
      ?
      <span className="text-success">{params?.data?.type}</span>
      :
      params?.data?.type === "debit"
      ?
      <span className="text-danger">{params?.data?.type}</span>
      :
      ""
    ) },
    { headerName: 'Currency', width: 100, field: 'currency' },
    { headerName: 'Time', cellRenderer: (params) => formatDateString(params?.data?.createdAt) },
  ];

  const handleCSVDownload = () => csvDownload({ 
		data: casinoData?.data?.map(el => ({
			id: el?._id,
			game: el?.gameName,
			merchant: el?.MerchantName,
      Username: el?.user_id.username,
      name: el?.user_id.name,
      email: el?.user_id.email,
      phone: el?.user_id.phone,
			stake: el?.stake,
			"Profil/Loss": el?.profit
      ?
      el?.profit
      :
      el?.loss
      ?
      "-"+el?.loss
      :
      "",
			"Debit/Credit": el?.type,
			currency: el?.currency,
      createdAt: formatDateString(el?.createdAt)
		})),
		delimiter: ",", 
		filename:"Betkaro-CasinoBets-"+formatDateString(new Date()) 
	})

  const getUserData = async () => {
		try{
			const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
			let apiUrl = ""
			if(user?.type === "affiliateAdmin"){
				apiUrl = baseUrl + "/admin/affiliate-user-list?" 				
			} else {
				apiUrl = baseUrl + "/admin/all_user_list?" 
			}
			
			const res = await axios.get(apiUrl, headers)
			if(res?.data?.data){
				setUsers(res?.data?.data?.map(el => ({...el, value:el?._id, label: el?.name+", "+el?.email+", "+el?.phone+", "+el?.username})))
			}
		} catch(err){
			console.log(err)
		}
	}

  const getUserCasino = async () => {
    setCasinoData({loader: true, data: []})
    const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
    const res = await axios.get(
      baseUrl + "/admin/casino_statement?"
      + `${filter?.user ? `userId=${filter?.user?._id}&` : ""}`
      + `${filter?.search ? `search=${filter?.search}&` : ""}`
      + `${filter?.minStake ? `minStake=${filter?.minStake}&` : ""}`
      + `${filter?.maxStake ? `maxStake=${filter?.maxStake}&` : ""}`
      + `${filter?.dateFrom ? `dateFrom=${filter?.dateFrom}&` : ""}`
      + `${filter?.dateTo ? `dateTo=${filter?.dateTo}&` : ""}`
      + `${filter?.type ? `type=${filter?.type}&` : ""}`
      , headers)
    if(res?.data?.data){
      setCasinoData({loader: false, data: res?.data?.data || []})
    }
  }  

	useEffect(() => {
		getUserData()
	}, [])

  useEffect(() => {
		getUserCasino()
	}, [])

  return (
    <MainTemplate
      categoryName="Casino Bets"
      categoryList={[]}
    >
    <div>
      <div className="">

        <div className="row mb-2">
          <div className="col-3 mb-1">
            <label><small>Search</small></label>
            <input className="form-control form-control-sm" type="text" placeholder="Search" onChange={e => setFilter({...filter, search: e.target.value})}/>
          </div>
          <div className="col-3 mb-1">
            <label><small>Search Stake</small></label>
            <div className="d-flex">
              <input className="form-control form-control-sm me-1" type="number" placeholder="Min Stake" onChange={e => setFilter({...filter, minStake: e.target.value})}/>				
              <input className="form-control form-control-sm" type="number" placeholder="Max Stake" onChange={e => setFilter({...filter, maxStake: e.target.value})}/>				
            </div>
          </div>
          <div className="col-3 mb-1">
            <label><small>Date From</small></label>
            <input className="form-control form-control-sm" type="date" max={new Date().toISOString().split('T')[0]} onChange={e => setFilter({...filter, dateFrom: e.target.value})}/>
          </div>
          <div className="col-3 mb-1">
            <label><small>Date To</small></label>
            <input className="form-control form-control-sm" type="date" max={new Date().toISOString().split('T')[0]} onChange={e => setFilter({...filter, dateTo: e.target.value})}/>
          </div>
          <div className="col-3 mb-1">
            <label><small>Select Debit/Credit</small></label>
            <select value={filter?.type} className={`form-control form-control-sm`} onChange={e => setFilter({...filter, type: e.target.value})}>
              <option value=''>Select a Tag</option>
              <option value='debit'>Debit</option>
              <option value='credit'>Credit</option>	
            </select>						
          </div>
          <div className="col-3 mb-1">
            <label><small>Select User</small></label>
            <ReactSelect className="basic-single" isClearable={true} options={users} value={filter?.user} onChange={e => setFilter({...filter, user: e})} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}/>
          </div>
          
          <div className="col-3 mb-1 d-flex align-items-end justify-content-end">
            <button type="button" className="btn btn-warning w-100" onClick={getUserCasino}>Search</button>
          </div>
          <div className="col-3 mb-1 d-flex align-items-end justify-content-end">
            <button type="button" className="btn btn-primary w-100" onClick={handleCSVDownload}>Export</button>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="ag-theme-alpine" style={{ width: '100%' }}>
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={casinoData?.data}
                    domLayout={gridOptions.domLayout}
                    enableCellTextSelection={true}
                    pagination={gridOptions.pagination}
                    paginationPageSize={gridOptions.paginationPageSize}
                    suppressCellSelection={true}
                    rowHeight={100}
                  />
                </div>
                {/* <div className="">
                  <div className="table-responsive">
                    <table className="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          <th>Game</th>
                          <th>Merchant</th>
                          <th>Stake</th>
                          <th>Profit/Loss</th>
                          <th>Debit/Credit</th>
                          <th>Currency</th>
                          <th>Image</th>
                          <th>time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          casinoData?.data?.map((el, i) => (
                            <tr>
                              <td>{i +1}</td>
                              <td>{el?.gameName}</td>
                              <td>{el?.MerchantName}</td>
                              <td>{el?.stake}</td>
                              {
                                el?.profit
                                ?
                                <td className="text-success">{el?.profit}</td>
                                :
                                el?.loss
                                ?
                                <td className="text-danger">-{el?.loss}</td>
                                :
                                ""
                              }
                              {
                                el?.type === "credit"
                                ?
                                <td className="text-success">{el?.type}</td>
                                :
                                el?.type === "debit"
                                ?
                                <td className="text-danger">{el?.type}</td>
                                :
                                ""
                              }
                              <td>{el?.currency}</td>
                              <td><img src={el?.ImageFullPath} width="100"/></td>
                              <td>{new Date(el?.createdAt).toLocaleString()}</td>
                              
                            </tr> 
                          ))
                        }
                        
                      </tbody>
                    </table>
                    {
                      casinoData?.loader
                      ?
                      <div className="w-100 d-flex justify-content-center"><Spinner/></div>
                      :
                      ""
                    }
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </MainTemplate>
  )
}

export default CasinoSetting