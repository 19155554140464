import React, { useEffect, useState } from 'react'
import MainTemplate from '../../components/MainTemplate'
import Logo from "../../assets/images/betkarologo.svg";
import { ROLES } from '../../utils/helperFunctin';
import AnimatedNumbers from "react-animated-numbers";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import baseUrl from '../../Api/baseUrl';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { addCategory } from '../../redux/reducers/sidebarSlice';

const Welcome = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = JSON.parse(localStorage.getItem("user"))
  const dashboardAccess = user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.Dashboard) || user?.type === "affiliateAdmin"

  const [data, setData] = useState([
    { text: 'Live Matches Count', loading: true},
    { text: 'Live Sports Bets Count', loading: true},
    { text: 'Total Users', loading: true },
    { text: 'Total Agents', loading: true },
    { text: 'Players Online',loading: true},
    { text: 'Total Amount Bets', loading: true },
    { text: 'Total Estimated Profit', loading: true },
    { text: 'Total Estimated Loss', loading: true },
  ])

  const getData = async (el) => {
		try{
			const headers = { headers: { "Content-Type": "application/json", "x-access-token": user?.token, } }
			const res = await axios.get(baseUrl +"/admin/dashboard", headers)
      if(res?.data ){
        const {totalAmountBet, totalBets, totalLiveMatches, totalNumberOfAgents, totalNumberOfUsers} = res?.data
        setData([
          { text: 'Live Matches Count', value: totalLiveMatches || 0, link:"/market-settings" },
          { text: 'Live Sports Bets Count', value: totalBets || 0, link:"/current-bets"  },
          { text: 'Total Users', value: totalNumberOfUsers || 0, link:"/users-list" },
          { text: 'Total Agents', value: totalNumberOfAgents || 0, link:"/agent-list" },
          { text: 'Players Online', value: 0 },
          { text: 'Total Amount Bets', value: totalAmountBet || 0, link:"/account-statement" },
          { text: 'Total Estimated Profit', value: 0 , numberClass:"text-success" },
          { text: 'Total Estimated Loss', value: 0, numberClass:"text-danger"  },
        ])
      } else {
        setData(data?.map(el => ({...el, loading: false, value: 0})))
      }
		} catch(err){
			console.log(err)
		}
	}

  const redirectLink = url => {
    if(url){
      dispatch(addCategory({}))
      navigate(url)
    }
  }

  useEffect(() => {
    getData()
    const callFunction = setInterval(() => getData(), 5000)
    return () => {
      clearInterval(callFunction)
    }
  }, [])

  return (
    <MainTemplate categoryName="" categoryList={[]} >

    <div className="row">
      {
        dashboardAccess
        ?
        <div className="container mt-5">
          <h1 className="text-center mb-5">Dashboard</h1>

          <div className="row">
            {data.map((item, index) => (
                <div key={index} className="col-lg-3 col-md-6 mb-4 cursorPointer" onClick={() => redirectLink(item?.link)}>
                  <div className={`card shadow h-100`} >
                    <div className="card-body d-flex flex-column justify-content-between">
                      <h5 className={`card-title p-3`}>{item.text}</h5>
                      {
                        item?.loading
                        ?
                        <div className="text-center"><Spinner size="sm" /></div>
                        :
                        <p className={`card-text display-6 text-center py-4 ${item?.numberClass}`} style={{fontWeight: 500}}>
                          <AnimatedNumbers
                            includeComma
                            locale={"en-IN"}
                            className={"justify-content-center"}
                            transitions={(index) => ({ type: "spring", duration: index + 0.3, })}
                            animateToNumber={item.value}
                          />
                        </p>
                      }
                    </div>
                  </div>
                </div>
            ))}
          </div>

          
        </div>
        :
        <div className="text-center align-items-center d-flex justify-content-center" style={{height: "700px", flex: 1}}>
            <img src={Logo}  className="w-50" />
        </div>
      }
        
    </div>
    </MainTemplate>
  )
}

export default Welcome
