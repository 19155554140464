import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
// import CategoryList from "../CategoryList/CategoryList";
// import "./LandingPage.css";
import Logo from "../assets/images/betkarologo.svg";
import User from "../assets/images/profile.png";
import Breadcrumb from "./Breadcrumb";
import Sidebar from "./Sidebar";

function MainTemplate({ children, categoryName, categoryList }) {
  const sidebarRef = useRef(null);
  const containerRef = useRef(null);
  const user = JSON.parse(localStorage.getItem("user"))
  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState();
  const [sidebar, showSidebar] = useState(true);

  useEffect(() => {
    if (sidebar) {
      if (sidebarRef && sidebarRef?.current)
        sidebarRef.current.style.width = "25%";
      if (containerRef && containerRef?.current)
        containerRef.current.style.width = "75%";
    } else {
      if (sidebarRef && sidebarRef?.current)
        sidebarRef.current.style.width = "5%";
      if (containerRef && containerRef?.current)
        containerRef.current.style.width = "95%";
    }
  }, [sidebar]);

  useEffect(() => {
    const nav = JSON.parse(localStorage.getItem("nav"));
    if (nav) {
      setSelectedCategory(nav);
    }
  }, []);

  const togglePage = (el) => {
    localStorage.setItem("nav", JSON.stringify(el));
    navigate(el?.link);
  };

  return (
    <div className="parentContainer row h-100 m-0">
      {/* side container */}
      <Sidebar containerRef={containerRef} />
      {/* main container */}
      <div
        className="p-0 bg-light"
        id="mainContainer"
        ref={containerRef}
        style={{ width: "75%" }}
      >
        <div className="mx-3 my-4 px-3">
          <div className="mt-4">
            <Breadcrumb name={categoryName} list={categoryList} user={user}/>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainTemplate;
