import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { fetchAllSports, getMatchSettings, match_details } from "../../../Api/api";
import baseUrl from "../../../Api/baseUrl";
import '../MatchSettings/MatchSettings.css'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import MainTemplate from "../../../components/MainTemplate";
import { formatDateString, gridOptions } from "../../../utils/helperFunctin";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import csvDownload from "json-to-csv-export";
import ReactSelect from "react-select";

function MatchSettings() {
  const [status, setStatus] = useState();
  const [search, setSearch] = useState();
  const [date, setDate] = useState({})
  const [data, setData] = useState([]);
  const [sportsData, setSportsData] = useState([]);
  const [selectedSports, setSelectedSports] = useState("");
  const [matchesData, setMatchesData] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState("");
  const [loader, setLoader] = useState(false);
  const [checkIn, setCheckIn] = useState(false)
  
  
  const columnDefs = [
    { headerName: 'Sr.No.', valueGetter: 'node.rowIndex+1', width: 80 },
    { headerName: 'Match Id', field: 'event.id', width: 300 },
    { headerName: 'Match Name', field: 'event.name', width: 300 },
    { headerName: 'Date', cellRenderer: (params) => formatDateString(params?.data?.event?.openDate) },
    {
      headerName: 'Action',
      cellRenderer: (params) => (
        <div className='d-flex'>
          <label className="switch">
            <input
              className="switch-input"
              type="checkbox"
              checked={params.data.status}
              onChange={() => handleClickCheckBox(params.data)}
            />
            <span className="switch-label"></span>
            <span className="switch-handle"></span>
          </label>
          <span>{params.data.status ? 'Checked' : 'Unchecked'}</span>
        </div>
      ),
    },
  ];


  const getData = async () => {
    setLoader(true);
    const res = await axios.get(
      baseUrl +
      getMatchSettings + `?` +
      `${status ? `&status=${status === "true" ? true : status ==="false" ? false : "" }` : ""}` +
      `${selectedSports?.competition?.id ? `&seriousId=${selectedSports?.competition?.id}` : "" }` +
      `${selectedMatch?.event?.id ? `&matchId=${selectedMatch?.event?.id}` : ""}`+
      `${search ? `&search=${search}` : ""}` +
      `${date?.dateFrom ? `&dateFrom=${date?.dateFrom}&` : ""}` +
      `${date?.dateTo ? `&dateTo=${date?.dateTo}&` : ""}`
    );
    if (res?.data?.data) {
      setData(res?.data?.data);
      if (selectedSports?.eventType) setMatchesData(res?.data?.data);
    }
    setLoader(false);
  };

  const getAllSportsData = async () => {
    const res = await axios.get(baseUrl + fetchAllSports);
    if (res?.data?.data) {
      setSportsData([{value: "", label: "All"}, ...res?.data?.data?.map(el => ({...el, value: el?.competition?.id, label: el?.competition?.name}))]);
    }
  };

  const handleSportsChange = (e) => {
    setSelectedSports(e);
    // setSelectedMatch();
  };

  // const handleMatchChange = (e) => {
  //   const matchId = e.target.value;
  //   const foundMatch = matchesData?.find((el) => el?.event?.id === matchId);
  //   setSelectedMatch(foundMatch);
  // };

  const handleClickCheckBox = async (el) => {
    try {
      const status = el?.status == false ? true : false
      const token = localStorage.getItem("token");
      const headers = {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      };
      const matchId = el?.event?.id
      const responce = await axios.put(baseUrl + match_details + `/${status}/${matchId}`, {}, headers)
      if (responce.status == 200) {
        toast.success('Updated', { position: "top-center", autoClose: 2000 });
        getData()
      } else {
        getData()
      }
    }
    catch (error) {
      toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
      getData()
    }
  }

  const handleCSVDownload = () => csvDownload({ 
		data: data?.map(el => ({
			id: el?._id,
			matchId: el?.event?.id,
			matchName: el?.event?.name,
			matchDate: formatDateString(el?.event?.openDate),
      status: el?.status
		})),
		delimiter: ",", 
		filename:"Betkaro-MatchSetting-"+formatDateString(new Date()) 
	})

  useEffect(() => {
    getAllSportsData();
    getData();
  }, []);

  // useEffect(() => {
  //   getData();
  // }, [status, selectedSports, selectedMatch, search, date]);

  return (
    <MainTemplate
      categoryName="Match Setting"
      categoryList={["Market Setting", "Match Setting"]}
    >
    <div>
      <ToastContainer />
      <div>
        <div className="">
          <div className="row">
            <div className="col-lg-12">
              <div className="col-lg-12 stretch-card">
                <div className="">
                  <div className="">
                    <div className="table-responsive">
                      <table className="table table-borderless mb-0">
                        <thead>
                          <tr>
                            <th> Status </th>
                            <th> Series Name </th>
                            <th className="row m-0"> 
                              <span className="col-6">Date From</span> 
                              <span className="col-6">Date To</span> 
                            </th>
                            <th> Match Search </th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Form.Group>
                                <select onChange={(e) => setStatus(e.target.value)} className="form-control " id="exampleFormControlSelect3" >
                                  <option value="" selected>All</option>
                                  <option value="true">Active</option>
                                  <option value="false">Inactive</option>
                                </select>
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                              <ReactSelect options={sportsData} value={selectedSports} defaultValue={sportsData?.[0]} className="basic-single" onChange={handleSportsChange} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}></ReactSelect>					
                              </Form.Group>
                            </td>
                            <td className="d-flex">
                              <input className="form-control  me-2" type="date" onChange={e => setDate({...date, dateFrom: e.target.value})}/>
                              <input className="form-control " type="date" onChange={e => setDate({...date, dateTo: e.target.value})}/>
                            </td>
                            <td>
                              <input className="form-control " type="text" placeholder="Search" onChange={e => setSearch( e.target.value)}/>
                            </td>
                            <td>
                              <div className="d-flex align-items-start">
                                <button
                                  type="submit"
                                  onClick={getData}
                                  className="btn btn-warning btn-sm "
                                >
                                  Search
                                </button>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-start">
                                <button
                                  type="submit"
                                  onClick={handleCSVDownload}
                                  className="btn btn-primary btn-sm "
                                >
                                  Export
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-lg-12">
              <div className="col-lg-12 grid-margin stretch-card">
                {/* <div className="card">
                  <div className="">
                    <div className="table-responsive">
                      <table className="table table-bordered mb-0">
                        <thead>
                          <tr>
                            <th> Sr.No. </th>
                            <th> Match Name </th>
                            <th> Date </th>
                            <th> Action </th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loader &&
                            data?.map((el) => (
                              <tr>
                                <td> {++index} </td>
                                <td> {el?.event?.name} </td>
                                <td> {new Date( el?.event?.openDate ).toLocaleString()} </td>
                                <td className='d-flex' >
                                  <label className="switch" checked={el?.status == true ? true : false} onChange={() => handleClickCheckBox(el)} >
                                    <input className="switch-input" type="checkbox" checked={el?.status == true ? true : false} />
                                    <span className="switch-label"></span>
                                    <span className="switch-handle"></span>
                                  </label>
                                  <span> { el?.status == true ? "Checked" : "Unchecked" } </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {loader ? (
                        <div className="d-flex justify-content-center">
                          <div className="d-flex justify-content-center">
                          <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only"></span>
                          </div>
                        </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div> */}
                <div className="ag-theme-alpine" style={{ width: '100%' }}>
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={data}
                    domLayout={gridOptions.domLayout}
                    enableCellTextSelection={true}
                    pagination={gridOptions.pagination}
                    paginationPageSize={gridOptions.paginationPageSize}
                    suppressCellSelection={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </MainTemplate>
  );
}

export default MatchSettings;
