import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Form } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import { get_Exposure_List } from '../../../Api/api';
import baseUrl from '../../../Api/baseUrl';
import MainTemplate from '../../../components/MainTemplate';
import ReactSelect from 'react-select';
import csvDownload from 'json-to-csv-export';
import { formatDateString, gridOptions } from '../../../utils/helperFunctin';
import { AgGridReact } from 'ag-grid-react';


function ExposurDetails() {
  const user = JSON.parse(localStorage.getItem("user"))

  const [exposureList, setExposureList] = useState({ data: [], loader: false })
  const [users, setUsers] = useState([])
  const [filter, setFilter] = useState({})

  const getExposureList = async () => {
    try {
      setExposureList({ ...exposureList, loader: true })
      const headers = { headers: { "Content-Type": "application/json", "x-access-token": user?.token } }
      const res = await axios.get(
        baseUrl + get_Exposure_List + `?`
        + `${filter?.user ? `userId=${filter?.user?._id}&` : ""}`
        + `${filter?.minExposure ? `minExposure=${filter?.minExposure}&` : ""}`
        + `${filter?.maxExposure ? `maxExposure=${filter?.maxExposure}&` : ""}`
      )
      if (res?.status == 200) {
        setExposureList({ data: res?.data?.data, loader: false })
      } else {
        setExposureList({ ...exposureList, loader: false })
      }
    } catch (error) {
      setExposureList({ ...exposureList, loader: false })
    }
  }

  const handleCSVDownload = () => csvDownload({ 
		data: exposureList?.data?.map(el => ({
			id: el?.users?._id,
      Name: el?.users?.name,
      Phone: el?.users?.phone,
      Username: el?.users?.username,
      Exposure: el?.total,
		})),
		delimiter: ",", 
		filename:"Betkaro-ExposureList-"+formatDateString(new Date()) 
	})

  const getUserData = async () => {
		try{
			const headers = { headers: { "Content-Type": "application/json", "x-access-token": user?.token } }
			let apiUrl = ""
			if(user?.type === "affiliateAdmin"){
				apiUrl = baseUrl + "/admin/affiliate-user-list?" 				
			} else {
				apiUrl = baseUrl + "/admin/all_user_list?" 
			}
			const res = await axios.get(apiUrl, headers)
			if(res?.data?.data){
				setUsers(res?.data?.data?.map(el => ({...el, value:el?._id , label: el?.name + ", " + el?.phone + ", " + el?.email + ", " + el?.username})))
			}
		} catch(err){
		}
	}

  const columnDefs = [
    { headerName: 'Sr.No.', valueGetter: 'node.rowIndex+1', width: 80 },
    { headerName: 'User Name', field: 'users.username' },
    { headerName: 'Name', field: 'users.name' },
    { headerName: 'Phone', field: 'users.phone' },
    { headerName: 'User Id', field: 'users._id' },
    { headerName: 'Exposure', field: 'total' },
  ];

  useEffect(() => {
    getUserData()
  }, [])

  useEffect(() => {
    getExposureList()
  }, [])

  return (
    <MainTemplate
      categoryName="Exposure Detail"
      categoryList={["Reports", "Exposure Detail"]}
    >
    <div>
      <div className="">
        <div className="card border-0">
          <div className="">
              <div className="table-responsive">
              <table className="table mb-0 table-borderless">
                  <thead>
                    <tr>
                      <th>User search</th>
                      <th>Exposure</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                      <td>
                          <ReactSelect isClearable={true} options={users} value={filter?.user}  className="basic-single" onChange={e => setFilter({...filter, user: e})} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}></ReactSelect>		
                      </td>
                      <td>
                        <div className="d-flex">
                          <input className="form-control me-1" type="number" placeholder="Min Exposure" onChange={e => setFilter({...filter, minExposure: e.target.value})}/>				
                          <input className="form-control" type="number" placeholder="Max Exposure" onChange={e => setFilter({...filter, maxExposure: e.target.value})}/>				
                        </div>
                      </td>
                      <td>
                          <button type="submit" onClick={getExposureList} className="btn btn-warning w-100" >
                            Search
                          </button>
                      </td>
                      <td>
                          <button type="submit" onClick={handleCSVDownload} className="btn btn-primary w-100" >
                            Export
                          </button>
                      </td>
                  </tr>
                  </tbody>
              </table>
              </div>
          </div>
      </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="ag-theme-alpine" style={{ width: '100%' }}>
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={exposureList?.data}
                    domLayout={gridOptions.domLayout}
                    enableCellTextSelection={true}
                    pagination={gridOptions.pagination}
                    paginationPageSize={gridOptions.paginationPageSize}
                    suppressCellSelection={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    </MainTemplate>
  )
}

export default ExposurDetails