import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import axios from 'axios'
import baseUrl from '../../Api/baseUrl'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useRef } from 'react'
import MainTemplate from '../../components/MainTemplate'

function SiteSetting() {

    const [siteData, setSiteData] = useState({})
    const [bannerImagesTemp, setBannerImagesTemp] = useState([])
    const [bannerImages, setBannerImages] = useState([])
    const [bannerLoader, setBannerLoader] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [loader, setLoader] =  useState(false)
    const bannerImgRef = useRef()

    const handleValidation = () => {
        let error = {}
        if(!(!isNaN(siteData?.whatsAppNumber) && (String(siteData?.whatsAppNumber)?.length === 10))){
            error.whatsAppNumber = "Invalid Number"
        }
        if(!(!isNaN(siteData?.refreshTime) && (siteData?.refreshTime > 999) && (siteData?.refreshTime < 20001))){
            error.refreshTime = "Should be between 1000 - 20000"
        }
        if(!siteData?.marqueeText){
            error.marqueeText = "Marquee Text Required"
        }
        return error
    }

    const getSiteSetting = async () => {
        try {
            setLoader(true)
            const Token = localStorage.getItem('token')
            const headers = {
                headers: {
                    // "Content-Type": "application/json",
                    "x-access-token": Token
                }
            }
            const res = await axios.get(baseUrl + '/site-setting', siteData, headers)
            if (res?.status == 200) {
               setSiteData(res?.data?.data)
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const handleValidationObject = handleValidation();
        if (Object.keys(handleValidationObject).length > 0) {
            setFormErrors(handleValidationObject);
        } else {
            setFormErrors({})
            try {
                setLoader(true)
                const Token = localStorage.getItem('token')
                const headers = {
                    headers: {
                        // "Content-Type": "application/json",
                        "x-access-token": Token
                    }
                }
                const res = await axios.post(baseUrl + '/site-setting', siteData, headers)
                if (res?.status == 200) {
                    toast.success('Successfylly updated', { position: "top-center", autoClose: 2000 });
                }
                setLoader(false)
            } catch (error) {
                setLoader(false)
                toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
            }
        }
    }

    const handleChange = e => setSiteData({
        ...siteData,
        [e.target.name]: e.target.value
    })

    const handleBannerChange = e => setBannerImagesTemp([ ...bannerImagesTemp, ...(e.target.files) ])

    const uploadBannerImages = async e => {
        e.preventDefault()
        try{
            if(!bannerImagesTemp?.length){
                return toast.error("Select Image First", { position: "top-center", autoClose: 2000 });
            }
            setLoader(true)
            const Token = localStorage.getItem("token");
            const headers = {
                headers: {
                    "x-access-token": Token,
                },
            };
            const fd = new FormData()
            for (const key of Object.keys(bannerImagesTemp)) {
                fd.append('image', bannerImagesTemp[key])
            }
            fd.append("status", "top")
            const res = await axios.post(baseUrl + `/admin/banner_img`, fd, headers)
            if(res?.data?.data){
                getBannerImages()
                setBannerImagesTemp([])
                setLoader(false)
                bannerImgRef.current.value = ""
            }
        } catch(err) {
            console.log(err)
            setLoader(false)
        }
    } 

    const getBannerImages = async () => {
        try{
            setLoader(true)
            const Token = localStorage.getItem("token");
            const headers = {
                headers: {
                    "x-access-token": Token,
                },
            };
            const res = await axios.get(baseUrl + `/admin/banner_img/top`, headers)
            if(res?.data?.data){
                setBannerImages(res?.data?.data?.reverse())
                setLoader(false)
            }
        } catch(err) {
            console.log(err)
        }
    } 

    const deleteBannerImg = async (index) => {
        try{
            setLoader(true)
            const Token = localStorage.getItem("token");
            const headers = {
                headers: {
                    "x-access-token": Token,
                },
            };
            const img = bannerImages?.[index]
            const res = await axios.delete(baseUrl + `/admin/banner_img?id=` + img?._id, headers)
            if(res?.data?.data){
                getBannerImages()
            }
        } catch(err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getBannerImages()
        getSiteSetting()
    }, [])

    return (
        <MainTemplate
            categoryName="Site Settings"
            categoryList={[]}
        >
        <div>
            <ToastContainer />

            {/* General setting */}
            <div className="page-header">
                <h4 className="page-title">
                    General Setting
                </h4>
            </div>
            <div className="row mb-4">
                <div className="col-lg-12">
                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="card p-4">
                            <form className="forms-sample col-6" onSubmit={handleSubmit}>
                                <Form.Group className="mb-2">
                                    <label htmlFor="exampleInputUsername1">Whatsapp Number</label>
                                    <Form.Control name='whatsAppNumber' maxLength={10} onInput={(e) => {if (e.target.value > e.target.maxLength) {e.target.value = e.target.value.slice(0, e.target.maxLength)}  }} value={siteData?.whatsAppNumber} className={`form-control${formErrors.whatsAppNumber ? "border border-danger" : ""}`} type="number" id="exampleInputUsername1" placeholder="Whatsapp Number" onChange={handleChange} />
                                    <span className='text-danger'>{formErrors.whatsAppNumber}</span>
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <label htmlFor="exampleInputEmail1">Odds Refresh Time (1000 for 1 second)</label>
                                    <Form.Control name='refreshTime' maxLength={5} onInput={(e) => {if (e.target.value > e.target.maxLength) {e.target.value = e.target.value.slice(0, e.target.maxLength)} }} value={siteData?.refreshTime} type="number" className={`form-control${formErrors.refreshTime ? "border border-danger" : ""}`} id="exampleInputEmail1" placeholder="Odds Refresh Time" onChange={handleChange} />
                                    <span className='text-danger'>{formErrors.refreshTime}</span>
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <label htmlFor="exampleInputEmail1">Marquee Text</label>
                                    <Form.Control name='marqueeText' value={siteData?.marqueeText} type="text" className={`form-control ${formErrors.marqueeText ? "border border-danger" : ""}`} id="exampleInputEmail1" placeholder="Marquee Text" onChange={handleChange} />
                                    <span className='text-danger'>{formErrors.marqueeText}</span>
                                </Form.Group>
                                <button type="submit" className="btn btn-warning btn-sm mt-2 mr-2">
                                    Submit
                                    {
                                        loader && <div class="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm" role="status"></div>
                                    }
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Banner setting */}
            <div className="page-header">
                <h4 className="page-title">
                    Banner Setting
                </h4>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="card p-4">
                            <form className="forms-sample" onSubmit={uploadBannerImages}> 
                                <Form.Group>
                                    <label htmlFor="exampleInputUsername1">Banner Images</label>
                                    <Form.Control multiple ref={bannerImgRef} accept="image/png, image/gif, image/jpeg" name='banner' className={`form-control `} type="file" onChange={handleBannerChange} />
                                </Form.Group>
                                <div className="row W-100 px-3">               
                                    {
                                        bannerImagesTemp?.map((img, index) => <span className="col-3 p-1 position-relative">
                                            <button onClick={() =>  setBannerImagesTemp([...bannerImagesTemp?.slice(0, index), ...bannerImagesTemp?.slice(index+1)])} className="position-absolute p-1" style={{top: 0, right: 0}}>X</button>
                                            <img style={{width: "100%", height: "100px", objectFit: "cover"}} src={URL.createObjectURL(img)} />
                                        </span>)
                                    }
                                </div>
                                <button type="submit" className="btn btn-warning btn-sm my-2 mr-2">
                                    Submit
                                    {
                                        loader && <div class="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm" role="status"></div>
                                    }
                                </button>
                            </form>
                            <div className="row W-100 px-3">               
                                {
                                    bannerImages?.map((img, idx) => <span className="col-3 p-1 position-relative">
                                        <button onClick={() => deleteBannerImg(idx)} className="position-absolute p-1" style={{top: 0, right: 0}}>X</button>
                                        <img style={{width: "100%", height: "100px", objectFit: "cover"}} src={img?.image} />
                                    </span>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </MainTemplate>
    )
}

export default SiteSetting