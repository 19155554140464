import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import axios from 'axios'
import baseUrl from '../../../Api/baseUrl'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import MainTemplate from '../../../components/MainTemplate'

function ChangePassword() {
    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        transactionPassword: '12345',
        loader: ''
    })

    const [formErrors, setFormErrors] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        transactionPassword: ''
    })

    const handleChange = (event) => {
        setFormErrors({ ...formErrors, [event.target.name]: '' })
        setPasswordData({ ...passwordData, [event.target.name]: event.target.value })
    }
    const handleValidation = () => {
        const { currentPassword, newPassword, confirmPassword, transactionPassword } = passwordData
        const newErrors = {};
        console.log(newPassword, "JJJK", confirmPassword)
        if (!currentPassword) newErrors.currentPassword = 'Please enter your current password'
        if (!newPassword) newErrors.newPassword = 'Please enter your new password'
        if (!confirmPassword) newErrors.confirmPassword = 'Please re-enter your new password'
        if (!transactionPassword) newErrors.transactionPassword = 'Please enter your tranaction password'
        if (newPassword !== confirmPassword) newErrors.confirmPassword = 'Confirm password is not the same as new password'
        return newErrors
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const handleValidationObject = handleValidation();
        if (Object.keys(handleValidationObject).length > 0) {
            setFormErrors(handleValidationObject);
        }
        else {
            setPasswordData({ ...passwordData, loader: true })
            try {
                const Token = localStorage.getItem('token')
                const headers = {
                    headers: {
                        // "Content-Type": "application/json",
                        "x-access-token": Token
                    }
                }
                const res = await axios.put(baseUrl + '/admin/change_password', { old_password: passwordData.currentPassword, new_password: passwordData.newPassword }, headers)
                if (res?.status == 200) {
                    toast.success('successfylly updated', { position: "top-center", autoClose: 2000 });
                    setPasswordData({
                        ...passwordData,
                        currentPassword: '',
                        newPassword: '',
                        confirmPassword: '',
                        loader: false
                    })
                }
            } catch (error) {
                toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
                setPasswordData({
                    ...passwordData,
                    currentPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                    loader: false
                })
            }
        }
    }
    return (
        <MainTemplate
            categoryName="Change Password"
            categoryList={["Profile", "Change Password"]}
        >
        <div>
            <ToastContainer />
            <div className="page-header">
                <h3 className="page-title">
                    Change Password
                </h3>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="card p-4">
                            <form className="forms-sample col-6" onSubmit={handleSubmit}>
                                <Form.Group>
                                    <label htmlFor="exampleInputUsername1">Current Password</label>
                                    <Form.Control name='currentPassword' value={passwordData.currentPassword} className={`mb-2 form-control${formErrors.currentPassword ? "border border-danger" : ""}`} type="password" id="exampleInputUsername1" placeholder="Current Password" onChange={handleChange} />
                                    <span className='text-danger'>{formErrors.currentPassword}</span>
                                </Form.Group>
                                <Form.Group>
                                    <label htmlFor="exampleInputEmail1">New Password</label>
                                    <Form.Control name='newPassword' value={passwordData.newPassword} type="password" className={`mb-2 form-control${formErrors.newPassword ? "border border-danger" : ""}`} id="exampleInputEmail1" placeholder="New Password" onChange={handleChange} />
                                    <span className='text-danger'>{formErrors.newPassword}</span>
                                </Form.Group>
                                <Form.Group>
                                    <label htmlFor="exampleInputPassword1">Confirm Password</label>
                                    <Form.Control name='confirmPassword' value={passwordData.confirmPassword} type="password" className={`mb-2 form-control${formErrors.confirmPassword ? "border border-danger" : ""}`} id="exampleInputPassword1" placeholder="Confirm Password" onChange={handleChange} />
                                    <span className='text-danger'>{formErrors.confirmPassword}</span>
                                </Form.Group>
                                <Form.Group>
                                    <label htmlFor="exampleInputConfirmPassword1">Transaction Password</label>
                                    <Form.Control disabled name='transactionPassword' value={passwordData.transactionPassword} type="password" className={`mb-2 form-control${formErrors.transactionPassword ? "border border-danger" : ""}`} id="exampleInputConfirmPassword1" placeholder="Transaction Password" onChange={handleChange} />
                                    <span className='text-danger'>{formErrors.transactionPassword}</span>
                                </Form.Group>
                                <button type="submit" className="btn btn-warning btn-sm  mr-2">
                                    Submit{" "}{" "}
                                    {
                                        passwordData.loader && <div class="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm" role="status"></div>
                                    }
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </MainTemplate>
    )
}

export default ChangePassword