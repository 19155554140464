import React from "react";
import { useSelector } from "react-redux";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import Login from "../pages/Login/Login";

import MarketSettings from "../pages/MarketSetting/MarketSettings/MarketSettings";
import MatchSettings from "../pages/MarketSetting/MatchSettings/MatchSettings";
import MarketRollback from "../pages/MarketSetting/MarketRollback/MarketRollback";
import SportsSetting from "../pages/MarketSetting/SportsSetting/SportsSetting";
import SeriesSetting from "../pages/MarketSetting/SeriesSetting/SeriesSetting";
import FancySetting from "../pages/MarketSetting/FancySetting/FancySetting";
import CasinoSetting from "../pages/MarketSetting/CasinoSetting/CasinoSetting";

import AccountStatement from "../pages/Reports/AccountStatement/AccountStatement";
import ExposurDetails from "../pages/Reports/ExposurDetails/ExposurDetails";
import CurrentBets from "../pages/Reports/CurrentBets/CurrentBets";
import GeneralReport from "../pages/Reports/GeneralReport/GeneralReport";
import GameReport from "../pages/Reports/GameReport/GameReport";
import DeleteBetHistory from "../pages/Reports/DeleteBetHistory/DeleteBetHistory";
import DepositPaymentHistory from "../pages/Reports/DepositPaymentHistory/DepositPaymentHistory";
import UserLoginHistory from "../pages/Reports/UserLoginHistory/UserLoginHistory";
import ProfitAndLoss from "../pages/Reports/ProfitAndLoss/ProfitAndLoss";
// import CasinoResultReport from "../pages/Reports/CasinoResultReport/CasinoResultReport";

import Bets from "../pages/Bets/Bets";
// import Verification from "../pages/verification";
import UserList from "../pages/Reports/UserList/userList";
import UserWalletHistory from "../pages/Reports/UserList/UserWalletHistory";
import DepositList from "../pages/Reports/DepositList/depositList";
import WithdrawalList from "../pages/Reports/WithdrawalList/withdrawalList";
import Admins from "../pages/Admins/Admins";
import AddAdmin from "../pages/Admins/AddAdmin";
import SiteSetting from "../pages/SiteSetting/SiteSetting";
import CreateUser from "../pages/CreateUser/CreateUser";

import AccountDetails from "../pages/UserDetails/AccountDetails/AccountDetails";
import ChangePassword from "../pages/UserDetails/ChangePassword/ChangePassword";
import Withdrawalverification from "../pages/UserDetails/Withdrawalverification/Withdrawalverification";
import CreateMessage from "../pages/UserDetails/CreateMessage/CreateMessage";
import { ROLES } from "../utils/helperFunctin";
import FancySettings from "../pages/MarketSetting/FancySettings/FancySettings";

import AgentList from "../pages/AffiliateProgram/AgentList"
import AddAffiliateAgent from "../pages/AffiliateProgram/AddAffiliateAgent";
import Welcome from "../pages/Welcome/Welcome";
import AddAffiliateUser from "../pages/AffiliateProgram/AddAffiliateUser";
import BookmakerSetting from "../pages/MarketSetting/BookmakerSetting/BookmakerSetting";
import FancyBets from "../pages/AGENTS/FancyBets/FancyBets";
import Bookmaker from "../pages/AGENTS/Bookmaker/Bookmaker";
import AgentWalletHistory from "../pages/AffiliateProgram/AgentWalletHistory";

const ScreenRouting = () => {
	const user = useSelector((state) => state.userReducer);

	// Admin Routes
	const getFinanceRoutes = (user?.type === "admin") && (user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.Finance)) ? <>
		<Route path="/deposit-list" element={<DepositList />} />
		<Route path="/withdrawal-list" element={<WithdrawalList />} />
	</> : ""
	const getUsersRoutes = (user?.type === "admin") && (user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.Users)) ? <>
		<Route path="/users-list" element={<UserList />} />
		<Route path="/user-wallet-history" element={<UserWalletHistory />} />
		<Route path="/admins-list" element={<Admins />} />
		<Route path="/add-admin" element={<AddAdmin />} />
		<Route path="/create-users" element={<CreateUser />} />
	</> : ""
	const getProfileRoutes = (user?.type === "admin") && (user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.Profile)) ? <>
		<Route path="/account-details" element={<AccountDetails />} />
		<Route path="/change-password" element={<ChangePassword />} />
	</> : ""
	const getMarketRoutes = (user?.type === "admin") && (user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.Market)) ? <>
		<Route path="/sports-setting" element={<SportsSetting />} />
		<Route path="/series-setting" element={<SeriesSetting />} />
		<Route path="/match-settings" element={<MatchSettings />} />
		<Route path="/market-settings" element={<MarketSettings />} />
		<Route path="/fancy-settings" element={<FancySettings />} />
		<Route path="/bookmaker-settings" element={<BookmakerSetting />} />
	</> : ""
	const getReportRoutes = (user?.type === "admin") && (user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.Report)) ? <>
		<Route path="/bets" element={<Bets />} />
		<Route path="/account-statement" element={<AccountStatement />} />
		<Route path="/exposure-details" element={<ExposurDetails />} />
		<Route path="/current-bets" element={<CurrentBets />} />
		<Route path="/user-login-history" element={<UserLoginHistory />} />
		<Route path="/fancy-bets" element={<FancyBets />} />
		<Route path="/bookmaker-bets" element={<Bookmaker />} />
	</> : ""
	const getCasinoRoutes = (user?.type === "admin") && (user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.Casino)) ? <>
		<Route path="/casino-setting" element={<CasinoSetting />} />
	</> : ""
	const getNotificationRoutes = (user?.type === "admin") && (user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.Notication)) ? <>
		<Route path="/create-message" element={<CreateMessage />} />
	</> : ""
	const getAffiliateRoutes = (user?.type === "admin") && (user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.AffiliateProgram)) ? <>
		<Route path="/agent-list" element={<AgentList />} />
		<Route path="/agent-wallet-history" element={<AgentWalletHistory />} />
		<Route path="/add-agent" element={<AddAffiliateAgent />} />
	</> : ""
	const getSiteSettingRoutes = (user?.type === "admin") && (user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.SiteSetting)) ? <>
		<Route path="/site-setting" element={<SiteSetting />} />
	</> : ""


	// Agent Routes
	const getAgentUsersRoutes = (user?.type === "affiliateAdmin") ? <>
		<Route path="/users-list" element={<UserList />} />
		<Route path="/user-wallet-history" element={<UserWalletHistory />} />
		<Route path="/create-affiliate-users" element={<AddAffiliateUser />} />
		<Route path="/deposit-list" element={<DepositList />} />
		<Route path="/withdrawal-list" element={<WithdrawalList />} />
		<Route path="/account-statement" element={<AccountStatement />} />
		<Route path="/current-bets" element={<CurrentBets />} />
		<Route path="/fancy-bets" element={<FancyBets />} />
		<Route path="/bookmaker-bets" element={<Bookmaker />} />
		<Route path="/account-details" element={<AccountDetails />} />
		<Route path="/casino-setting" element={<CasinoSetting />} />
	</> : ""


	return (
		user?._id
		? 
		<Router>
			<Routes>
				<Route path="/" element={<Welcome />} />
				{/* admin routes access control */}
				{getFinanceRoutes}
				{getUsersRoutes}
				{getProfileRoutes}
				{getMarketRoutes}
				{getReportRoutes}
				{getCasinoRoutes}
				{getNotificationRoutes}
				{getSiteSettingRoutes}
				{getAffiliateRoutes}


				{/* agent route access control */}
				{getAgentUsersRoutes}
				
				<Route path="*" element={<Login />} />
			</Routes>
		</Router>
		:
		<Router>
			<Routes>
				<Route path="/" element={<Login />}></Route>
				<Route path="*" element={<Navigate to="/" />}></Route>
			</Routes>
		</Router>
	)
};

export default ScreenRouting;
