import React from 'react'
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import baseUrl from '../../../Api/baseUrl';
import axios from 'axios'
import { create_Messages, get_Message_List } from "../../../Api/api"
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-bootstrap';
import MainTemplate from '../../../components/MainTemplate';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

function CreateMessage() {
    let index = 1
    const [messageData, setMessageData] = useState({ title: '', description: '', time: '', date: '', loader: false })
    const [messageDataError, setMessageDataError] = useState({ title: '', description: '', time: '', date: '', loader: false })
    const [messageList, setMessageList] = useState({ data: [], loader: false })
    const [isModalOpen, setIsModalOpen] = useState(false)


    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        if (subtitle && subtitle?.style) {
            subtitle.style.color = '#f00';
        }

    }

    function closeModal() {
        setIsModalOpen(false)
    }

    const getMessageList = async () => {
        try {
            setMessageList({ ...messageList, loader: true })
            const token = localStorage.getItem("token");
            const headers = {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": token,
                },
            };
            const res = await axios.get(baseUrl + get_Message_List, headers)
            if (res?.status == 200) {
                setMessageList({ data: res?.data?.data?.reverse(), loader: false })
            } else {
                setMessageList({ ...messageList, loader: false })
            }
        } catch (error) {
            setMessageList({ ...messageList, loader: false })
        }
    }

    const handleChange = (event) => {
        setMessageData({ ...messageData, [event.target.name]: event.target.value })
        setMessageDataError({ ...messageDataError, [event.target.name]: null })
    }

    const handleValidation = () => {
        const newErrrors = {}
        if (messageData.title == '') {
            newErrrors.title = 'Please enter title'
        }
        if (messageData.description == '') {
            newErrrors.description = 'Please enter description'
        }

        if (messageData.date == '') {
            newErrrors.date = 'Please select date'
        }

        // if (messageData.time == '') {
        //     newErrrors.time = 'Please select time'
        // }
        return newErrrors
    }

    const handleSubmitMessage = async (event) => {
        event.preventDefault()
        const handleValidationObject = handleValidation()
        if (Object.keys(handleValidationObject).length > 0) {
            setMessageDataError(handleValidationObject)
        } else {
            try {
                setMessageData({ ...messageData, loader: true })
                const payload = {
                    title: messageData.title,
                    description: messageData.description,
                    push_date: messageData.date,
                    push_time: messageData.time,
                }
                const token = localStorage.getItem("token");
                const headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": token,
                    },
                };
                const responec = await axios.post(baseUrl + create_Messages, payload, headers)
                if (responec.status == 200) {
                    setMessageData({ ...messageData, loader: false })
                    toast.success('message screated', { position: "top-center", autoClose: 2000 });
                    handleCleare()
                    getMessageList()
                    closeModal()
                } else {
                    setMessageData({ ...messageData, loader: false })
                    handleCleare()
                    getMessageList()
                    closeModal()
                }
            } catch (error) {
                setMessageData({ ...messageData, loader: false })
                toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
                handleCleare()
                getMessageList()
                closeModal()
            }
        }
    }

    const handleCleare = () => {
        setMessageData({
            ...messageData,
            title: "",
            description: "",
            time: "",
            date: "",
            loader: false
        })
    }

    useEffect(() => {
        getMessageList()
    }, [])

    return (
        <MainTemplate
            categoryName="Notification"
            categoryList={[]}
        >
        <div>
            <ToastContainer />
            <div>
                <div className="">
                    {/* <div className="row">
                        <div className="col-lg-12">
                            <div className="col-lg-12 stretch-card">
                                <div className="card">
                                    <div className="mt-5">
                                        <div className="table-responsive">
                                            <table className="table table-bordered mb-0">
                                                <thead>
                                                    <tr>
                                                        <th> Sport Name </th>
                                                        <th> Series Name </th>
                                                        <th> Match Name </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <Form.Group>
                                                                <select onChange={handleSportsChange} className="form-control form-control-sm" id="exampleFormControlSelect3">
                                                                    <option value="" selected>All</option>
                                                                    {
                                                                        sportsData?.map((el, index) => <option value={el?.eventType} index={index}>{el?.name}</option>)
                                                                    }
                                                                </select>
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Form.Group>
                                                                <select onChange={handleSeriesChange} className="form-control form-control-sm" id="exampleFormControlSelect3">
                                                                    <option value="" selected>All</option>
                                                                    {
                                                                        seriesData?.map(el => <option value={el?.competition?.id}>{el?.competition?.name}</option>)
                                                                    }
                                                                </select>
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Form.Group>
                                                                <select onChange={handleMatchChange} className="form-control form-control-sm" id="exampleFormControlSelect3" >
                                                                    <option value="" selected>All</option>
                                                                    {
                                                                        matchesData?.map(el => <option value={el?.id}>{el?.name}</option>)
                                                                    }
                                                                </select>
                                                            </Form.Group>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-lg-12 d-flex justify-content-end">
                            <button type="submit" className="btn btn-warning btn-sm  mr-4 m-3" onClick={() => setIsModalOpen(true)} >Create Message</button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="">
                                        <div className="table-responsive">
                                            <table className="table table-bordered mb-0">
                                                <thead>
                                                    <tr>
                                                        <th> Sr.No. </th>
                                                        <th> Titile </th>
                                                        <th> Desscription </th>
                                                        <th> Date </th>
                                                        {/* <th> Time </th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        messageList?.loader == false && messageList?.data?.map((el, ind) => {
                                                            return <tr>
                                                                <td> {index++}. </td>
                                                                <td> {el?.title} </td>
                                                                <td> {el?.description} </td>
                                                                <td> {el?.push_date} </td>
                                                                {/* <td> {el?.push_time} </td> */}
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                messageList?.loader
                                                    ?
                                                    <div className="d-flex justify-content-center">
                                                        <div class="d-flex justify-content-center">
  <div class="spinner-border spinner-border-sm" role="status">
    <span class="sr-only"></span>
  </div>
</div>
                                                    </div>
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Message Modal */}
                    {/* <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <button onClick={closeModal}>close</button>
                        <div className="grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <form className="forms-sample" onSubmit={handleSubmitMessage}>
                                        <Form.Group>
                                            <label htmlFor="exampleInputUsername1">Title</label>
                                            <Form.Control type="text" id="exampleInputUsername1" value={messageData.title} name='title' onChange={handleChange} className={` ${messageDataError?.title && 'border border-danger'}`} placeholder="Title" size="lg" />
                                            <span className='text-danger' >{messageDataError.title}</span>
                                        </Form.Group>
                                        <Form.Group>
                                            <label htmlFor="exampleInputEmail1">Desscription</label>
                                            <Form.Control type="text" id="exampleInputEmail1" value={messageData.description} name='description' onChange={handleChange} className={` ${messageDataError?.description && 'border border-danger'} form-control`} placeholder="Description" />
                                            <span className='text-danger' >{messageDataError.description}</span>
                                        </Form.Group>
                                        <Form.Group>
                                            <label htmlFor="exampleInputPassword1">Date</label>
                                            <Form.Control type="date" max={new Date().toISOString().split('T')[0]} id="exampleInputPassword1" value={messageData.date} name='date' onChange={handleChange} className={` ${messageDataError?.date && 'border border-danger'} form-control`} placeholder="Date" />
                                            <span className='text-danger' >{messageDataError.date}</span>
                                        </Form.Group>
                                        <Form.Group>
                                            <label htmlFor="exampleInputConfirmPassword1">Time</label>
                                            <Form.Control type="time" id="exampleInputConfirmPassword1" value={messageData.time} name='time' onChange={handleChange} className={` ${messageDataError?.time && 'border border-danger'} form-control`} placeholder="Time" />
                                            <span className='text-danger' >{messageDataError.time}</span>
                                        </Form.Group>
                                        <button type="submit" className="btn btn-warning btn-sm  mr-2" >Submit{" "}
                                            {
                                                messageData?.loader
                                                &&
                                                <div class="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm" role="status"></div>
                                            }
                                        </button>
                                        <button className="btn btn-warning btn-sm  mr-2" type='reset' onClick={handleCleare}>Cleare</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal> */}
                    <Modal show={isModalOpen}
                        onHide={setIsModalOpen}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Create Message</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form className="forms-sample" onSubmit={handleSubmitMessage}>
                                <Form.Group>
                                    <label htmlFor="exampleInputUsername1">Title</label>
                                    <Form.Control type="text" id="exampleInputUsername1" value={messageData.title} name='title' onChange={handleChange} className={`  mb-2 ${messageDataError?.title && 'border border-danger'}`} placeholder="Title" size="lg" />
                                    <span className='text-danger' >{messageDataError.title}</span>
                                </Form.Group>
                                <Form.Group>
                                    <label htmlFor="exampleInputEmail1">Desscription</label>
                                    <Form.Control type="text" id="exampleInputEmail1" value={messageData.description} name='description' onChange={handleChange} className={`  mb-2 ${messageDataError?.description && 'border border-danger'} form-control`} placeholder="Description" />
                                    <span className='text-danger' >{messageDataError.description}</span>
                                </Form.Group>
                                <Form.Group>
                                    <label htmlFor="exampleInputPassword1">Date</label>
                                    <Form.Control type="date" max={new Date().toISOString().split('T')[0]} id="exampleInputPassword1" value={messageData.date} name='date' onChange={handleChange} className={`  mb-2 ${messageDataError?.date && 'border border-danger'} form-control`} placeholder="Date" />
                                    <span className='text-danger' >{messageDataError.date}</span>
                                </Form.Group>
                                {/* <Form.Group>
                                    <label htmlFor="exampleInputConfirmPassword1">Time</label>
                                    <Form.Control type="time" id="exampleInputConfirmPassword1" value={messageData.time} name='time' onChange={handleChange} className={`  mb-2 ${messageDataError?.time && 'border border-danger'} form-control`} placeholder="Time" />
                                    <span className='text-danger' >{messageDataError.time}</span>
                                </Form.Group> */}
                                <button type="submit" className="btn btn-warning btn-sm  mt-4 me-2" >Submit{" "}
                                    {
                                        messageData?.loader
                                        &&
                                        <div class="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm" role="status"></div>
                                    }
                                </button>
                                <button className="btn btn-warning btn-sm  mt-4 me-2" type='reset' onClick={handleCleare}>Clear</button>
                            </form>
                        </Modal.Body>
                    </Modal>

                </div>
            </div>
        </div>
        </MainTemplate>
    )
}

export default CreateMessage