import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Form } from 'react-bootstrap'
import { get_Current_bets, get_agent_Current_bets } from '../../../Api/api'
import baseUrl from '../../../Api/baseUrl'
import MainTemplate from '../../../components/MainTemplate'
import csvDownload from 'json-to-csv-export'
import { customModalStyles, formatDateString, gridOptions } from '../../../utils/helperFunctin'
import ReactSelect from 'react-select'
import { AgGridReact } from 'ag-grid-react'
import { toast } from 'react-toastify'
import ReactModal from 'react-modal'

function CurrentBets() {
  const user = JSON.parse(localStorage.getItem("user"))	
  const [chooseType, setChoosType] = useState("")
  const [currentBetsList, setCurrentBetsList] = useState({ data: [], loader: false })
  const [filter, setFilter] = useState({})
  const [users, setUsers] = useState([])
  const [modal, setModal] = useState()


  const handleGetCurrentBets = async () => {
    const Token = localStorage.getItem('token')
    const headers = {
        headers: {
            // "Content-Type": "application/json",
            "x-access-token": Token
        }
    }
    setCurrentBetsList({ ...currentBetsList, loader: true })
    const reponce = await axios.get(baseUrl + (user?.type === "admin" ? get_Current_bets : get_agent_Current_bets) + `?`
      + `${filter?.search ? `search=${filter?.search}&` : ""}`
      + `${filter?.eventSearch ? `eventSearch=${filter?.eventSearch}&` : ""}`
      + `${filter?.runnerSearch ? `runnerSearch=${filter?.runnerSearch}&` : ""}`
      + `${filter?.dateFrom ? `dateFrom=${filter?.dateFrom}&` : ""}`
      + `${filter?.dateTo ? `dateTo=${filter?.dateTo}&` : ""}`
      + `${filter?.minAmount ? `minAmount=${filter?.minAmount}&` : ""}`
      + `${filter?.maxAmount ? `maxAmount=${filter?.maxAmount}&` : ""}`
      + `${filter?.type ? `type=${filter?.type}&` : ""}`
      + `${filter?.user ? `userId=${filter?.user?._id}&` : ""}`
      , headers
    )
    if (reponce.status == 200) {
      setCurrentBetsList({ ...currentBetsList, data: reponce?.data?.data, loader: false })
    } else {
      setCurrentBetsList({ ...currentBetsList, loader: false })
    }
  }

  const abondonBet = async betId => {
		try{
      setModal({...modal, loader: true})
      const token = localStorage.getItem("token");
			const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
			const res = await axios.post(baseUrl + "/admin/user-match-abandon", {status: "market", betId }, headers)
			if(res?.data?.data){
				toast.success('Bet Abandoned', { position: "top-center", autoClose: 2000 });
        setModal()
        handleGetCurrentBets()
			}
		} catch(err){
			console.log(err)
      setModal({...modal, loader: false})
		}
	}

  const getUsersData = async () => {
		try{
      const token = localStorage.getItem("token");
			const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
			const res = await axios.get(baseUrl + "/admin/all_user_list", headers)
			if(res?.data?.data){
				setUsers(res?.data?.data?.reverse()?.map(el => ({...el, value:el?._id, label: el?.name+", "+el?.email+", "+el?.phone})))
			}
		} catch(err){
			console.log(err)
		}
	}

  const handleCSVDownload = () => csvDownload({ 
		data: currentBetsList?.data?.map(el => ({
			eventType: el?.oddsBackup[0]?.marketType[0]?.marketName,
			eventName: el?.oddsBackup[0]?.event?.name,
      Username: el?.user_id.username,
      name: el?.user_id.name,
      email: el?.user_id.email,
      phone: el?.user_id.phone,
			SelectionName: el?.oddsBackup[0]?.marketType[0]?.runners.find(e => +e?.selectionId === +el?.selectionId)?.runnerName,
      BetType: el?.type,
      Amount: el?.stake,
      PlaceDate: formatDateString(el?.createdAt),
      MatchDate: formatDateString(el?.oddsBackup[0]?.marketType[0]?.marketStartTime),
		})),
		delimiter: ",", 
		filename:"Betkaro-ExchangeBets-"+formatDateString(new Date()) 
	})

  const columnDefs = [
    { headerName: 'Sr.No.', valueGetter: 'node.rowIndex + 1', width: 80 },
    { headerName: 'Event Type', valueGetter: (params) => params?.data?.oddsBackup[0]?.marketType[0]?.marketName,  width: 150},
    { headerName: 'Event Name', valueGetter: (params) => params?.data?.oddsBackup[0]?.event?.name },
    { headerName: 'Username', field: 'user_id.username' },
    { headerName: 'Player name', field: 'user_id.name' },
    { headerName: 'email', field: 'user_id.email' },
    { headerName: 'phone', field: 'user_id.phone' },
    { headerName: 'Selection Name', valueGetter: (params) => params?.data?.oddsBackup[0]?.marketType[0]?.runners.find(el => +el?.selectionId === +params?.data?.selectionId)?.runnerName },
    { headerName: 'Bet Type', field: 'type' },
    { headerName: 'Stake', field: 'stake', width: 90 },
    { headerName: 'Odds', field: 'odds', width: 90 },
    { 
      headerName: 'Estimated Profit/Liability', 
      cellRenderer: (params) => {
        const {stake, odds, type} = params?.data
        const estimatedValue = parseFloat((stake*odds) - stake).toFixed(2)
        return <span className={type === "Back" ? "text-success" : "text-danger"}>{type === "Back" ? "+" : "-"}{estimatedValue}</span>
      },
      valueGetter: (params) => {
        const { stake, odds, type } = params?.data;
        const estimatedValue = parseFloat((stake * odds) - stake).toFixed(2);
        return type === 'Back' ? estimatedValue : -estimatedValue;
      },
      sortable: true
    },
    { headerName: 'Place Date', valueGetter:  (params) => formatDateString(params?.data?.createdAt) },
    { headerName: 'Match Date', valueGetter: (params) => formatDateString(params?.data?.oddsBackup[0]?.marketType[0]?.marketStartTime)},
    { headerName: 'Action', cellRenderer: (params) => <button className="btn btn-danger btn-sm" onClick={() => setModal(params?.data)}>Abandon</button> },
  ];

  useEffect(() => {
    handleGetCurrentBets()
  }, [])

  useEffect(() => {
    getUsersData()
  }, []);


  return (
    <MainTemplate
      categoryName="Exchange Bets"
      categoryList={["Live Bets", "Exchange Bets"]}
    >
    <div>
      <div className="">

          <div className="row ml-1 mb-2">
          <div className="col-md-2 mb-1">
							<label><small>Event Search</small></label>
							<input className="form-control " type="text" placeholder="Search" onChange={e => setFilter({...filter, eventSearch: e.target.value})}/>
						</div>
						{/* <div className="col-md-2 mb-1">
							<label><small>Match Search</small></label>
							<input className="form-control " type="text" placeholder="Search" onChange={e => setFilter({...filter, search: e.target.value})}/>
						</div> */}
						<div className="col-md-4 mb-1">
              <div className="row">
                <label className="col-6"><small>Date From</small></label>
                <label className="col-6"><small>Date To</small></label>
              </div>
              <div className="d-flex">
                <input className="form-control  me-1" type="date" max={new Date().toISOString().split('T')[0]} onChange={e => setFilter({...filter, dateFrom: e.target.value})}/>
                <input className="form-control " type="date" max={new Date().toISOString().split('T')[0]} onChange={e => setFilter({...filter, dateTo: e.target.value})}/>
              </div>
							
						</div>
						<div className="col-md-4 mb-1">
							<label><small>Bet Amount</small></label>
							<div className="d-flex">
								<input className="form-control  me-1" type="number" placeholder="Min Amount" onChange={e => setFilter({...filter, minAmount: e.target.value})}/>									
								<input className="form-control " type="number" placeholder="Max Amount" onChange={e => setFilter({...filter, maxAmount: e.target.value})}/>									
							</div>
						</div>
						<div className="col-md-2 mb-1 d-flex align-items-end">
							<button type="button" className="btn btn-primary btn-sm w-100 mb-1 me-2" onClick={handleCSVDownload}>Export</button>
						</div>            
            <div className="col-md-2 mb-1">
							<label><small>Selection Search</small></label>
							<input className="form-control " type="text" placeholder="Search" onChange={e => setFilter({...filter, runnerSearch: e.target.value})}/>
						</div>
            <div className="col-md-2 mb-1">
							<label><small>Bet Type</small></label>
              <select className="form-control" onChange={e => setFilter({...filter, type: e.target.value})}>
                <option value="">Select Type</option>
                <option value="Back">Back</option>
                <option value="Lay">Lay</option>
              </select>
						</div>
            <div className="col-3 mb-1">
              <label><small>Select User</small></label>
              <ReactSelect className="basic-single" isClearable={true} options={users} value={filter?.user} onChange={e => setFilter({...filter, user: e})} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}/>
            </div>
            <div className="col-md-2 mb-1 d-flex align-items-end">
							<button type="button" className="btn btn-warning btn-sm w-100 mb-1 me-2" onClick={handleGetCurrentBets}>Search</button>
						</div>
						
					</div>

          <div className="ag-theme-alpine" style={{ width: '100%' }}>
							<AgGridReact
								columnDefs={columnDefs}
								rowData={currentBetsList?.data}
								domLayout={gridOptions.domLayout}
                enableCellTextSelection={true}
								pagination={gridOptions.pagination}
								paginationPageSize={gridOptions.paginationPageSize}
								suppressCellSelection={true}
							/>
						</div>
      </div>
    </div>
    <ReactModal
			isOpen={modal}
			onRequestClose={() => setModal()}
			style={customModalStyles}
		>
			<h1 className="text-center mb-4">Abandon Bet</h1>
			<h5 className="text-danger text-center">This action is irreversible</h5>
			<div className="">Event Type: {modal?.oddsBackup[0]?.marketType[0]?.marketName}</div>
			<div className="">Event Name: {modal?.oddsBackup[0]?.event?.name}</div>
			<div className="">Username: {modal?.user_id?.name}</div>
			<div className="">Selction Name: {modal?.oddsBackup[0]?.marketType[0]?.runners.find(el => +el?.selectionId === +modal?.selectionId)?.runnerName}</div>
			<div className="">Bet Type: {modal?.type}</div>
			<div className="">Stake: {modal?.stake}</div>
			<div className="">Odds: {modal?.odds}</div>
			<div className="">Place Date: {modal?.createdAt ? formatDateString(modal?.createdAt) : "-"}</div>

      <div className="row mt-3">
        <div className="col-6">
          <button className="btn btn-warning w-100" onClick={() => setModal()}>Cancel</button>
        </div>
        <div className="col-6">
          <button type="button" disabled={modal?.loader} onClick={() => abondonBet(modal?._id)} className="btn btn-danger w-100" >
          {
            modal?.loader
            ?
            <div class="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm" role="status" />
            :
            "Abandon"
          }
          </button>
        </div>
      </div>
			
		</ReactModal>

    </MainTemplate>
  )
}

export default CurrentBets