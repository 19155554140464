import React from 'react'
import { useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import baseUrl from '../../Api/baseUrl'
import './Admins.css'
import { useHistory, useLocation } from "react-router-dom";
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ROLES, validatePassword } from '../../utils/helperFunctin';
import MainTemplate from '../../components/MainTemplate';
const animatedComponents = makeAnimated();

const accessList = [    
    { value: ROLES.All, label: ROLES.All},
    { value: ROLES.Dashboard, label: ROLES.Dashboard},
    { value: ROLES.Finance, label: ROLES.Finance},
    { value: ROLES.Users, label: ROLES.Users},
    { value: ROLES.Profile, label: ROLES.Profile},
    { value: ROLES.Market, label: ROLES.Market },
    { value: ROLES.Report, label: ROLES.Report },
    { value: ROLES.Casino, label: ROLES.Casino },
    { value: ROLES.Notification, label: ROLES.Notification },
    { value: ROLES.SiteSetting, label: ROLES.SiteSetting },
    { value: ROLES.AffiliateProgram, label: ROLES.AffiliateProgram }
];

const AddAdmin = () => {

    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
        const adminObj = location?.state?.admin || {}
        const isUpdate = location?.state?.update || false
        const accessDetails = location?.state?.admin?.access?.map(el => ({"value":el, "label": el})) || []
        setAdmin({...adminObj, access: accessDetails || []})
        setIsUpdate(isUpdate)
    }, [location?.state?.admin])

    const [admin, setAdmin] = useState({})
    const [errorAdmin, setError] = useState({})
    const [submittedOnce, setSubmittedOnce] = useState(false)
    const [loader, setLoader] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)

    const handleChange = e => {
        setAdmin({...admin, [e.target.name]: e.target.value})
    }

    const validation = () => {
        let error = {}
        if(!admin?.username){ error.username = "Username Required" }
        if(!(admin?.phone )){ error.phone = "Phone Required" }
        if(!(!isNaN(admin?.phone) && admin?.phone?.length === 10)){ error.phone = "Invalid Phone Number" }
        // if(!(admin?.email)){ error.email = "Email Invalid" }
        // if(!(validateEmail(admin?.email))){ error.email = "Email Invalid" }
        if(!admin?.role){ error.role = "Role Required" }
        if(!(admin?.access && admin?.access?.length > 0)){ error.access = "Access Required" }
        if(!isUpdate){
            if(!(admin?.password && validatePassword(admin?.password))){ error.password = "Min 8 letter password, with at least a symbol, upper and lower case letters and a number" }
            if(!((admin?.password === admin?.confirmPassword) && admin?.confirmPassword)){ error.confirmPassword = "Confirm password didn't matched" }
        }
        setError(error)
        
        if(!Object?.keys(error)?.length > 0 ){
            return true
        } else {
            return false
        }
    }


    const submit = async e => {
        try {
            e.preventDefault()
            setSubmittedOnce(true)
            
            if(validation()){
                setLoader(true)
                const access = admin?.access?.map(el => el?.label)
                const token = localStorage.getItem("token");
                const headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": token,
                    },
                };
                const adminData = isUpdate ? {...admin, id: admin?._id, access} : {...admin, access}
                const res = await axios.post(baseUrl+"/admin/create_subadmin", adminData , headers)
                setLoader(false)
                toast.success('Admin Added', { position: "top-center", autoClose: 2000 })
                navigate("/admins-list")
            }
        } catch(err){
            console.log(err)
            setLoader(false)
            toast.error('Something went wrong', { position: "top-center", autoClose: 2000 });
        }
    }

    useEffect(() => {
        if(admin && submittedOnce){
            validation()
        }
    }, [admin])


    return (
        <MainTemplate
            categoryName="Reports"
            categoryList={["Reports", "Add Admins"]}
        >
        <div>
            <ToastContainer />
            <div className="col-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Add Admin</h4>
                        <form className="forms-sample" onSubmit={submit}>
                            <Form.Group>
                                <label htmlFor="exampleInputName1">User name</label>
                                <Form.Control name="username" value={admin?.username} onChange={handleChange} type="text" className={`form-control ${errorAdmin?.username ? "border-danger" : ""}`} id="exampleInputName1" placeholder="Name" />
                                <code>{errorAdmin?.username}</code>
                            </Form.Group>
                            {/* <Form.Group>
                                <label htmlFor="exampleInputEmail2">Email address</label>
                                <Form.Control name="email" value={admin?.email} onChange={handleChange} type="email" className={`form-control ${errorAdmin?.email ? "border-danger" : ""}`} id="exampleInputEmail2" placeholder="Email" />
                                <code>{errorAdmin?.email}</code>
                            </Form.Group> */}
                            <Form.Group>
                                <label htmlFor="exampleInputName3">Phone</label>
                                <Form.Control name="phone" maxLength = "10" value={admin?.phone} onChange={handleChange} type="number" onInput={e => (e.target?.value?.length > e.target?.maxLength) ? e.target.value = e.target.value.slice(0, e.target.maxLength): ""} className={`form-control ${errorAdmin?.phone ? "border-danger" : ""}`} id="exampleInputName3" placeholder="Name" />
                                <code>{errorAdmin?.phone}</code>
                            </Form.Group>
                            <Form.Group>
                                <label htmlFor="exampleInputName4">Role</label>
                                <Form.Control name="role" value={admin?.role} onChange={handleChange} type="text" className={`form-control ${errorAdmin?.role ? "border-danger" : ""}`} id="exampleInputName4" placeholder="Role" />
                                <code>{errorAdmin?.role}</code>
                            </Form.Group>
                            <Form.Group>
                                <label htmlFor="exampleInputName1">Access</label>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    name="access"
                                    defaultValue={admin?.access}
                                    value={admin?.access}
                                    onChange={val => handleChange({target: {name: "access", value: val}})}
                                    placeholder="Select Access"
                                    options={accessList}
                                    className={errorAdmin?.access ? "border-danger" : ""}
                                    styles={{ control: (provided, state) => ({ ...provided, border: errorAdmin?.access ? "1px solid #fe7c96 !important" : "", })}}
                                />
                                <code>{errorAdmin?.access}</code>
                            </Form.Group>
                            <Form.Group>
                                <label htmlFor="exampleInputPassword5">Password</label>
                                <Form.Control name="password" value={admin?.password} onChange={handleChange} type="password" className={`form-control ${errorAdmin?.password ? "border-danger" : ""}`} id="exampleInputPassword5" placeholder="Password" />
                                <code>{errorAdmin?.password}</code>
                            </Form.Group>
                            <Form.Group>
                                <label htmlFor="exampleInputPassword6">Confirm Password</label>
                                <Form.Control name="confirmPassword" value={admin?.confirmPassword} onChange={handleChange} type="password" className={`form-control ${errorAdmin?.confirmPassword ? "border-danger" : ""}`} id="exampleInputPassword6" placeholder="Password" />
                                <code>{errorAdmin?.confirmPassword}</code>
                            </Form.Group>
                            <button type="submit" className="btn btn-warning btn-sm me-2 mt-2" onClick={submit} disabled={loader}>
                                {
                                    loader
                                    ?
                                    <div class="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm" role="status"></div>
                                    :
                                    "Submit"
                                }
                            </button>
                            <button className="btn btn-light btn-sm mt-2" onClick={(e) => {e.preventDefault(); navigate(-1)}}>Cancel</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </MainTemplate>
    )
}

export default AddAdmin