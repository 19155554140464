import React from 'react'
import { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import baseUrl from '../../../Api/baseUrl';
import axios from 'axios'
import { fetchAllSports, getMarketSettings, market_Status } from "../../../Api/api"
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../SportsSetting/SportsSetting.css'
import MainTemplate from '../../../components/MainTemplate';
import csvDownload from 'json-to-csv-export';
import { formatDateString, gridOptions } from '../../../utils/helperFunctin';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import ReactSelect from 'react-select';

function MarketSettings() {

    let index = 0
    const [data, setData] = useState([])
    const [sportsData, setSportsData] = useState([])
    const [status, setStatus] = useState();
    const [selectedSports, setSelectedSports] = useState("")
    const [seriesData, setSeriesData] = useState([])
    const [selectedSeries, setSelectedSeries] = useState("")
    const [matchesData, setMatchesData] = useState([])
    const [selectedMatch, setSelectedMatch] = useState("")
    const [loader, setLoader] = useState(false)
    const [checkInData, setCheckInData] = useState({ CheckIn: false, data: "" })
    const [checkIn, setCheckIn] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [matchId, setMatchId] = useState('')
    const [marketId, setMarketId] = useState('')
    const [matchMarkets, setMatchMarkets] = useState({})
    const [declareList, setDeclareList] = useState({ data: [], loader: false })
    const [search, setSearch] = useState();
    const [date, setDate] = useState({});

    const columnDefs = [
        { headerName: 'Sr.No.', valueGetter: 'node.rowIndex+1', width: 80 },
        { headerName: 'Series Name', field: 'seriesDetails.name' },
        { headerName: 'Match Name', field: 'matchDetails.MatchName' },
        { headerName: 'Match Date', cellRenderer: (params) => formatDateString(params?.data?.matchDetails?.openDate) },
        { headerName: 'Market Name', field: 'marketName' },
        { headerName: 'Market Id', field: 'marketId' },
        {
          headerName: 'Action',
          cellRenderer: (params) => (
            <div className='d-flex'>
              <label className="switch" onChange={() => handleCheck(params.data)}>
                <input
                  className="switch-input"
                  type="checkbox"
                  checked={params.data.status !== false}
                />
                <span className="switch-label"></span>
                <span className="switch-handle"></span>
              </label>
              <span>{params.data.status === false ? 'UnChecked' : 'Checked'}</span>
            </div>
          ),
        },
        {
          headerName: 'Declare Result',
          cellRenderer: (params) => (
            params?.data?.matchStatus === "declared"
            ?
            <button
              type="submit"
              disabled={true}
              className="btn btn-secondary btn-sm mr-2"
            >
              Declared
            </button>
            :
            <button
              type="submit"
              disabled={params.data.status === false}
              className="btn btn-warning btn-sm mr-2"
              onClick={() => handleDeclaration(params.data)}
            >
              Declare
            </button>
          ),
        },
    ];
    

    const handleClose = () => {
        setIsModalOpen(false);
    };
    const [selectedRunner, setSelectedRunner] = useState("");
    const [selectedRunnerError, setSelectedRunnerError] = useState("");

    function handleRunnerSelect(event) {
        setSelectedRunner(event.target.value);
    }

    const handleCSVDownload = () => csvDownload({ 
		data: data?.map(el => ({
			id: el?._id,
			seriesName: el?.seriesDetails?.name,
			matchName: el?.matchDetails?.MatchName,
			matchDate: formatDateString(el?.matchDetails?.openDate),
			marketName: el?.marketName,
			marketId: el?.marketId
		})),
		delimiter: ",", 
		filename:"Betkaro-MarketSetting-"+formatDateString(new Date()) 
	})

    async function handleSubmit(event) {
        event.preventDefault();
        try {

            if (selectedRunner != "") {
                setDeclareList({ ...declareList, loader: true })
                const payload = {
                    market_id: marketId,
                    match_id: matchId,
                    team: selectedRunner,
                    status: "declare"
                }

                const Token = localStorage.getItem("token");
                const headers = {
                    headers: {
                        "x-access-token": Token,
                    },
                };

                let declareResult = await axios.post(baseUrl + '/admin/event/declare', payload, headers)
                if (declareResult.status = 200) {
                    setDeclareList({ ...declareList, loader: false })
                    toast.success('Result declared', { position: "top-center", autoClose: 2000 });
                    getData()
                    handleClose()
                    handleCleare()
                }
                else {
                    handleClose()
                    setDeclareList({ ...declareList, loader: false })
                    handleCleare()
                }
            } else {
                setSelectedRunnerError("Please select runner")
            }

        } catch (error) {
            toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
            handleClose()
            setDeclareList({ ...declareList, loader: false })
            handleCleare()
        }
    }

    const handleCleare = () => {
        setSelectedRunnerError("")
        setMatchId("")
        setMarketId("")
        setSelectedRunner("")
    }


    const getData = async () => { 
        setLoader(true)
        const res = await axios.get(baseUrl + getMarketSettings + `?` +
            `${status ? `status=${status === "true" ? true : status ==="false" ? false : "" }` : ""}` +
            // `${selectedSports?.eventType ? `eventId=${selectedSports?.eventType}` : ""}` +
            // `${selectedSeries?.competition?.id ? `&competitionId=${selectedSeries?.competition?.id}` : ""}` +
            `${selectedSports?.competition?.id ? `&seriousId=${selectedSports?.competition?.id}` : ""}`+
            `${search ? `&search=${search}` : ""}`+
            `${date?.dateFrom ? `&dateFrom=${date?.dateFrom}` : ""}`+
            `${date?.dateTo ? `&dateTo=${date?.dateTo}` : ""}`
        )
        if (res?.data?.data) {
            // let sanitizeData = []
            // res?.data?.data?.map(el => {
            //     el?.matches?.map(ele => {
            //         const data = { ...el, match: ele }
            //         sanitizeData?.push(data)
            //     })
            // })
            // sanitizeData = sanitizeData?.filter((value, index, self) => index === self.findIndex((t) => ( t.Id === value.Id  )))
            setData(res?.data?.data)
        }
        setLoader(false)
    }


    const getAllSportsData = async () => {
        const res = await axios.get(baseUrl + fetchAllSports)
        if (res?.data?.data) {
            setSportsData([{value: "", label: "All"}, ...res?.data?.data?.map(el => ({...el, value: el?.competition?.id, label: el?.competition?.name}))]);
        }
    }
    const handleSportsChange = e => {
        setSelectedSports(e)
    }

    useEffect(() => {
        getAllSportsData()
    }, [])

    const handleCheck = async (ele) => {
        setCheckInData({ ...checkInData, CheckIn: !checkInData?.CheckIn, data: ele })
        setCheckIn(!checkIn)
    }

    const handleCheckVerify = async () => {
        const token = localStorage.getItem("token");
        const headers = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
            },
        };
        const responce = await axios.put(baseUrl + market_Status +
            `${checkInData?.data?.status == false ? `/true` : "/false"}` +
            `${checkInData?.data ? `/${checkInData?.data?.marketId}` : ""}`
            // "?"+
            // `${search ? `&search=${search}` : ""}` +
            // `${date?.dateFrom ? `dateFrom=${date?.dateFrom}&` : ""}` +
            // `${date?.dateTo ? `dateTo=${date?.dateTo}&` : ""}`
            , {},
            headers
        )
        if (responce.status == 200) {
            toast.success(responce?.data?.data, { position: "top-center", autoClose: 2000 });
            getData()
            setCheckInData({ ...checkInData, CheckIn: false, data: [] })
        } else {
            getData()
            setCheckInData({ ...checkInData, CheckIn: false, data: [] })
        }
    }

    const handleDeclaration = async (el) => {
        setMarketId(el?.marketId)
        setMatchId(el?.matchDetails?.matchId)
        setIsModalOpen(true)
        setDeclareList({ ...declareList, data: el })
    }

    useEffect(() => {
        if (selectedRunner) {
            setSelectedRunnerError("")
        }
    }, [selectedRunner])

    useEffect(() => {
        handleCheckVerify()
    }, [checkIn])

    // useEffect(() => {
    //     getMatchData()
    // }, [matchId])

    useEffect(() => {
        getData()
    }, [
        // selectedSports, status, search, date
        // selectedSeries, 
        // selectedMatch
    ])

    return (
        <MainTemplate
            categoryName="Market Setting"
            categoryList={["Market Setting", "Market Setting"]}
        >
            <div>
                <ToastContainer />
                <div>
                    <div className="">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="col-lg-12 stretch-card mb-2">
                                    <div className="">
                                        <div className="">
                                            <div className="table-responsive">
                                            <table className="table table-borderless mb-0">
                                                <thead>
                                                <tr>
                                                    <th> Status </th>
                                                    <th> Series Name </th>
                                                    <th className="row m-0"> 
                                                        <span className="col-6">Date From</span> 
                                                        <span className="col-6">Date To</span> 
                                                    </th>
                                                    <th> Match Search </th>
                                                    <th> </th>
                                                    <th> </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                    <Form.Group>
                                                        <select
                                                            onChange={(e) => setStatus(e.target.value)}
                                                            className="form-control"
                                                            id="exampleFormControlSelect3"
                                                        >
                                                        <option value="" selected>All</option>
                                                        <option value="true">Active</option>
                                                        <option value="false">Inactive</option>
                                                        </select>
                                                    </Form.Group>
                                                    </td>
                                                    <td>
                                                    <Form.Group>
                                                        <ReactSelect options={sportsData} value={selectedSports} defaultValue={sportsData?.[0]} className="basic-single" onChange={handleSportsChange} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}></ReactSelect>		
                                                    </Form.Group>
                                                    </td>
                                                    <td className="d-flex">
                                                        <input className="form-control me-2" type="date" onChange={e => setDate({...date, dateFrom: e.target.value})}/>
                                                        <input className="form-control" type="date" onChange={e => setDate({...date, dateTo: e.target.value})}/>
                                                    </td>
                                                    <td>
                                                        <input className="form-control" type="text" placeholder="Search" onChange={e => setSearch( e.target.value)}/>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-start">
                                                            <button
                                                            type="submit"
                                                            onClick={getData}
                                                            className="btn btn-warning btn-sm "
                                                            >
                                                            Search
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-start">
                                                            <button
                                                            type="submit"
                                                            onClick={handleCSVDownload}
                                                            className="btn btn-primary btn-sm "
                                                            >
                                                            Export
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="col-lg-12 grid-margin stretch-card">
                                    <div className="ag-theme-alpine" style={{ width: '100%' }}>
                                        <AgGridReact
                                            columnDefs={columnDefs}
                                            rowData={data}
                                            domLayout={gridOptions.domLayout}
                                            enableCellTextSelection={true}
                                            pagination={gridOptions.pagination}
                                            paginationPageSize={gridOptions.paginationPageSize}
                                            suppressCellSelection={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={isModalOpen} onHide={setIsModalOpen}>
                    <Modal.Header closeButton>
                        <Modal.Title>Declare Match Result</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form
                            onSubmit={handleSubmit}
                        >
                            <Form.Group>
                                <label htmlFor="exampleFormControlSelect3" className="col-form-label">Choose your team to declare result</label>
                                {
                                    < div key={matchMarkets?.marketId}>
                                        <p>{matchMarkets?.marketName}</p>
                                        <select className={`${selectedRunnerError != "" ? 'border border-danger' : ''} form-control`} id={`exampleFormControlSelect3`} onChange={handleRunnerSelect}>
                                            <option value=''>select runner</option>
                                            {
                                                declareList?.data?.runners && declareList?.data?.runners?.map((runner) => {
                                                    return <option key={runner?.selectionId} value={runner?.selectionId}>{runner?.runnerName}</option>

                                                })
                                            }
                                        </select>

                                    </div>
                                }
                            </Form.Group>
                            <div className="row mt-3">
                                <div className="col-6 m-0">
                                    <button type="submit" className="btn btn-warning w-100">
                                        Yes {" "}
                                        {
                                            declareList.loader
                                            &&
                                            <div className="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm" role="status"></div>
                                        }
                                    </button>
                                </div>
                                <div className="col-6 m-0">
                                    <button type="reset" className="btn btn-danger w-100" onClick={handleCleare}>Clear</button>
                                </div>
                            </div>
                            
                        </Form>
                    </Modal.Body>
                </Modal>
            </div >
        </MainTemplate>
    )
}

export default MarketSettings