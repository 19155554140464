
import './ManageSeries.css'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { fetchAllSeries, manage_details } from '../../../Api/api'
import baseUrl from '../../../Api/baseUrl'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import MainTemplate from '../../../components/MainTemplate';
import { formatDateString, gridOptions } from '../../../utils/helperFunctin'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import csvDownload from 'json-to-csv-export'
import { Link } from 'react-router-dom'
import ReactSelect from 'react-select'

function SeriesSetting() {

  const sportsData = [
    { value: "1", label: "Football" },
    { value: "2", label: "Tennis" },
    { value: "4", label: "Cricket" }
  ]

  const [selectedSports, setSelectedSports] = useState({ id: "1", name: "Football" })
  const [loader, setLoader] = useState(false)
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({sport: sportsData?.[0]})

  const getData = async () => {
    setLoader(true)
    const res = await axios.get(baseUrl + fetchAllSeries + `?`
      +`eventId=${filter?.sport?.value}`
      + `${filter?.search ? `&search=${filter?.search}&` : ""}`
      + `${filter?.status ? `&status=${filter?.status}&` : ""}`
    )
    if (res?.data?.data) {
        setData(res?.data?.data)
    }
    setLoader(false)
  }
  
  const handleClickCheckBox = async (el) => {
    try {
      const status = el?.status == false ? true : false
      const token = localStorage.getItem("token");
      const headers = { headers: { "Content-Type": "application/json", "x-access-token": token } };
      const matchId = el?.competition?.id
      const responce = await axios.put(baseUrl + manage_details + `/${status}/${matchId}`, {}, headers)
      if (responce.status == 200) {
        toast.success('Updated', { position: "top-center", autoClose: 2000 });
        getData()
      } else {
        getData()
      }
    }
    catch (error) {
      toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
      getData()
    }
  }

  const columnDefs = [
    { headerName: 'Sr.No.', valueGetter: 'node.rowIndex + 1' },
    { headerName: 'Series Id', field: 'competition.id' },
    { headerName: 'Competition Region', field: 'competitionRegion' },
    { headerName: 'Series Name', field: 'competition.name', minWidth: 300 },
    {
      headerName: 'Action',
      field: 'status',
      cellRenderer: (params) => (
        <div className='d-flex'>
          <label className="switch" checked={params.value === false ? false : true} onChange={() => handleClickCheckBox(params.data)}>
            <input className="switch-input" type="checkbox" checked={params.value === false ? false : true} />
            <span className="switch-label"></span>
            <span className="switch-handle"></span>
          </label>
          <span>{params.value === false ? 'UnChecked' : 'Checked'}</span>
        </div>
      ),
    },
  ];

  const handleCSVDownload = () => csvDownload({ 
		data: data?.map(el => ({
			id: el?._id,
			seriesId: el?.competition?.id,
			competitionRegion: el?.competitionRegion,
			seriesName: el?.competition?.name,
			status: el?.status,
		})),
		delimiter: ",", 
		filename:"Betkaro-SeriesSetting-"+formatDateString(new Date()) 
	})

  useEffect(() => {
    getData()
  }, [])

  return (
    <MainTemplate categoryName="Series Setting" categoryList={["Market Setting", "Series Setting"]} >
      {/* <div className="row">
        <div className="col-lg-12 mb-2 stretch-card mt-3 px-2">
          <button className="btn btn-warning me-2 " onClick={() => setSelectedSports()}>Football</button>
          <button className="btn btn-warning me-2 " onClick={() => setSelectedSports()}>Tennis</button>
          <button className="btn btn-warning me-2 " onClick={() => setSelectedSports()}>Cricket</button>
        </div>
        
      </div> */}

      <div className="row">
        <div className="col-3 mb-3">
          <label><small>Select Sport</small></label>
          <ReactSelect options={sportsData} defaultValue={sportsData?.[0]} className="basic-single" value={filter?.sport} onChange={e => setFilter({...filter, sport: e})} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}} />					
        </div>
        <div className="col-3 mb-1">
          <label><small>Search</small></label>
          <input className="form-control " type="text" placeholder="Search" onChange={e => setFilter({...filter, search: e.target.value})}/>
        </div>
        <div className="col-3">
          <label><small>Status</small></label>
          <select value={filter?.status} className={`form-control`} onChange={e => setFilter({...filter, status: e.target.value})}>
            <option value=''>Select Status</option>
            <option value='active'>Active</option>
            <option value='inactive'>Inactive</option>
          </select>
        </div>
        <div className="col-3 mb-1 d-flex align-items-end">
          <button type="button" className="btn btn-warning mb-3 w-50 me-1" onClick={getData}>Search</button>
          <button type="button" className="btn btn-primary mb-3 w-50" onClick={handleCSVDownload}>Export</button>
          {/* <Link to={user?.type === "affiliateAdmin" ? "/create-affiliate-users" : "/create-users"}><button type="button" className="btn btn-warning btn-sm px-3 btn-fw" >Add User</button></Link> */}
        </div>
      </div>

      <div className=" px-2">
        <h5 className="page-title ml-3">
          {selectedSports?.name}
        </h5>
      </div>

        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <AgGridReact
            columnDefs={columnDefs}
            rowData={data}
            domLayout={gridOptions.domLayout}
            enableCellTextSelection={true}
            pagination={gridOptions.pagination}
            paginationPageSize={gridOptions.paginationPageSize}
            suppressCellSelection={true}
          />
        </div>

    </MainTemplate>
  )
}

export default SeriesSetting