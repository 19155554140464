import React, { useEffect, useState,  } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import baseUrl from "../Api/baseUrl";
import { getMessages, readMessages } from "../Api/api";
import axios from "axios";

const Breadcrumb = ({ name, list, user}) => {
    // const user = JSON.parse(localStorage.getItem("user"));
    const [notification, showNotification] = useState(false);
    const [agentWalletBalance, setAgentWalletBalance] = useState("");
    const [notificationTab, setNotificationTab] = useState("Orders");
    const [apiData, setApiData] = useState([])
    const [orders, setOrders] = useState([]);
    const [inventory, setInventory] = useState([]);

    const [notificationCount, setNotificationCount] = useState("")
    const [notificationData, setNotificationData] = useState([])
    const [notificationModel, setNotificationModel] = useState()

    const [loader, setLoader] = useState(false);
      

    const customStyles = {
        content: {
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            position: "absolute",
            height: "100vh",
            background: "rgba(0,0,0,0.1)",
            outline: "none",
            border: "none",
        },
        overlay: {
            position: "absolute",
            heigth: "100vh",
            background: "rgba(0,0,0,0.6)",
            zIndex: "99999",
        },
    };

    const getMessageList = async () => {
        try {
            const headers = {
              headers: {
                "Content-Type": "application/json",
                "x-access-token": user?.token,
              },
            };
            const res = await axios.get(baseUrl + getMessages, headers);
            if (res?.status == 200) {
                setNotificationCount(res?.data?.unreadCount || "")
                setNotificationData(res?.data?.data || [])
            }
        } catch (error) {
        // setMessageList({ ...messageList, loader: false })
        setNotificationData([])
        // console.log(error)
        }
    }

    const updateMessageRead = async id => {
        try {
            const headers = { headers: { "Content-Type": "application/json", "x-access-token": user?.token } };
            const res = await axios.put(baseUrl + readMessages + `?messageId=${id}`, {},  headers);
            getMessageList()
        } catch (error) {
            console.log(error);
        }
    }

    const getWalletBalanceAgent = async id => {
        try {
            if(user?.type === "affiliateAdmin"){
                const headers = { headers: { "Content-Type": "application/json", "x-access-token": user?.token, } };
                const res = await axios.get(baseUrl + "/admin/affiliate-agent-wallet",  headers);
                setAgentWalletBalance(res?.data?.data?.walletId?.balance || "")
            } else {
                setAgentWalletBalance("")
            }
            
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getMessageList()
        getWalletBalanceAgent()
    }, [])

    return (
        <>
            <div className="row">
                <div className="col-10 p-0 mb-4 px-3">
                    <div>
                        <h5 className="mb-0 font-weight-bold">{name}</h5>
                        {list?.map((el, index) => (
                            <span className={`t-12 ${index + 1 === list?.length && "font-weight-bold"}`}>
                                {el}
                                {index + 1 !== list?.length && " > "}
                            </span>
                        ))}
                    </div>
                </div>
                <div className="col-2 d-flex justify-content-end position-relative">
                    {
                        user?.type === "affiliateAdmin" && agentWalletBalance ? <span className="me-4 pb-1">Wallet: ₹ <b>{agentWalletBalance}</b></span> : ""
                    }
                    {
                        notificationCount
                        ?
                        <span className="badge badge-pill badge-warning position-absolute">
                            {notificationCount}
                        </span>
                        :
                        ""
                    }
                    
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        onClick={() => showNotification(!notification)}
                        height="16"
                        stroke="black"
                        stroke-width=".1"
                        fill="currentColor"
                        className="font-weight-bold bi bi-bell"
                        viewBox="0 0 16 16"
                    >
                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                    </svg>
                </div>
            </div>

            <Modal
                isOpen={notification}
                onRequestClose={() => showNotification(false)}
                style={customStyles}
            >
                <div
                    className={`px-3 border-right position-absolute d-block bg-white rounded shadow-lg`}
                    style={{
                        width: "350px",
                        top: 0,
                        right: 0,
                        zIndex: "999",
                        height: "100vh",
                    }}
                >
                    <div className="my-4">
                        <div className="h4 d-flex justify-content-between align-items-center">
                            <span>Notifications</span>
                            <svg
                                onClick={() => showNotification(false)}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-x-lg"
                                viewBox="0 0 16 16"
                            >
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                            </svg>
                        </div>
                    </div>

                    <div className="notificationScroll ps-2 pe-3 mb-3">
                        <div class="accordion" id="accordionExample">
                        {    
                            notificationData?.map((el, index) => {
                                return <div class="accordion-item">
                                    <h2 class="accordion-header" id={"headingOne-"+ index} onClick={() => updateMessageRead(el?._id)}>
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne-" + index} >
                                            {el?.title} {!el?.read ? <span className=" ms-1 badge badge-warning badge-pill">New</span> : ""}
                                        </button>
                                    </h2>
                                    <div id={"collapseOne-" + index} class="accordion-collapse collapse " aria-labelledby={"headingOne-"+ index} data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            {el?.description}
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                        </div>
                    </div>

                    {/* <div className="my-4">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div className="col-6 pe-2">
                                <button
                                    className={`t-12 w-100 btn ${notificationTab === "Deposit"
                                        ? "btn-dark text-warning"
                                        : "btn-outline-dark"
                                        }`}
                                    onClick={() => setNotificationTab("Deposit")}
                                >
                                    Deposit
                                </button>
                            </div>
                            <div className="col-6 pe-2">
                                <button
                                    className={`t-12 w-100 btn ${notificationTab === "Withdrawal"
                                        ? "btn-dark text-warning"
                                        : "btn-outline-dark"
                                        }`}
                                    onClick={() => setNotificationTab("Withdrawal")}
                                >
                                    Withdrawal
                                </button>
                            </div>
                        </div>
                    </div> */}

                    {/* <hr className="my-4" /> */}

                    {/* {notificationTab === "Withdrawal" && (
                        <>
                            <div className="notificationScroll ps-2 pe-3 mb-3">
                                {loader ? (
                                    <div className="text-center mt-5">
                                        <button
                                            class="btn mt-5"
                                            style={{ backgroundColor: "#FFEDC2" }}
                                            type="button"
                                        >
                                            <span
                                                class="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>{" "}
                                            Loading...
                                        </button>
                                    </div>
                                ) : (
                                    inventory?.map((el) => (<>
                                        <div>
                                            <div className="t-12 row px-2 font-weight-bold mb-0 text-capitalize">
                                                {el?.product?.product_name} Running low on quantity
                                            </div>
                                            <div className="row px-2 t-12">
                                                <div className="col-7 p-0 mb-3 text-capitalize">Only {el?.product?.sizeList?.quantity} apples left</div>
                                                <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                                    <Link to="/addinventory" state={{ data: el, edit: true }}
                                                    >Take action</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="mb-3 mt-0 w-100" />
                                    </>)

                                    ))}
                            </div>

                            <div>
                                <u className="d-flex justify-content-end font-weight-bold t-12">
                                    <span className="cursorPointer d-inline-block">View All</span>
                                </u>
                            </div>
                        </>
                    )}
                    {notificationTab == "Deposit" && (
                        <>
                            <div className="notificationScroll ps-2 pe-3 mb-3">
                                {loader ? (
                                    <div className="text-center mt-5">
                                        <button
                                            class="btn mt-5"
                                            style={{ backgroundColor: "#FFEDC2" }}
                                            type="button"
                                        >
                                            <span
                                                class="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>{" "}
                                            Loading...
                                        </button>
                                    </div>
                                ) : (
                                    orders?.map((el) => (
                                        <>
                                            <div>
                                                <div className="t-12 row px-2 font-weight-bold mb-0">
                                                    Order no. #{el?.order?._id}
                                                </div>
                                                <div className="row px-2 t-12">
                                                    <div className="col-7 p-0 mb-0">
                                                        {el?.order?.products.length} Items
                                                    </div>
                                                    <div className="col-7 p-0 mb-3">Punjab, Prestige Heritage</div>
                                                    <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                                        {el?.order?._id && (  // Add a conditional check here
                                                            <Link className="" to={`/order-details/${el.order._id}`}>
                                                                Take Actions
                                                            </Link>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="mb-3 mt-0 w-100" />
                                        </>
                                    ))

                                )}
                            </div>

                            <div>
                                <u className="d-flex justify-content-end font-weight-bold t-12">
                                    <Link to="/orders" onClick={() => showNotification(false)} className="cursorPointer d-inline-block">View All</Link>
                                </u>
                            </div>
                        </>
                    )} */}
                </div>
            </Modal>

            <Modal
                isOpen={notificationModel?._id}
                onRequestClose={() => setNotificationModel({})}
                style={customStyles}
            >
                <div className={`px-3 border-right position-absolute d-block bg-white rounded shadow-lg`} >
                    asd
                </div>
            </Modal>
        </>
    );
};

export default Breadcrumb;
