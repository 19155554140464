import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Form } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import { getMatchSettings, get_Wallet_history } from '../../../Api/api';
import baseUrl from '../../../Api/baseUrl';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useRef } from 'react';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import MainTemplate from '../../../components/MainTemplate';
import ReactSelect from 'react-select';
import csvDownload from 'json-to-csv-export';
import { formatDateString, gridOptions } from '../../../utils/helperFunctin';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';  


function AccountStatement() {

  const user = JSON.parse(localStorage.getItem("user"))

  const [accountStatementList, setAccountStatementList] = useState({ data: [], loader: false })
  const [filter, setFilter] = useState({})
  const [matches, setMatchesData] = useState([])
  const [users, setUsers] = useState([])

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Account_Statement',
    sheet: 'Statement'
  })

  const createPDF = async () => {
    const pdf = new jsPDF("portrait", "pt");
    const data = await html2canvas(document.querySelector("#order_detail"));
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("account_Statement.pdf");
  };


  const getAccountStatementList = async () => {
    try {
      setAccountStatementList({ ...accountStatementList, loader: true })
      const headers = { headers: { "Content-Type": "application/json", "x-access-token": user?.token } };
      console.log(filter)
      const res = await axios.get(
        baseUrl + get_Wallet_history + "?"
				+ `${filter?.matchId ? `matchId=${filter?.matchId}&` : ""}`
        + `${filter?.source ? `source=${filter?.source?.value}&` : ""}`
        + `${filter?.type ? `type=${filter?.type}&` : ""}`
				+ `${filter?.user ? `userId=${filter?.user?._id}&` : ""}`
				+ `${filter?.dateFrom ? `dateFrom=${filter?.dateFrom}&` : ""}`
				+ `${filter?.dateTo ? `dateTo=${filter?.dateTo}&` : ""}`
        , headers
      )
      if (res?.status == 200) {
        setAccountStatementList({ data: res?.data?.data, loader: false })
      } else {
        setAccountStatementList({ ...accountStatementList, loader: false })
      }
    } catch (error) {
      setAccountStatementList({ ...accountStatementList, loader: false })
    }
  }

  useEffect(() => {
    getMatchData()
    getUserData()
  }, [])

  useEffect(() => {
    getAccountStatementList()
  }, [])

  const sources= [
      {value: "", label: "Select Source"},
      {value: "LayBookmaker", label: "LayBookmaker"},
      {value: "BackBookmaker", label: "BackBookmaker"},
      {value: "LayBet", label: "LayBet"},
      {value: "BackBet", label: "BackBet"},
      {value: "FancyBet", label: "FancyBet"},
      {value: "casinoComission", label: "casinoComission"},
      {value: "cancelBet", label: "cancelBet"},
      {value: "BookmakerDeclare", label: "BookmakerDeclare"},
      {value: "BookmakerComission", label: "BookmakerComission"},
      {value: "FancyDeclare", label: "FancyDeclare"},
      {value: "FancyCommission", label: "FancyCommission"},
      {value: "AgentMoney", label: "AgentMoney"},
      {value: "Betcommission", label: "Betcommission"},
      {value: "BetDeclare", label: "BetDeclare"},
  ]

  const handleCSVDownload = () => csvDownload({ 
		data: accountStatementList?.data?.[0]?.transactions?.map(el => ({
			id: el?._id,
			type: el?.type,
			// status: el?.status,
			matchName: el?.source === "FancyBet" || el?.source === "FancyDeclare"
        ?
        el?.fancy?.matchDetails?.name || "-"
        :
        el?.source === "casinoComission" 
        ? 
        "NA" 
        :
        el?.bets?.matchDetails?.name || "-"
      , 
      matchTime: el?.source === "FancyBet" || el?.source === "FancyDeclare"
        ?
        el?.fancy?.matchDetails?.openDate ? formatDateString(el?.fancy?.matchDetails?.openDate) : "-"
        :
        el?.source === "casinoComission" 
        ? 
        "NA" 
        :
        el?.bets?.matchDetails?.openDate ? formatDateString(el?.bets?.matchDetails?.openDate) : "-"
      , 
      runnerName: el?.source === "FancyBet" || el?.source === "FancyDeclare"
        ?
        el?.fancy?.RunnerName || "-"
        :
        el?.source === "casinoComission" 
        ? 
        "NA" 
        :
        "-"
      ,
			amount: el?.amount,
			balance: el?.balance,
      playerName: el?.sourceUser.name,
      playerPhone: el?.sourceUser.phone,
      playerUsername: el?.sourceUser.username,
      playerEmail: el?.sourceUser.email,
      createdAt: formatDateString(el?.timestamp),


		})),
		delimiter: ",", 
		filename:"Betkaro-AccountStatement-"+formatDateString(new Date()) 
	})

  const getMatchData = async () => {
    const res = await axios.get(baseUrl +getMatchSettings);
    if (res?.data?.data) {
      setMatchesData(res?.data?.data?.map(el => ({ ...el, value: el?.event?.id, label: el?.event?.name })))
    }
  };

  const getUserData = async () => {
		try{
			const headers = { headers: { "Content-Type": "application/json", "x-access-token": user?.token } }
			let apiUrl = ""
			if(user?.type === "affiliateAdmin"){
				apiUrl = baseUrl + "/admin/affiliate-user-list?" 				
			} else {
				apiUrl = baseUrl + "/admin/all_user_list?" 
			}
			const res = await axios.get(apiUrl, headers)
			if(res?.data?.data){
				setUsers(res?.data?.data?.map(el => ({...el, value:el?._id , label: el?.name + ", " + el?.phone + ", " + el?.email + ", " + el?.username})))
			}
		} catch(err){
		}
	}

  const columnDefs = [
    { headerName: 'Sr.No.', valueGetter: 'node.rowIndex+1', width: 80 },
    { headerName: 'Cr/Dr', field: 'type', width: 110},
    { headerName: 'Source', field: 'source', width: 150 },
    // { headerName: 'Status', field: 'status' },
    { headerName: 'Match', 
      cellRenderer: (params) => {
        const {source} = params?.data
        if(source === "FancyBet" || source === "FancyDeclare"){
          return params?.data?.fancy?.matchDetails?.name || "-"
        } else if(source === "casinoComission") {
          return "NA"
        } else {
          return params?.data?.bets?.matchDetails?.name || "-"
        }
      },
      valueGetter: (params) => {
        const {source} = params?.data
        if(source === "FancyBet" || source === "FancyDeclare"){
          return params?.data?.fancy?.matchDetails?.name || "-"
        } else if(source === "casinoComission") {
          return "NA"
        } else {
          return params?.data?.bets?.matchDetails?.name || "-"
        }
      }
    },
      { headerName: 'Match Time', cellRenderer: (params) => {
        const {source} = params?.data
        if(source === "FancyBet" || source === "FancyDeclare"){
          return params?.data?.fancy?.matchDetails?.openDate ? formatDateString(params?.data?.fancy?.matchDetails?.openDate) : "-"
        } else if(source === "casinoComission") {
          return "NA"
        } else {
          return params?.data?.bets?.matchDetails?.openDate ? formatDateString(params?.data?.bets?.matchDetails?.openDate) : "-"
        }
      },
      valueGetter: (params) => {
        const {source} = params?.data
        if(source === "FancyBet" || source === "FancyDeclare"){
          return params?.data?.fancy?.matchDetails?.openDate ? formatDateString(params?.data?.fancy?.matchDetails?.openDate) : "-"
        } else if(source === "casinoComission") {
          return "NA"
        } else {
          return params?.data?.bets?.matchDetails?.openDate ? formatDateString(params?.data?.bets?.matchDetails?.openDate) : "-"
        }
      }
    },
    { headerName: 'Runner Name', cellRenderer: (params) => {
      const {source} = params?.data
      if(source === "FancyBet" || source === "FancyDeclare"){
        return params?.data?.fancy?.RunnerName || "-"
      } else if(source === "casinoComission") {
        return "NA"
      } else {
        return "-"
      }
    },
    valueGetter: (params) => {
      const {source} = params?.data
      if(source === "FancyBet" || source === "FancyDeclare"){
        return params?.data?.fancy?.RunnerName || "-"
      } else if(source === "casinoComission") {
        return "NA"
      } else {
        return "-"
      }
    }},
    { headerName: 'Amount', field: 'amount', width: 100 },
    { headerName: 'Balance', field: 'balance', width: 100 },
    { headerName: 'Player Name', cellRenderer: params => params?.data?.sourceUser?.name || "-", valueGetter: params => params?.data?.sourceUser?.name || "-",},
    { headerName: 'Player Phone', cellRenderer: params => params?.data?.sourceUser?.phone || "-", valueGetter: params => params?.data?.sourceUser?.phone || "-",  width: 150 },
    { headerName: 'Player Username', cellRenderer: params => params?.data?.sourceUser?.username || "-", valueGetter: params => params?.data?.sourceUser?.username || "-",},
    { headerName: 'Player Email', cellRenderer: params => params?.data?.sourceUser?.email || "-", valueGetter: params => params?.data?.sourceUser?.email || "-",},
    { headerName: 'Created At', cellRenderer: params => params?.data?.timestamp ? formatDateString(params?.data?.timestamp): "-", valueGetter: params => params?.data?.timestamp ? formatDateString(params?.data?.timestamp): "-"},
    
  ];

  return (
    <MainTemplate
      categoryName="Account Statement"
      categoryList={["Reports", "Account Statement"]}
    >
    <div>
      <div className="">
        <div className="col-lg-12 stretch-card mb-2">
        <div className="d-flex justify-content-end fw-bold mb-2">{accountStatementList?.data?.[0]?.balance ? `💵Balance: ₹ ${accountStatementList?.data?.[0]?.balance}`: ""}</div>

            <div className="card border-0">
                <div className="">
                    <div className="table-responsive">
                    <table className="table mb-0 table-borderless">
                        <thead>
                          <tr>
                            <th> Search Match</th>
                            <th> Source </th>
                            <th> User search </th>
                            <th>Credit/Debit</th>
                          </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                            <input className="form-control me-1" type="string" placeholder="Match Search" onChange={e => setFilter({...filter, matchId: e.target.value})}/>				
                                {/* <ReactSelect isClearable={true}  value={filter?.match}  className="basic-single" onChange={e => setFilter({...filter, match: e})} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}></ReactSelect>		 */}
                            </td>
                            <td>
                                <ReactSelect isClearable={true} options={sources} value={filter?.source} defaultValue={sources?.[0]} className="basic-single" onChange={e => setFilter({...filter, source: e})} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}></ReactSelect>		
                            </td>
                            <td>
                                <ReactSelect isClearable={true} options={users} value={filter?.user}  className="basic-single" onChange={e => setFilter({...filter, user: e})} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}></ReactSelect>		
                            </td>
                            <td>
                                <select className='form-control' onChange={e => setFilter({...filter, type: e.target.value})}>
                                  <option value="">All</option>
                                  <option value="Cr">Credit</option>
                                  <option value="Dr">Debit</option>
                                </select>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                    <div className="table-responsive">
                    <table className="table table-borderless mb-0">
                        <thead>
                        <tr>
                            
                            <th className="row m-0"> 
                                <span className="col-6">Date From</span> 
                                <span className="col-6">Date To</span> 
                            </th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            
                            <td className="d-flex">
                                <input className="form-control form-control-sm me-2" type="date" max={new Date().toISOString().split('T')[0]} onChange={e => setFilter({...filter, dateFrom: e.target.value})}/>
                                <input className="form-control form-control-sm" type="date" max={new Date().toISOString().split('T')[0]} onChange={e => setFilter({...filter, dateTo: e.target.value})}/>
                            </td>
                            <td>
                                <button type="submit" onClick={getAccountStatementList} className="btn btn-warning btn-sm w-100" >
                                  Search
                                </button>
                            </td>
                            <td>
                                <button type="submit" onClick={handleCSVDownload} className="btn btn-primary btn-sm w-100" >
                                  Export
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card" id="order_detail">
                <div className="ag-theme-alpine" style={{ width: '100%' }}>
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={accountStatementList?.data?.[0]?.transactions}
                    domLayout={gridOptions.domLayout}
                    enableCellTextSelection={true}
                    pagination={gridOptions.pagination}
                    paginationPageSize={gridOptions.paginationPageSize}
                    suppressCellSelection={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    </MainTemplate>
  )
}

export default AccountStatement