import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addCategory } from "../redux/reducers/sidebarSlice";
import Logo from "../assets/images/betkarologo.svg";
import User from "../assets/images/profile.png";
import "../App.css";
import Modal from "react-modal";
import { ROLES, customActionModalStyles } from "../../src/utils/helperFunctin";
import { addSidebarModules } from "../redux/reducers/sidebardModuleSlice";

function Sidebar({ containerRef }) {
  const sidebarRef = useRef(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const selectedCategory = useSelector((state) => state?.sidebarReducer);
  const [sidebar, showSidebar] = useState(true);
  const [sidebarMobile, showSidebarMobile] = useState(false);
  const [actionModal, setActionModal] = useState({ loader: false, show: false, type: "", data: {} });
  const [categories, setCategories] = useState([])
  const [user, setUser] = useState({})

  useEffect(() => {
    const categories = []
    
    // admin menus
    if(user?.type === "admin"){
      // FINANCE
      if((user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.Finance))){
        categories.push({
          id: "1", name: "Finance", value: "Finance", type: "DROPDOWN", subCategory: [
            { id: "1.1", name: "Withdrawal List", link: "/withdrawal-list"},
            { id: "1.3", name: "Deposit List", link: "/deposit-list"},
          ]
        })
      }
      // Manage Users
      const subLinks = []
      if((user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.Users))){
        subLinks.push({ id: "2.1", name: "Users List", link: "/users-list"})
        subLinks.push({ id: "2.2", name: "Admins List", link: "/admins-list"})
      }
      if((user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.AffiliateProgram))){
        subLinks.push({ id: "2.3", name: "Agent List", link: "/agent-list" })
      }
      if((user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.Users) || user?.access?.includes(ROLES.AffiliateProgram))){
        categories.push({
          id: "2", name: "Manage Users", value: "Manage Users", type: "DROPDOWN", subCategory: subLinks
        })
      }
      // Profile
      if((user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.Profile))){
        categories.push({
          id: "3", name: "Profile", value: "Profile", type: "DROPDOWN", subCategory: [
            { id: "3.1", name: "Account Details", link: "/account-details" },
            { id: "3.2", name: "Change Password", link: "/change-password"},
          ]
        })
      }
      // Market Settings
      if((user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.Market))){
        categories.push({
          id: "4", name: "Market Settings", value: "Market Settings", type: "DROPDOWN", subCategory: [
            { id: "4.1", name: "Sports Setting", link: "/sports-setting" },
            { id: "4.2", name: "Series Setting", link: "/series-setting" },
            { id: "4.3", name: "Match Setting", link: "/match-settings" },
            { id: "4.4", name: "Market Setting", link: "/market-settings" },
            { id: "4.5", name: "Fancy Settings", link: "/fancy-settings" },
            { id: "4.5", name: "Bookmaker Settings", link: "/bookmaker-settings" },
            // { id: "4.6", name: "Market Rollback", link: "/market-rollback" },
          ]
        })
      }
      // Report
      if((user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.Report))){
        categories.push({ 
          id: "5", name: "Reports", value: 'Reports', type: "DROPDOWN", subCategory: [
            // { id: "5.1", name: "Bets", link: "/bets" },
            { id: "5.2", name: "Account Statement", link: "/account-statement" },
            { id: "5.3", name: "Exposure Details", link: "/exposure-details" },
            // { id: "5.4", name: "Current Bets", link: "/current-bets" },
            { id: "5.5", name: "User login History", link: "/user-login-history" },
          ]
        })
      }
      // Live Bets
      if((user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.Report))){
        categories.push({ 
          id: "6", name: "Live Bets", value: 'LiveBets', type: "DROPDOWN", subCategory: [
            { id: "6.1", name: "Exchange Bets", link: "/current-bets" },
            { id: "6.2", name: "Fancy Bets", link: "/fancy-bets" },
            { id: "6.3", name: "Bookmaker Bets", link: "/bookmaker-bets" },
          ]
        })
      }
      // Casino
      if((user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.Casino))){
        categories.push({ id: "7", name: "Casino Bets", link: "/casino-setting" })
      }
      // Notification
      if((user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.Notification))){
        categories.push({ id: "8", name: "Notification", link: "/create-message" })
      }
      // Affiliate Program
      // categories.push({ 
      //   id: "9", name: "Affiliate Program", value: 'AffiliateProgram', type: "DROPDOWN", subCategory: [
      //     { id: "9.1", name: "Agent List", link: "/agent-list" },
      //     { id: "9.1", name: "Agent Wallet History", link: "/agent-wallet-history" },
      //   ]
      // })
      // SiteSetting
      if((user?.access?.includes(ROLES.All) || user?.access?.includes(ROLES.SiteSetting))){
        categories.push({ id: "10", name: "Site Setting", link: "/site-setting" })
      }
    }
    


    // agent menus
    // Manage Users
    if(user?.type === "affiliateAdmin"){
      // FINANCE
      categories.push({
        id: "1", name: "Finance", value: "Finance", type: "DROPDOWN", subCategory: [
          { id: "1.1", name: "Withdrawal List", link: "/withdrawal-list"},
          { id: "1.2", name: "Deposit List", link: "/deposit-list"},
        ]
      })
      // Manage Users
      categories.push({
        id: "2", name: "Manage Users", value: "Manage Users", type: "DROPDOWN", subCategory: [
          { id: "2.1", name: "Users List", link: "/users-list"},
        ]
      })
      // Profile
      categories.push({
        id: "3", name: "Profile", value: "Profile", type: "DROPDOWN", subCategory: [
          { id: "3.1", name: "Account Details", link: "/account-details" },
          // { id: "4.2", name: "Change Password", link: "/change-password"},
        ]
      })
      //Report
      categories.push({ 
        id: "4", name: "Reports", value: 'Reports', type: "DROPDOWN", subCategory: [
          { id: "4.2", name: "Account Statement", link: "/account-statement" },
        ]
      })
      // Report
      categories.push({ 
        id: "5", name: "Live Bets", value: 'LiveBets', type: "DROPDOWN", subCategory: [
          { id: "5.1", name: "Exchange Bets", link: "/current-bets" },
          { id: "5.2", name: "Fancy Bets", link: "/fancy-bets" },
          { id: "5.3", name: "Bookmaker Bets", link: "/bookmaker-bets" },

        ]
      })
      // Casino
      categories.push({ id: "6", name: "Casino Bets", link: "/casino-setting" })
      
    }


    setCategories(categories)
    dispatch(addSidebarModules(categories));
  }, [user])

  useEffect(() => {
    if (sidebar) {
      sidebarRef.current.style.width = "25%";
      containerRef.current.style.width = "75%";
    } else {
      sidebarRef.current.style.width = "5%";
      containerRef.current.style.width = "95%";
    }
    dispatch(addSidebarModules(categories));
    if (categories?.length != 0) {
      dispatch(addSidebarModules(categories));
    }
  }, [sidebar]);

  const togglePage = (category, subCategory, type) => {
    dispatch(addCategory(category));
    if (type !== "subCategory") {
      navigate(category?.link);
    } else {
      navigate(subCategory?.link);
    }
  };

  useEffect(() => {
    const userObj = JSON.parse(localStorage.getItem("user"))
    setUser(userObj)
  }, [])

  return (
    <>
      {/* sidebar */}
      {/* <AdminForm></AdminForm> */}
      <div
        className={`${sidebar ? "p-4" : "p-0"} border-right d-none d-md-block`}
        ref={sidebarRef}
        id="sidebar"
        style={{ width: "25%" }}
      >
        {sidebar ? (
          <div className="">
            <div className="mb-4">
              <div className="h2">
                <svg
                  onClick={() => showSidebar(false)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>
              </div>
            </div>

            <div className="mb-3">
              <div className="sidebar_logo">
                <Link to="/">
                  <img src={Logo} className="w-100 categoryImageSidebar" />
                </Link>
              </div>
            </div>
            {/* {user.type == 'admin'
              ?
              <div className="mb-3 bg-light shadow-sm border rounded row py-3">
                <div className="col-md-3 d-flex align-items-center">
                  <img src={user && user?.image || User} className="userLogo  rounded-circle" />
                </div> 
                <div className="col-md-9 d-flex flex-row align-items-center">
                  <div className="p-1 text-wrap">
                    <div className="font-weight-bold">Admin</div>
                    <div className="t-12">{user && user?.username}</div>
                  </div>
                </div>
              </div>
              :
              <div className="mb-3 bg-light rounded row py-3">
                <div className="col-md-3 d-flex align-items-center">
                </div>
                <div className="col-md-9 d-flex flex-row align-items-center">
                  <div className="p-1 text-wrap">
                    <div className="font-weight-bold">Admin</div>
                    <div className="t-12">admin@betkaro.in</div>
                  </div>
                </div>
              </div>
            } */}

            <div className="mb-5 overflow-auto sidebarCategoryHeight pe-2">
              {/* Desk View */}
              {
                categories?.map((el) =>
                  el?.type !== "DROPDOWN" ? (
                    <div onClick={() => togglePage(el)} key={el?.id}>
                      <div
                        className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.id === el?.id &&
                          "selectedCategory font-weight-bold shadow-sm"
                          }`}
                        onClick={() => togglePage(el)}
                      >
                        {el?.name}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="position-relative"
                      data-bs-toggle="collapse"
                      data-bs-target={`#accordian-${el?.id}`}
                      key={el?.id}
                      onClick={() => togglePage(el)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill={`${selectedCategory?.id !== el?.id && "grey"}`}
                        style={{ top: "13px", right: "12px" }}
                        className="position-absolute"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                      <div
                        className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.id === el?.id &&
                          "selectedCategory font-weight-bold shadow-sm"
                          }`}
                        onClick={() => togglePage(el)}
                      >
                        {el?.name}
                      </div>
                      <div
                        id={`accordian-${el?.id}`}
                        className={`${selectedCategory?.id === el?.id && "show"
                          }  px-3 bg-light text-sidebar-dropdown py-2 rounded t-12 accordion-collapse collapse`}
                      >
                        {el?.subCategory?.map((ele) => (
                          <div
                            className={`py-1 px-3 rounded dropdownSubMenus cursorPointer`}
                            onClick={(e) => {
                              e.stopPropagation();
                              togglePage(el, ele, "subCategory");
                            }}
                          >
                            {ele?.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                )
              }
            </div>

            <div className="m-0 p-0">
              <span className="mx-2"
                // onClick={() => dispatch({ type: "USER_LOGOUT" })}
                onClick={() => setActionModal({ show: true })}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                  <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                </svg>
                <span className="mx-2 logout">Log out</span>
              </span>
            </div>
          </div>
        ) : (
          <>
            <div className="mb-4">
              <div className="h2 d-flex justify-content-center mt-4">
                <svg
                  onClick={() => showSidebar(true)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="mt-2 bi bi-arrow-right position-absolute"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                  />
                </svg>
              </div>
            </div>
          </>
        )}
      </div>
      {/* sidebar mobile nav*/}
      <div
        className={`p-0 border-right d-block d-md-none`}
        style={{ width: "5%" }}
      >
        <>
          <div className="mb-4">
            <div className="h2 d-flex justify-content-center mt-4">
              <svg
                onClick={() => showSidebarMobile(true)}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="mt-2 bi bi-arrow-right position-absolute"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
            </div>
          </div>
        </>
      </div>
      {/* sidebar mobile */}
      {sidebarMobile && (
        <div
          className={`px-4 border-right position-absolute d-block d-md-none bg-white`}
          style={{ width: "300px", zIndex: "999", height: "100vh" }}
        >
          <div className="">
            <div className="mb-4">
              <div className="h2">
                <svg
                  onClick={() => showSidebarMobile(false)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>
              </div>
            </div>

            <div className="mb-3">
              <div className="sidebar_logo">
                <Link to="/categories">
                  <img src={Logo} className="w-100 categoryImageSidebar" />
                </Link>
              </div>
            </div>

            {/* <div className="mb-3 bg-light rounded row py-3">
              <div className="col-md-9 d-flex flex-row align-items-center">
                <div className="p-1 text-wrap">
                  <div className="font-weight-bold">Admin</div>
                  <div className="t-12">admin@betkaro.in</div>
                </div>
              </div>
            </div> */}

            <div className="mb-5 overflow-auto sidebarCategoryHeight pe-2">
              {/* Mobile View */}
              {
                categories?.map((el) =>
                  el?.type !== "DROPDOWN" ? (
                    <div onClick={() => togglePage(el)} key={el?.id}>
                      <div
                        className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.id === el?.id &&
                          "selectedCategory font-weight-bold shadow-sm"
                          }`}
                        onClick={() => togglePage(el)}
                      >
                        {el?.name}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="position-relative"
                      data-bs-toggle="collapse"
                      data-bs-target={`#accordian-${el?.id}`}
                      key={el?.id}
                      onClick={() => togglePage(el)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill={`${selectedCategory?.id !== el?.id && "grey"}`}
                        style={{ top: "13px", right: "12px" }}
                        className="position-absolute"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                      <div
                        className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.id === el?.id &&
                          "selectedCategory font-weight-bold shadow-sm"
                          }`}
                        onClick={() => togglePage(el)}
                      >
                        {el?.name}
                      </div>
                      <div
                        id={`accordian-${el?.id}`}
                        className={`${selectedCategory?.id === el?.id && "show"
                          }  px-3 bg-light text-sidebar-dropdown py-2 rounded t-12 accordion-collapse collapse`}
                      >
                        {el?.subCategory?.map((ele) => (
                          <div
                            className={`py-1 px-3 rounded dropdownSubMenus cursorPointer`}
                            onClick={(e) => {
                              e.stopPropagation();
                              togglePage(el, ele, "subCategory");
                            }}
                          >
                            {ele?.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                )
              }
            </div>

            <div className="m-0 p-0 ">
              <span className="mx-2" onClick={() =>
              {
                dispatch({ type: "USER_LOGOUT"})
                showSidebarMobile(false)
                setActionModal({ show: true })
                localStorage.removeItem("token")
						    localStorage.removeItem("user")
              }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                  <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                </svg>
                <span className="mx-2">Log out</span>
              </span>
            </div>
          </div>
        </div>
      )}

      {/* Logout Modal */}
      <Modal
        isOpen={actionModal?.show}
        onRequestClose={() => setActionModal({})}
        style={customActionModalStyles}
        contentLabel="Action Modal"
        ariaHideApp={false}
      >
        <div className="px-3 py-3 modal_width">
          <div className="row">
            <span className="d-flex justify-content-end cursorPointer">
              <svg
                onClick={() => setActionModal({})}
                className="d-inline"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </div>
          <div className="row">
            <h4 className="text-danger d-flex justify-content-center">
              Do you want to Logout ?
            </h4>
          </div>
          <div className="row my-5">
            <h5 className="d-flex justify-content-center">
              {actionModal?.data?.name}
            </h5>
          </div>
          <div className="form-group my-3 d-flex justify-content-center">
            <button
              disabled={actionModal?.loader}
              className="btn btn-dark me-3 mb-2 text-warning px-5"
              onClick={() => {localStorage.clear(); dispatch({ type: "USER_LOGOUT" })}}
            >
              {actionModal?.loader ? (
                <div class="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm" role="status">
                  <span class="sr-only"></span>
                </div>
              ) : (
                "Yes"
              )}
            </button>
            <button
              disabled={actionModal?.loader}
              className="btn btn-light me-3 mb-2 shadow-sm text-dark border px-5"
              onClick={() => setActionModal({})}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Sidebar;
