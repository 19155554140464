import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { get_Online_User } from '../../../Api/api';
import baseUrl from '../../../Api/baseUrl';
import MainTemplate from '../../../components/MainTemplate';
import { formatDateString, gridOptions } from '../../../utils/helperFunctin';
import { AgGridReact } from 'ag-grid-react';
import ReactSelect from 'react-select';
import csvDownload from 'json-to-csv-export';

function UserLoginHistory() {
  const user = JSON.parse(localStorage.getItem("user"))	

  const [usersList, setUsersList] = useState({ data: [], loader: false })
  const [users, setUsers] = useState([])
  const [filter, setFilter] = useState({})

  const getUserList = async () => {
    try {
      setUsersList({ ...usersList, loader: true })
      const token = localStorage.getItem("token");
      const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, }, };
      const res = await axios.get(baseUrl + get_Online_User + `?${filter?.user ? `userId=${filter?.user?._id}&` : ""}`, headers)
      if (res?.status == 200) {
        setUsersList({ data: res?.data?.data, loader: false })
      } else {
        setUsersList({ ...usersList, loader: false })
      }
    } catch (error) {
      setUsersList({ ...usersList, loader: false })
    }
  }

  const getUsersData = async () => {
		try{
      const token = localStorage.getItem("token");
			const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
			const res = await axios.get(baseUrl + "/admin/all_user_list", headers)
			if(res?.data?.data){
				setUsers(res?.data?.data?.reverse()?.map(el => ({...el, value:el?._id, label: el?.name+", "+el?.email+", "+el?.phone})))
			}
		} catch(err){
			console.log(err)
		}
	}

  const handleCSVDownload = () => csvDownload({ 
		data: usersList?.data?.map(el => ({
      Name: el?.name,
      Username: el?.username,
      LoginTime: formatDateString(el?.lastLogin),
      IpAddress: el?.ipAddress,
      BrowserInfo: el?.browser,
      Location: el?.location || "-",
		})),
		delimiter: ",", 
		filename:"Betkaro-loginHistory-"+formatDateString(new Date()) 
	})

  useEffect(() => {
    getUserList()
  }, [])

  useEffect(() => {
    getUsersData()
  }, [])

  const columnDefs = [
    { headerName: 'Sr.No.', valueGetter: 'node.rowIndex + 1', width: 80 },
    { headerName: 'Name', field: 'name' },
    { headerName: 'Username', field: 'username' },
    { headerName: 'Login Time', cellRenderer: (params) =>  formatDateString(params?.data?.lastLogin) },
    { headerName: 'Ip Address', field: 'ipAddress' },
    { headerName: 'Browser Info', field: 'browser' },
    // { headerName: 'Location', field: 'location' },
  ];


  return (
    <MainTemplate
      categoryName="User Login History"
      categoryList={["Reports", "User Login History"]}
    >
    <div>
      <div className="">

        <div className="row ml-1 mb-2">
          <div className="col-5 mb-1">
            <label><small>Select User</small></label>
            <ReactSelect className="basic-single" isClearable={true} options={users} value={filter?.user} onChange={e => setFilter({...filter, user: e})} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}} />
          </div>
          <div className="col-md-7 mb-1 d-flex align-items-end justify-content-end">
            <button type="button" className="btn btn-warning btn-sm px-5 mb-1 me-2" onClick={getUserList}>Search</button>
            <button type="button" className="btn btn-primary btn-sm px-5 mb-1 me-2" onClick={handleCSVDownload}>Export</button>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="ag-theme-alpine" style={{ width: '100%' }}>
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={usersList?.data}
                  domLayout={gridOptions.domLayout}
                  enableCellTextSelection={true}
                  pagination={gridOptions.pagination}
                  paginationPageSize={gridOptions.paginationPageSize}
                  suppressCellSelection={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </MainTemplate>
  )
}

export default UserLoginHistory