import React from 'react'
import { useState } from 'react'
import { Form } from 'react-bootstrap';
import MainTemplate from '../../components/MainTemplate';

const Bets = () => {

    const [loader, setLoader] = useState(false)
    let index = 0
    const [data, setData] = useState([])

    return (
        <MainTemplate
            categoryName="Bets"
            categoryList={["Reports", "Bets"]}
        >
        <div>
            <div>
                <div className="row">
                    <div className="me-0 col-lg-6">
                        <div className="table-responsive mb-5">
                            <table className="border-none bg-white table table-borderless">
                                <thead>
                                    <tr>
                                        <th colspan="3"> Maximum Bet </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="d-flex" colspan="3">
                                            <Form.Control type="number"  defaultValue="0"  />
                                            <button type="button" className="btn btn-warning btn-sm  btn-fw">Active</button>
                                            <button type="button" className="btn btn-warning btn-sm  btn-fw">Submit</button>
                                        </td>                           
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="me-0 col-lg-6">
                        <div className="table-responsive mb-5">
                            <table className="bg-white table table-bordered table-borderless">
                                <thead>
                                    <tr>
                                        <th colspan="4">Menus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="d-flex" colspan="4">
                                            <button type="button" className="px-4 btn btn-warning btn-sm ">Bet Lock</button>
                                            <button type="button" className="px-4 btn btn-warning btn-sm ">Fancy Lock</button>
                                            <button type="button" className="px-4 btn btn-warning btn-sm ">User Book</button>
                                            <button type="button" className="px-4 btn btn-warning btn-sm ">Mrkers Book</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                

                

                <div className="row">
                    <div className="col-lg-9 pe-2">

                        <table className="bg-white table table-bordered mb-5 shadow-sm">
                            <thead>
                                <tr className="bg-primary">
                                    <th colspan="8"> {"KAZAKHSTAN V SLOVENIA > MATCH ODDS > 2023-03-23 20:30:00"} </th>
                                    <th colspan="2"> Maximum Bets 2500000 </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="6"></td>
                                    <td className="bg-info font-weight-bold">Back</td>
                                    <td className="bg-danger font-weight-bold">Lay</td>
                                    <td colspan="2"></td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <div  className="font-weight-bold">KAZAKHSTAN</div>
                                        <div>0.00</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <div  className="font-weight-bold">Slovemnia</div>
                                        <div>0.00</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <div  className="font-weight-bold">The Draw</div>
                                        <div>0.00</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="bg-white table table-bordered mb-5 shadow-sm">
                            <thead>
                                <tr className="bg-primary">
                                    <th colspan="8"> {"KAZAKHSTAN V SLOVENIA > OVER/UNDER 1.5 Goals > 2023-03-23 20:30:00"} </th>
                                    <th colspan="2"> Maximum Bets 2500000 </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="6"></td>
                                    <td className="bg-info font-weight-bold">Back</td>
                                    <td className="bg-danger font-weight-bold">Lay</td>
                                    <td colspan="2"></td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <div  className="font-weight-bold">KAZAKHSTAN</div>
                                        <div>0.00</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <div  className="font-weight-bold">Slovemnia</div>
                                        <div>0.00</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="bg-white table table-bordered mb-5 shadow-sm">
                            <thead>
                                <tr className="bg-primary">
                                    <th colspan="8"> {"KAZAKHSTAN V SLOVENIA > OVER/UNDER 2.5 Goals > 2023-03-23 20:30:00"} </th>
                                    <th colspan="2"> Maximum Bets 2500000 </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="6"></td>
                                    <td className="bg-info font-weight-bold">Back</td>
                                    <td className="bg-danger font-weight-bold">Lay</td>
                                    <td colspan="2"></td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <div  className="font-weight-bold">KAZAKHSTAN</div>
                                        <div>0.00</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <div  className="font-weight-bold">Slovemnia</div>
                                        <div>0.00</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-info">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                    <td className="bg-danger">
                                        <div className="font-weight-bold">{Math.ceil(Math.random()*10)}</div>
                                        <div>{parseFloat(Math.random()*1000).toFixed(2)}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-lg-3 ">
                        <div className="mb-4">
                            <div className="bg-primary p-3">
                                Live Match
                            </div>
                            <div className="bg-white px-3" style={{height:"200px"}}>
                                
                            </div>
                        </div>

                        <div className="mb-4">
                            <div className="bg-primary p-3">
                                Score Card
                            </div>
                            <div className="bg-white px-3" style={{height:"200px"}}>
                                
                            </div>
                        </div>

                        <div className="mb-4">
                            <div className="bg-primary p-3">
                                Live Match
                            </div>
                            <div className="bg-white px-3" style={{height:"200px"}}>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        </MainTemplate>
    )
}

export default Bets