import React from "react";
import { Form, Spinner } from "react-bootstrap";
import baseUrl from "../../Api/baseUrl";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import csvDownload from "json-to-csv-export";
import Modal from 'react-modal';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainTemplate from "../../components/MainTemplate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { formatDateString, gridOptions } from "../../utils/helperFunctin";
import ReactSelect from "react-select";


const AgentWalletHistory = () => {

	const token = localStorage.getItem("token");
	const { state: agent } = useLocation();

	const [transactions, setTransactions] = useState([]);
	const [filter, setFilter] = useState({agent: agent?agent:""})
	const [agents, setAgents] = useState([])

	const columnDefs = [
		{ headerName: 'Sr.No.', field: 'srNo', width: 80, valueGetter: 'node.rowIndex + 1', sortable: true },
		{ headerName: 'Type', field: 'type',  width: 120, 	 sortable: true },
		{ headerName: 'Amount', field: 'amount',  width: 120, sortable: true },
		{ 
			headerName: 'Rate %', 
			width: 120, 
			cellRenderer: (params) => params?.data?.collectedAmount || params?.data?.commission ? (params?.data?.collectedAmount || params?.data?.commission + "%") : "",  
			valueGetter: (params) => params?.data?.collectedAmount || params?.data?.commission ? (params?.data?.collectedAmount || params?.data?.commission + "%") : "",  
			sortable: true 
		},
		{ 
			headerName: 'Cash Involved ( ₹ )',
			width: 150,  
			cellRenderer: (params) => (
				params?.data?.amount && (params?.data?.collectedAmount || params?.data?.commission)
				? 
				Number(parseFloat(params?.data?.amount * (params?.data?.collectedAmount || params?.data?.commission) /100)?.toFixed(2))
				: 
				""
			), 
			valueGetter: (params) => (
				params?.data?.amount && params?.data?.collectedAmount
				? 
				 Number(parseFloat(params?.data?.amount * params?.data?.collectedAmount /100)?.toFixed(2))
				: 
				""
			),  
			sortable: true 
		}, // collectedAmount key
		{ headerName: 'Balance', field: 'balance',  width: 150, sortable: true },
		{ headerName: 'Admin Name', cellRenderer: params => params?.data?.adminDetails?.username || "-", valueGetter: params => params?.data?.adminDetails?.username || "-" },
		{ headerName: 'Admin Phone', cellRenderer: params => params?.data?.adminDetails?.phone || "-", valueGetter: params => params?.data?.adminDetails?.phone || "-" },
		{ headerName: 'Player Username', cellRenderer: params => params?.data?.userDetails?.username || "-", valueGetter: params => params?.data?.userDetails?.username || "-" },
		{ headerName: 'Player Name',cellRenderer: params => params?.data?.userDetails?.name || "-", valueGetter: params => params?.data?.userDetails?.name || "-"  },
		{ headerName: 'Player Phone', cellRenderer: params => params?.data?.userDetails?.phone || "-", valueGetter: params => params?.data?.userDetails?.phone || "-"} ,
		{ headerName: 'Source', field: 'source',  sortable: true },
		{
			headerName: 'Created At', 
			field: 'timestamp', 
			valueGetter: (params) => params.data?.timestamp ? formatDateString(params.data?.timestamp) : "",
			cellRenderer: (params) => formatDateString(params?.data?.timestamp)
		}
	]
	  
	const getData = async () => {
		try{
			const headers = { 
				headers: { "Content-Type": "application/json", 
				"x-access-token": token,
			}}
			const res = await axios.get(
				baseUrl + "/admin/affiliate-agent-wallet-history?" 
				+ `${filter?.agent?._id ? `agentId=${filter?.agent?._id}&` : ""}`
				+ `${filter?.dateFrom ? `dateFrom=${filter?.dateFrom}&` : ""}`
				+ `${filter?.dateTo ? `dateTo=${filter?.dateTo}&` : ""}`
				+ `${filter?.minAmount ? `minAmount=${filter?.minAmount}&` : ""}`
				+ `${filter?.maxAmount ? `maxAmount=${filter?.maxAmount}&` : ""}`
				, headers
			)
			if(res?.data?.data){
				setTransactions(res?.data?.data?.[0])
			}
		} catch(err){
			console.log(err)
		}
	}

    const getAgentsData = async () => {
		try{
			const headers = { 
				headers: { "Content-Type": "application/json", 
				"x-access-token": token,
			}}
			const res = await axios.get(baseUrl + "/admin/affiliate-agent-list?" , headers)
			if(res?.data?.data){
				setAgents(res?.data?.data?.map(el => ({...el, value: el?._id, label: el?.name+", "+el?.email+", "+el?.phone})))
			}
		} catch(err){
			console.log(err)
		}
	}

	const handleCSVDownload = () => filter?.agent?._id
	?
	csvDownload({ 
		data: transactions?.transactions?.map(el => ({
			username: filter?.agent?.username,
			name: filter?.agent?.name,
			phone: filter?.agent?.phone,
			email: filter?.agent?.email,
			userId: filter?.agent?._id,
			type: el?.type,
			amount: el?.amount,
			"rate_%": el?.collectedAmount ? el?.collectedAmount + "%" : "-",
			cashInvolved: el?.amount && el?.collectedAmount
				? 
				Number(parseFloat(el?.amount * el?.collectedAmount /100)?.toFixed(2))
				: 
				"",
			balance: el?.balance,
			adminName: el?.adminDetails?.username,
			adminPhone: el?.adminDetails?.phone,
			playerUsername: el?.userDetails?.username,
			playerName: el?.userDetails?.name,
			playerPhone: el?.userDetails?.phone,
			source: el?.source,
			createdAt: formatDateString(el?.timestamp)
		})),
		delimiter: ",", 
		filename:`Betkaro-Agent-${filter?.agent?.username}-${filter?.agent?.phone}-${filter?.agent?.email}-`+formatDateString(new Date())
	})
	:
	""

	useEffect(() => {
		getData()
	}, [filter])

    useEffect(() => {
        getAgentsData()
    }, [])

	return (
		<MainTemplate
			categoryName="Agent Wallet History"
			categoryList={["Manage Users", "Agent Wallet History"]}
		>
			<div>
				<div className="">
					<div className="row ml-1 mb-2">
						<div className="col-3 mb-1">
							<label><small>Search Agent</small></label>
							<ReactSelect className="basic-single w-100" isClearable={true} options={agents} value={filter?.agent} onChange={e => setFilter({...filter, agent: e})}/>
						</div>
						<div className="col-md-3">
							<label><small>Wallet Balance</small></label>
							<div className="d-flex">
								<input className="form-control form-control-sm me-1" type="number" placeholder="Min Balance" onChange={e => setFilter({...filter, minAmount: e.target.value})}/>									
								<input className="form-control form-control-sm" type="number" placeholder="Max Balance" onChange={e => setFilter({...filter, maxAmount: e.target.value})}/>									
							</div>
						</div>
						<div className="col-md-3">
							<div className="row">
								<label className="col-6"><small>Date From</small></label>
								<label className="col-6"><small>Date To</small></label>
							</div>
							<div className="d-flex">
								<input className="form-control form-control-sm" type="date" max={new Date().toISOString().split('T')[0]} onChange={e => setFilter({...filter, dateFrom: e.target.value})}/>
								<input className="form-control form-control-sm" type="date" max={new Date().toISOString().split('T')[0]} onChange={e => setFilter({...filter, dateTo: e.target.value})}/>
							</div>
						</div>
						<div className="col-md-3 d-flex align-items-end justify-content-end pb-2">
							<button type="button" className="btn btn-warning btn-sm w-50 me-2" onClick={handleCSVDownload}>Export</button>
						</div>
						
						
					</div>

					<div className="row">
						<div className="col-lg-12">
							<div className="col-lg-12 grid-margin stretch-card">
								<div className="card">
									<div className="ag-theme-alpine affiliateAgent" style={{ width: '100%' }}>
										<AgGridReact
											columnDefs={columnDefs}
											rowData={transactions?.transactions || []}
											rowClassRules={ {'locked': params => params?.data?.status === "LOCKED"}}
											domLayout={gridOptions.domLayout}
											enableCellTextSelection={true}
											pagination={gridOptions.pagination}
											paginationPageSize={gridOptions.paginationPageSize}
											suppressCellSelection={true}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ToastContainer />
			</div>
		</MainTemplate>
	);
};

export default AgentWalletHistory;
