import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { bankDetails, getBankDetails } from "../../../Api/api";
import baseUrl from "../../../Api/baseUrl";
import "../AccountDetails/AccountDetails.css";
import MainTemplate from "../../../components/MainTemplate";
import { isValid_Bank_Acc_Number, isValid_UPI_ID, isValidIFSC } from "../../../utils/helperFunctin";

function AccountDetails() {
  const [accountDetails, setAccountDeatils] = useState({
    account_number: "",
    bank_name: "",
    ifsc: "",
    account_holder: "",
    upi: "",
    cryptoId: "",
    // upi_number: "",
    // gpay_number: '',
    transaction_password: "12345",
    qr_image: 12,
    googlepay_qr: 12,
    phonepay_qr: 12,
    loader: false,
  });
  const [accountDetailsError, setAccountDeatilsError] = useState({
    account_number: "",
    bank_name: "",
    ifsc: "",
    account_holder: "",
    upi: "",
    cryptoId: "",
    // upi_number: "",
    // gpay_number: '',
    transaction_password: "",
    qr_image: "",
    // googlepay_qr: "",

    // phonepay_qr: "",
  });
  const [data, setData] = useState([]);

  const handleChange = (event) => {
    if (
      event.target.name == "qr_image"
      //   event.target.name == "googlepay_qr" ||
      //   event.target.name == "phonepay_qr"
    ) {
      setAccountDeatils({
        ...accountDetails,
        [event.target.name]: event.target.files[0],
      });
      setAccountDeatilsError({
        ...accountDetailsError,
        [event.target.name]: null,
      });
    } else {
      setAccountDeatils({
        ...accountDetails,
        [event.target.name]: event?.target?.value,
      });
      setAccountDeatilsError({
        ...accountDetailsError,
        [event.target.name]: null,
      });
    }
  };

  const handleValidation = () => {
    const {
      account_number,
      bank_name,
      ifsc,
      account_holder,
      upi,
      //   upi_number,
      //   gpay_number,
      transaction_password,
      cryptoId,
      qr_image,
      //   googlepay_qr,
      //   phonepay_qr,
    } = accountDetails;
    // var remailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const newErrors = {};
    if (!account_number) {
      newErrors.account_number = "Please enter account number";
    } else if(!isValid_Bank_Acc_Number(account_number)){
      newErrors.account_number = "Invalid Account Number";
    }

    if (!bank_name) {
      newErrors.bank_name = "Please enter bank name";
    }
    if (!ifsc) {
      newErrors.ifsc = "Please enter ifsc code";
    } else if(!isValidIFSC(ifsc)){
      newErrors.ifsc = "Invalid IFSC";
    }

    if (!cryptoId) {
      newErrors.cryptoId = "Please enter crypto ID";
    }
    if (!account_holder) {
      newErrors.account_holder = "Please enter account holder";
    }
    if (!upi) {
      newErrors.upi = "Please enter upi id";
    } else if(!isValid_UPI_ID(upi)){
      newErrors.upi = "Invalid UPI";
    }
    // if (!upi_number) {
    //   newErrors.upi_number = "please enter UPI number";
    // }
    // if (!gpay_number) {
    //     newErrors.gpay_number = "please enter phone pay number";
    // }
    if (!transaction_password) {
      newErrors.transaction_password = "Please enter transaction password";
    }
    if (!qr_image || typeof qr_image == "number") {
      newErrors.qr_image = "Please select file";
    }
    // if (!googlepay_qr || typeof googlepay_qr == "number") {
    //   newErrors.googlepay_qr = "please select file";
    // }
    // if (!phonepay_qr || typeof phonepay_qr == "number") {
    //   newErrors.phonepay_qr = "please select file";
    // }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const handleValidationObject = handleValidation();
    if (Object.keys(handleValidationObject).length > 0) {
      setAccountDeatilsError(handleValidationObject);
    } else {
      setAccountDeatils({ ...accountDetails, loader: true });
      const formData = new FormData();
      formData.append("account_number", accountDetails.account_number);
      formData.append("bank_name", accountDetails.bank_name);
      formData.append("ifsc", accountDetails.ifsc);
      formData.append("account_holder", accountDetails.account_holder);
      formData.append("upi", accountDetails.upi);
      formData.append("cryptoId", accountDetails.cryptoId);
      //   formData.append("upi_number", accountDetails.upi_number);
      //   formData.append("gpay_number", accountDetails.gpay_number);
      formData.append(
        "transaction_password",
        accountDetails.transaction_password
      );
      formData.append("qr_image", accountDetails.qr_image);
      // formData.append('googlepay_qr', accountDetails.googlepay_qr)
      // formData.append('phonepay_qr', accountDetails.phonepay_qr)
      const Token = localStorage.getItem("token");
      const headers = {
        headers: {
          // "Content-Type": "application/json",
          "x-access-token": Token,
        },
      };
      const url = baseUrl + bankDetails;
      const res = await axios.put(url, formData, headers);
      if (res?.status == 200) {
        toast.success("sucess", { position: "top-center", autoClose: 2000 });
        handlegetAccountDetails();
      } else {
        toast.error("sucess", {
          position: "top-center",
          autoClose: 2000,
        });
        handlegetAccountDetails();
      }
    }
  };

  const handlegetAccountDetails = async () => {
    const ur = baseUrl + getBankDetails;
    const Token = localStorage.getItem("token");
      const headers = {
        headers: {
          // "Content-Type": "application/json",
          "x-access-token": Token,
        },
      };
    const res = await axios.get(ur,headers);
    if (res?.status == 200) {
      
      setAccountDeatils({
        ...accountDetails,
        account_number: res?.data?.data[0].account_number,
        bank_name: res?.data?.data[0].bank_name,
        ifsc: res?.data?.data[0].ifsc,
        account_holder: res?.data?.data[0].account_holder,
        upi: res?.data?.data[0].upi,
        cryptoId: res?.data?.data[0].cryptoId,
        // upi_number: res?.data?.data[0].upi_number,

        // gpay_number: res?.data?.data[0].gpay_number,
        // transaction_password: res?.data?.data[0].transaction_password,
        transaction_password: "12345",
        qr_image: res?.data?.data[0]?.qr_image,
        // googlepay_qr: res?.data?.data[0]?.googlepay_qr,
        // phonepay_qr: res?.data?.data[0]?.phonepay_qr,
        loader: false,
      });
    } else {
    }
  };
  useEffect(() => {
    handlegetAccountDetails();
  }, []);

  return (
    <MainTemplate
      categoryName="Account Detail"
      categoryList={["Profile", "Account Detail"]}
    >
    <div>
      <ToastContainer />
      <div className="page-header">
        <h3 className="page-title">Account Details</h3>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card p-4">
              <div>
                <form className="forms-sample" onSubmit={handleSubmit}>
                  <div className="d-flex">
                    <Form.Group className="col-4 px-2 mb-2">
                      <label htmlFor="exampleInputUsername1">
                        Account Number
                      </label>
                      <Form.Control
                        type="number"
                        autoComplete="off"
                        value={accountDetails.account_number}
                        name="account_number"
                        onChange={handleChange}
                        className={`form-control form-control-sm ${
                          accountDetailsError.account_number
                            ? "border border-danger"
                            : ""
                        }`}
                        maxLength={18}
                        onInput={(e) => {
                          if (e.target.value > e.target.maxLength) {
                            e.target.value = e.target.value.slice(0, e.target.maxLength)
                          }
                        }}
                        id="exampleInputUsername1"
                        placeholder="Account Number"
                      />
                      <span className="text-danger">
                        {accountDetailsError.account_number}
                      </span>
                    </Form.Group>
                    <Form.Group className="col-4 px-2 mb-2">
                      <label htmlFor="exampleInputEmail1">Bank Name</label>
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        name="bank_name"
                        value={accountDetails.bank_name}
                        onChange={handleChange}
                        className={`form-control form-control-sm ${
                          accountDetailsError.bank_name
                            ? "border border-danger"
                            : ""
                        }`}
                        id="exampleInputEmail1"
                        placeholder="Bank Name"
                      />
                      <span className="text-danger">
                        {accountDetailsError.bank_name}
                      </span>
                    </Form.Group>
                    <Form.Group className="col-4 px-2 mb-2">
                      <label htmlFor="exampleInputPassword1">IFSC</label>
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        name="ifsc"
                        value={accountDetails.ifsc}
                        onChange={handleChange}
                        maxLength={11}
                        onInput={(e) => {
                          if (e.target.value > e.target.maxLength) {
                            e.target.value = e.target.value.slice(0, e.target.maxLength)
                          }
                        }}
                        className={
                          "col-4" +
                          `form-control form-control-sm ${
                            accountDetailsError.ifsc
                              ? "border border-danger"
                              : ""
                          }`
                        }
                        id="exampleInputPassword1"
                        placeholder="IFSC"
                      />
                      <span className="text-danger">
                        {accountDetailsError.ifsc}
                      </span>
                    </Form.Group>
                  </div>
                  <div className="d-flex">
                    <Form.Group className="col-6 px-2 mb-2">
                      <label htmlFor="exampleInputUsername1">
                        Account Holder Name
                      </label>
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        name="account_holder"
                        value={accountDetails.account_holder}
                        onChange={handleChange}
                        className={`form-control form-control-sm${
                          accountDetailsError.account_holder
                            ? "border border-danger"
                            : ""
                        }`}
                        id="exampleInputUsername1"
                        placeholder="Account Holder Name"
                      />
                      <span className="text-danger">
                        {accountDetailsError.account_holder}
                      </span>
                    </Form.Group>
                    <Form.Group className="col-6 px-2 mb-2">
                      <label htmlFor="exampleInputEmail1">UPI </label>
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        name="upi"
                        value={accountDetails.upi}
                        onChange={handleChange}
                        className={`form-control form-control-sm${
                          accountDetailsError.upi ? "border border-danger" : ""
                        }`}
                        id="exampleInputEmail1"
                        placeholder="UPI"
                      />
                      <span className="text-danger">
                        {accountDetailsError.upi}
                      </span>
                    </Form.Group>
                  </div>
                  <div className="d-flex">
                  <Form.Group className="col-6 px-2 mb-2">
                      <label htmlFor="exampleInputUsername1">
                        Crypto ID
                      </label>
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        name="cryptoId"
                        value={accountDetails.cryptoId}
                        onChange={handleChange}
                        className={`form-control form-control-sm${
                          accountDetailsError.cryptoId
                            ? "border border-danger"
                            : ""
                        }`}
                        id="exampleInputUsername1"
                        placeholder="Crypto ID"
                      />
                      <span className="text-danger">
                        {accountDetailsError.cryptoId}
                      </span>
                    </Form.Group> 
                  </div>
                  <div className="d-flex">
                  </div>
                  <div className="d-flex">
                    <Form.Group className="col-4 px-2 mb-2">
                      <label htmlFor="exampleInputEmail1">UPI QR</label>
                      <Form.Control
                        type="file"
                        name="qr_image"
                        autoComplete="off"
                        // value={accountDetails.qr_image}
                        onChange={handleChange}
                        className={`form-control form-control-sm ${
                          accountDetailsError.qr_image
                            ? "border border-danger"
                            : ""
                        }`}
                        placeholder="UPI QR"
                      />
                      <span className="text-danger">
                        {accountDetailsError.qr_image}
                      </span>
                    </Form.Group>
                  </div>
                  <div className="d-flex">
                    <div className="col-4">
                      {typeof accountDetails.qr_image == "object" && (
                        <div className="image">
                          <img
                            src={URL.createObjectURL(accountDetails?.qr_image)}
                            className="rounded shadow image g-0"
                            style={{
                              width: "100%",
                              height: "200px",
                              objectFit: "cover",
                            }}
                          />
                          <div
                            className="image-containerr"
                            onClick={() =>
                              setAccountDeatils({
                                ...accountDetails,
                                qr_image: 12,
                              })
                            }
                          >
                            {" "}
                          </div>
                        </div>
                      )}
                      {typeof accountDetails.qr_image == "string" && (
                        <div className="image">
                          <img
                            src={
                              accountDetails?.qr_image &&
                              accountDetails?.qr_image
                            }
                            className="rounded shadow image g-0"
                            style={{
                              width: "100%",
                              height: "200px",
                              objectFit: "cover",
                            }}
                          />
                          <div
                            className="image-containerr"
                            onClick={() =>
                              setAccountDeatils({
                                ...accountDetails,
                                qr_image: 12,
                              })
                            }
                          >
                            {" "}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="col-4 px-2">
                      <button
                        type="submit"
                        className="btn btn-warning btn-sm  mr-2 mt-3"
                      >
                        Submit{" "}
                        {accountDetails.loader && (
                          <div
                            class="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm"
                            role="status"
                          ></div>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </MainTemplate>
  );
}

export default AccountDetails;
