import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import axios from 'axios'
import baseUrl from '../../Api/baseUrl'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { validateEmail, validatePassword } from '../../utils/helperFunctin'
import MainTemplate from '../../components/MainTemplate'

function CreateUser() {
    const navigate = useNavigate()
    const location = useLocation()

    const [userData, setUserData] = useState({})
    const [formErrors, setFormErrors] = useState({})
    const [loader, setLoader] =  useState(false)

    const handleValidation = () => {
        let error = {}
        if(!(userData?.name)){
            error.name = "Name Required"
        }
        if(!(!isNaN(userData?.phone) && (String(userData?.phone)?.length === 10))){
            error.phone = "Invalid Phone Number"
        }
        if(!validateEmail(userData?.email)){
            error.email = "Invalid Email"
        }
        if(!validatePassword(userData?.password)){
            error.password = "Password must contain at least 8 character, 1 Uppercase, 1 lowercase, 1 number and 1 special character"
        }

        return error
    }

    const handleEditValidation = () => {
        let error = {}
        if(!(userData?.name)){
            error.name = "Name Required"
        }
        if(!(!isNaN(userData?.phone) && (String(userData?.phone)?.length === 10))){
            error.phone = "Invalid Phone Number"
        }
        if(!validateEmail(userData?.email)){
            error.email = "Invalid Email"
        }
        if(userData?.password && !validatePassword(userData?.password)){
            error.password = "Password must contain at least 8 character, 1 Uppercase, 1 lowercase, 1 number and 1 special character"
        }

        return error
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const handleValidationObject = userData?._id ? handleEditValidation() : handleValidation();
        console.log(handleValidationObject, 34344343)
        if (Object.keys(handleValidationObject).length > 0) {
            setFormErrors(handleValidationObject);
        } else {
            setFormErrors({})
            try {
                setLoader(true)
                const Token = localStorage.getItem('token')
                const headers = {
                    headers: {
                        // "Content-Type": "application/json",
                        "x-access-token": Token
                    }
                }
                let res
                if(userData?.id){
                    res = await axios.put(baseUrl + "/admin/agent/updatestatus-user", userData, headers)
                } else {
                    res = await axios.post(baseUrl + '/user/sign-up', userData, headers)
                }

                
                
                if (res?.status == 200) {
                    if(userData?.id){
                        toast.success('User updated Successfully', { position: "top-center", autoClose: 2000 });
                    } else {
                        toast.success('User created Successfully', { position: "top-center", autoClose: 2000 });
                    }
                    
                    navigate("/users-list")
                }
                setLoader(false)
            } catch (error) {
                setLoader(false)
                toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
            }
        }
    }

    const handleChange = e => setUserData({
        ...userData,
        [e.target.name]: e.target.value
    })

    useEffect(() => {
        if(location?.state?._id){
            const {_id, name, phone, email, comission} = location?.state
            setUserData({id: _id, name, phone, email, comission})
        }
    }, [location])

    return (
        <MainTemplate
            categoryName="Reports"
            categoryList={["Reports", "Create User"]}
        >
        <div>
            <ToastContainer />
            <div className="row">
                <div className="col-lg-12">
                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="card p-4">
                            <form className="forms-sample" onSubmit={handleSubmit}>
                                <Form.Group className="col-6">
                                    <label htmlFor="exampleInputUsername1">Name</label>
                                    <Form.Control name='name' value={userData?.name} className={`form-control${formErrors.name ? "border border-danger" : ""}`} type="text" placeholder="Full Name" onChange={handleChange} />
                                    <span className='text-danger'>{formErrors.name}</span>
                                </Form.Group>
                                <Form.Group className="col-6">
                                    <label htmlFor="exampleInputEmail1">Phone Number</label>
                                    <Form.Control name='phone' maxLength={10} onInput={(e) => {if (e.target.value > e.target.maxLength) {e.target.value = e.target.value.slice(0, e.target.maxLength)} }} value={userData?.phone} type="number" className={`form-control${formErrors.phone ? "border border-danger" : ""}`} id="exampleInputEmail1" placeholder="Phone Number" onChange={handleChange} />
                                    <span className='text-danger'>{formErrors.phone}</span>
                                </Form.Group>
                                <Form.Group className="col-6">
                                    <label htmlFor="exampleInputUsername1">Email</label>
                                    <Form.Control name='email' value={userData?.email} className={`form-control${formErrors.email ? "border border-danger" : ""}`} type="email" placeholder="Email" onChange={handleChange} />
                                    <span className='text-danger'>{formErrors.email}</span>
                                </Form.Group>
                                <Form.Group className="col-6">
                                    <label htmlFor="exampleInputUsername1">Password</label>
                                    <Form.Control name='password' value={userData?.password} className={`form-control${formErrors.password ? "border border-danger" : ""}`} type="text" placeholder="Password" onChange={handleChange} />
                                    <span className='text-danger'>{formErrors.password}</span>
                                </Form.Group>
                                <button type="submit" className="btn btn-warning btn-sm mt-2 me-2">
                                    Submit
                                    {
                                        loader && <div class="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm" role="status"></div>
                                    }
                                </button>
                                <button className="btn btn-light btn-sm mt-2" onClick={e => {e.preventDefault();navigate(-1)}}>Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </MainTemplate>
    )
}

export default CreateUser