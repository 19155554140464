import React from "react";
import { Form, Spinner } from "react-bootstrap";
import baseUrl from "../../../Api/baseUrl";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import csvDownload from "json-to-csv-export";
import Modal from 'react-modal';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainTemplate from "../../../components/MainTemplate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { debounce, formatDateString, gridOptions } from "../../../utils/helperFunctin";
import Select from 'react-select';

const UserList = () => {
	const casinoRef = useRef(null)
	const bettingRef = useRef(null)
	const navigate = useNavigate()

	const token = localStorage.getItem("token");
	const user = JSON.parse(localStorage.getItem("user"))	
	const { state: agent } = useLocation();
	
	const [users, setUsers] = useState([]);
	const [agents, setAgents] = useState([])
	const [filter, setFilter] = useState({agent: agent ? agent : ""})
	const [modal, setModal] = useState({show: false, data: {}})
	const [loader, setLoader] = useState(false)


	const showTransaction = user => navigate("/user-wallet-history", {
		state: {
			...user, 
			value: user?._id, 
			label: user?.name+", "+user?.email+", "+user?.phone
		}
	})

	const columnDefs = [
		{ headerName: 'Sr.No.', valueGetter: 'node.rowIndex + 1', width: 80 },
		{ headerName: 'User Name', field: 'username' },
		{ headerName: 'Name', field: 'name' },
		{ headerName: 'Email', field: 'email' },
		{ headerName: 'Phone', field: 'phone', width: 150 },
		{ headerName: 'Agent Name', field: "agentId.name" ,valueGetter: (params) => params?.data?.agentId?.name,  width: 150 },
		{ headerName: 'Agent Phone', field: "agentId.phone" , width: 150 },
		{
		  headerName: 'Wallet Amount',
		  field: 'walletAmount',
		  cellRenderer: (params) => (
			<span className="d-flex justify-content-between">
				{!isNaN(params?.data?.walletAmount) ? params?.data?.walletAmount : !isNaN(params?.data?.balance) ? params?.data?.balance : "-"}
				<span onClick={() => setModal({show: true, type:"money", data: params?.data})}>
					<button className="btn btn-warning btn-sm">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
							<path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/>
						</svg>													
						Add
					</button>
				</span>
			</span>
		  ),
		},
		{ 
			headerName: 'Show Transactions',
			cellRenderer: (params) => <button className="btn btn-warning btn-sm" onClick={()=> showTransaction(params?.data)}> Show All Transaction </button>
		},	
		{ 
			headerName: 'Created At', 
			field: 'createdAt', 
			cellRenderer: (params) => formatDateString(params.value), 
			valueGetter: (params) => formatDateString(params.data?.createdAt) 
		},
		{
			headerName: 'Status',
			field: 'status',
			cellRenderer: (params) => (
				<select value={params?.data?.status} className={`form-control form-control-sm`} onChange={e => updateUserStatus(params?.data?._id, e.target.value)}>
					<option value=''>Select Status</option>
					<option value='ACTIVE'>Active</option>
					<option value='SUSPENDED'>Suspended</option>
					<option value='LOCKED'>Blocked</option>
				</select>
			)
		},
		{
			headerName: 'Tag',
			field: 'tag',
			width: 150,
			cellRenderer: (params) => (
				<select value={params?.data?.tag} className={`form-control form-control-sm`} onChange={e => updateUserTag(params?.data?._id, e.target.value)}>
					<option value=''>Select a Tag</option>
					<option value='CHEATER'>Cheater</option>
					<option value='BONUS_HUNTER'>Bonus hunter</option>	
					<option value='NORMAL'>Normal</option>
					<option value='VIP'>VIP</option>
					<option value='SUPER_VIP'>Super VIP</option>
				</select>
			)
		},
		{ 
			headerName: 'Max Exposure', 
			field: 'maxExposure',
			width: 180,
			cellRenderer: params => <div className="d-flex justify-content-between">
				<span className="px-3npm st">{!isNaN(params?.data?.maxExposure) ? params?.data?.maxExposure : "-"}</span>
				<button onClick={() => setModal({show: true, type:"exposure", data: params?.data})} className="btn btn-warning  btn-sm">
					<svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
						<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
					</svg>
				</button>
			</div>
		},
		{
			headerName: 'Allowed Games',
			width: 200, 
			cellRenderer: (params) => (
				<div className=" d-flex justify-content-between">
					<div className="form-check d-flex align-items-center me-3 position-relative">
						<input 
							ref={casinoRef} 
							className="form-check-input me-2" 
							name="access" 
							type="checkbox" 
							value="casino" 
							onChange={e => updateUserAccess(e, params?.data)}
							checked={params?.data?.access?.includes("casino")}
						/>
						<label className="form-check-label">
							Casino
						</label>
					</div>
					<div className="form-check d-flex align-items-center me-3">
						<input 
							ref={bettingRef} 
							className="form-check-input me-2" 
							name="access" 
							type="checkbox" 
							value="exchange" 
							onChange={e => updateUserAccess(e, params?.data)} 
							checked={params?.data?.access?.includes("exchange")}
						/>
						<label className="form-check-label">
							Betting
						</label>
					</div>
				</div>
			),
		},
		{ 
			headerName: 'Action', 
			sortable: true,
			width: 100,
			cellRenderer: (params) => (
				params?.data?.status === "LOCKED"
				?
				""
				:
				<div className="d-flex align-items-center justify-content-center">
					<span className="px-2" onClick={() => user?.type === "affiliateAdmin" ? navigate('/create-affiliate-users',{state:params?.data}) : navigate('/create-users',{state:params?.data})}>
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
							<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z"/>
						</svg>
					</span>
				</div>
			)
		},
	];

	const customStyles = {
		content: {
		  top: '50%',
		  left: '50%',
		  right: 'auto',
		  bottom: 'auto',
		  marginRight: '-50%',
		  transform: 'translate(-50%, -50%)'
		},
		overlay: {
			background: "rgba(0,0,0,0.7)",
			zIndex: 999999
		}
	};

	const getData = async () => {
		try{
			setLoader(true)
			const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
			let apiUrl = ""
			if(user?.type === "affiliateAdmin"){
				apiUrl = baseUrl + "/admin/affiliate-user-list?" 				
			} else {
				apiUrl = baseUrl + "/admin/all_user_list?" 
			}
			// filters
			apiUrl += `${filter?.search ? `search=${filter?.search}&` : ""}`
			+ `${filter?.dateFrom ? `dateFrom=${filter?.dateFrom}&` : ""}`
			+ `${filter?.dateTo ? `dateTo=${filter?.dateTo}&` : ""}`
			+ `${filter?.status ? `status=${filter?.status}&` : ""}`
			+ `${filter?.tag ? `tag=${filter?.tag}&` : ""}`
			+ `${filter?.minExposure ? `minExposure=${filter?.minExposure}&` : ""}`
			+ `${filter?.maxExposure ? `maxExposure=${filter?.maxExposure}&` : ""}`
			+ `${filter?.minAmount ? `minAmount=${filter?.minAmount}&` : ""}`
			+ `${filter?.maxAmount ? `maxAmount=${filter?.maxAmount}&` : ""}`
			+ `${filter?.agent ? `agent=${filter?.agent?._id}&` : ""}`
			
			const res = await axios.get(apiUrl, headers)
			if(res?.data?.data){
				setLoader(false)
				setUsers(res?.data?.data?.reverse())
			}
		} catch(err){
			console.log(err)
			setLoader(false)
		}
	}

	const getAgentsData = async () => {
		try{
			const headers = { 
				headers: { "Content-Type": "application/json", 
				"x-access-token": token,
			}}
			const res = await axios.get(baseUrl + "/admin/affiliate-agent-list?" , headers)
			if(res?.data?.data){
				setAgents(res?.data?.data?.reverse()?.map(el => ({...el, value: el?._id, label: el?.name+", "+el?.email+", "+el?.phone})))
			}
		} catch(err){
			console.log(err)
			setLoader(false)
		}
	}

	const saveAmount = async (el) => {
		try{
			setModal({...modal, loader: true})
			const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
			const res = await axios.post(baseUrl + "/admin/manual_credit", {userId: el?._id, amount: el?.addAmount, collectedAmount: el?.collectedAmount}, headers)
			if(res?.data?.data){
				toast.success("Updated", { position: "top-center", autoClose: 2000 });
				setUsers(users?.map(el => ({...el, edit: false})))
				setModal({})
				getData()
			}
		} catch(err){
			setModal({...modal, loader: false})
			console.log(err)
		}
	}

	const updateUserStatus = async (userId, status) => {
		try{
			if(userId && status){
				const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
				const res = await axios.put(baseUrl + "/admin/agent/updatestatus-user", {id: userId, status}, headers)
				if(res?.data?.data){
					toast.success("Updated", { position: "top-center", autoClose: 2000 });
					getData()
				}
			}
		} catch(err){
			setModal({...modal, loader: false})
			console.log(err)
		}
	}

	const updateUserTag = async (userId, tag) => {
		try{
			if(userId && tag){
				const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
				const res = await axios.put(baseUrl + "/admin/agent/updatestatus-user", {id: userId, tag}, headers)
				if(res?.data?.data){
					toast.success("Updated", { position: "top-center", autoClose: 2000 });
					getData()
				}
			}
		} catch(err){
			setModal({...modal, loader: false})
			console.log(err)
		}
	}

	const updateMaxExposure = async (data) => {
		try{
			const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
			const res = await axios.put(baseUrl + "/admin/agent/updatestatus-user", {id: data?._id, maxExposure: data?.maxExposure}, headers)
			if(res?.data?.data){
				toast.success("Updated", { position: "top-center", autoClose: 2000 });
				setModal({})
				getData()
			}
		} catch(err){
			setModal({...modal, loader: false})
			console.log(err)
		}
	}

	const updateUserAccess = async (e, user, type) => {
		try{
			if(user?._id){
				let data = {
					id: user?._id,
					access: []
				}
				if(e.target.value === "casino"){
					const bettingChecked = user?.access?.includes("exchange")
					if(bettingChecked){
						data.access.push("exchange")
					}
					if(e.target.checked){
						data.access.push("casino")
					}
				} else if(e.target.value === "exchange"){
					const casinoChecked = user?.access?.includes("casino")
					if(casinoChecked){
						data.access.push("casino")
					}
					if(e.target.checked){
						data.access.push("exchange")
					}
				}

				console.log(data?.access, 3434434)
				const headers = { headers: { "Content-Type": "application/json", "x-access-token": token, } }
				const res = await axios.put(baseUrl + "/admin/agent/updatestatus-user", data, headers)
				if(res?.data?.data){
					toast.success("Updated", { position: "top-center", autoClose: 2000 });
					getData()
				}
			}
		} catch(err){
			setModal({...modal, loader: false})
			console.log(err)
		}
	}

	const handleCSVDownload = () => csvDownload({ 
		data: users?.map(el => ({
			id: el?._id,
			name: el?.name,
			phone: el?.phone,
			email: el?.email,
			username: el?.username,
			status: el?.status ? el?.status : "-",
			walletAmount: !isNaN(el?.walletAmount) ? el?.walletAmount : "-",
		})),
		delimiter: ",", 
		filename:"Betkaro-User-"+formatDateString(new Date()) 
	})

	useEffect(() => {
		getAgentsData()
		getData()
	}, [])

	return (
		<MainTemplate
			categoryName="Users List"
			categoryList={["Manage Users", "Users List"]}
		>
		<div>
		<div className="">
			<div className="row ml-1 mb-2">
				<div className="col-12 ">
					<div className="row">
						<div className="col-3 mb-1">
							<label><small>Search</small></label>
							<input className="form-control form-control-sm" type="text" placeholder="Search" onChange={e => setFilter({...filter, search: e.target.value})}/>
						</div>
						<div className="col-3 mb-1">
							<label><small>Date From</small></label>
							<input className="form-control form-control-sm" type="date" max={new Date().toISOString().split('T')[0]} onChange={e => setFilter({...filter, dateFrom: e.target.value})}/>
						</div>
						<div className="col-3 mb-1">
							<label><small>Date To</small></label>
							<input className="form-control form-control-sm" type="date" max={new Date().toISOString().split('T')[0]} onChange={e => setFilter({...filter, dateTo: e.target.value})}/>
						</div>
						<div className="col-3 mb-1">
							<label><small>Select Tag</small></label>
							<select value={filter?.tag} className={`form-control form-control-sm`} onChange={e => setFilter({...filter, tag: e.target.value})}>
								<option value=''>Select a Tag</option>
								<option value='CHEATER'>Cheater</option>
								<option value='BONUS_HUNTER'>Bonus hunter</option>	
								<option value='NORMAL'>Normal</option>
								<option value='VIP'>VIP</option>
								<option value='SUPER_VIP'>Super VIP</option>
							</select>						
						</div>
						<div className="col-3 mb-1">
							<label><small>Select Status</small></label>
							<select value={filter?.status} className={`form-control form-control-sm`} onChange={e => setFilter({...filter, status: e.target.value})}>
								<option value=''>Select Status</option>
								<option value='ACTIVE'>Active</option>
								<option value='SUSPENDED'>Suspended</option>
								<option value='LOCKED'>Blocked</option>
							</select>						
						</div>
						<div className="col-3 mb-1">
							<label><small>Search Exposure</small></label>
							<div className="d-flex">
								<input className="form-control form-control-sm me-1" type="number" placeholder="Min Exposure" onChange={e => setFilter({...filter, minExposure: e.target.value})}/>				
								<input className="form-control form-control-sm" type="number" placeholder="Max Exposure" onChange={e => setFilter({...filter, maxExposure: e.target.value})}/>				
							</div>
						</div>
						<div className="col-3 mb-1">
							<label><small>Wallet Balance</small></label>
							<div className="d-flex">
								<input className="form-control form-control-sm me-1" type="number" placeholder="Min Balance" onChange={e => setFilter({...filter, minAmount: e.target.value})}/>									
								<input className="form-control form-control-sm" type="number" placeholder="Max Balance" onChange={e => setFilter({...filter, maxAmount: e.target.value})}/>									
							</div>
						</div>
						<div className="col-3 mb-1 d-flex align-items-end">
							<button type="button" className="btn btn-primary btn-sm btn-fw me-2 w-50" onClick={handleCSVDownload}>Export</button>
							<Link to={user?.type === "affiliateAdmin" ? "/create-affiliate-users" : "/create-users"}><button type="button" className="btn btn-warning btn-sm px-3 btn-fw" >Add User</button></Link>
						</div>
						{
							user?.type === "admin"
							?
							<div className="col-3 mb-1">
								<label><small>Search Agent</small></label>
								<div className="d-flex">
								<Select className="basic-single w-100" isClearable={true} options={agents} value={filter?.agent} onChange={e => setFilter({...filter, agent: e})}/>
									{/* <input className="form-control form-control-sm me-1" type="number" placeholder="Min Balance" onChange={e => setFilter({...filter, minAmount: e.target.value})}/>									
									<input className="form-control form-control-sm" type="number" placeholder="Max Balance" onChange={e => setFilter({...filter, maxAmount: e.target.value})}/>									 */}
								</div>
							</div>
							:
							""
						}
						<div className="col-3 mb-1 d-flex align-items-end">
							<button type="button" className="btn btn-warning btn-sm w-100" onClick={getData}>Search</button>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
			<div className="col-lg-12">
				<div className="col-lg-12 grid-margin stretch-card">
					<div className="card">
						<div className="ag-theme-alpine" style={{ width: '100%' }}>
							<AgGridReact
								columnDefs={columnDefs}
								rowData={users}
								domLayout={gridOptions.domLayout}
								enableCellTextSelection={true}
								pagination={gridOptions.pagination}
								paginationPageSize={gridOptions.paginationPageSize}
								suppressCellSelection={true}
							/>
						</div>
					</div>
				</div>
			</div>
			</div>
		</div>
		<ToastContainer />

		<Modal
			isOpen={modal?.type === "money"}
			onRequestClose={() => setModal({})}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<h1 className="text-center mb-5">Add Money</h1>
			<div className="mb-2">Email: {modal?.data?.email}</div>
			<div className="mb-2">Phone: {modal?.data?.phone}</div>
			<div className="mb-4">Wallet Amount: {modal?.data?.walletAmount || modal?.data?.balance}</div>
			
			<h5>Enter Point</h5>
			<input 
				className="form-control w-100 mb-2 p-2 border-dark" 
				type="number" 
				placeholder="Enter Point to add"  
				value={modal?.data?.addAmount} 
				onChange={e => setModal({...modal, data: {...modal?.data, addAmount: e.target.value}})}
				maxLength={8}
				onInput={(event) => {
					if (event.target.value.length > event.target.maxLength) {
						event.target.value = event.target.value.slice(0, event.target.maxLength)
					}
				}}
			/>
			<span className="fw-bold">Collected rate (in %)</span>
				<input 
					className="form-control w-100 mb-2 p-2 border-dark" 
					type="number" 
					placeholder="Enter collected rate"  
					value={modal?.data?.collectedAmount} 
					onChange={e => setModal({...modal, data: {...modal?.data, collectedAmount: e.target.value}})}
					maxLength={3}
					onInput={(event) => {
						if(event.target.value < 1){
							event.target.value = 1
							return;
						}
						if(event.target.value > 100){
							event.target.value = 100
							return;
						}
						if (event.target.value.length > event.target.maxLength) {
							event.target.value = event.target.value.slice(0, event.target.maxLength)
						}
					}}
				/>
				{
					!isNaN(modal?.data?.addAmount) && !isNaN(modal?.data?.collectedAmount)
					?
					<small>Collected Cash: ₹{modal?.data?.addAmount*modal?.data?.collectedAmount/100 }</small>
					:
					""
				}
				<br />
			<button type="button" disabled={modal?.loader} className="btn btn-warning btn-sm  mt-2 w-100" onClick={() => saveAmount(modal?.data)}>
				{
					modal?.loader
					?
					<div class="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm" role="status" />
					:
					"Add"
				}
			</button>
		</Modal>

		<Modal
			isOpen={modal?.type === "exposure"}
			onRequestClose={() => setModal({})}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<h1 className="text-center mb-5">Set Exposure</h1>
			<div className="mb-2">Email: {modal?.data?.email}</div>
			<div className="mb-2">Phone: {modal?.data?.phone}</div>
			<div className="mb-4">Wallet Amount: {modal?.data?.walletAmount || modal?.data?.balance}</div>
			
			<h5>Enter Exposure</h5>
			<input 
				className="w-100 mb-3 p-2 border-dark form-control" 
				type="number" 
				placeholder="Enter Max Exposure"  
				value={modal?.data?.maxExposure} 
				onChange={e => setModal({...modal, data: {...modal?.data, maxExposure: e.target.value}})}
				maxLength={8}
				onInput={(event) => {
					if (event.target.value.length > event.target.maxLength) {
						event.target.value = event.target.value.slice(0, event.target.maxLength)
					}
				}}
			/>
			<button type="button" disabled={modal?.loader} className="btn btn-warning w-100" onClick={() => updateMaxExposure(modal?.data)}>
				{
					modal?.loader
					?
					<div class="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm" role="status" />
					:
					"Save Exposure"
				}
			</button>
		</Modal>
		</div>
		</MainTemplate>
	);
};

export default UserList;
