export const checkPasswordValidity = str => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
}
export const validateEmail = email => String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
export const emailValidity = str => {
  var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return re.test(str);
}

export const validatePassword = str => {
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
}

export const isValidIFSC = (ifscCode) => {
  // Regular expression for IFSC code pattern
  var ifscPattern = /^[A-Za-z]{4}\d{7}$/;

  // Check if the provided code matches the pattern
  return ifscPattern.test(ifscCode) ? true : false
}

export const isValid_UPI_ID = upi_Id => {
  const regex = /^[a-zA-Z0-9_\\.]+@[a-zA-Z0-9]+$/;
  return regex.test(upi_Id)
}

export const isValid_Bank_Acc_Number = bank_account_number => {
  const regex = new RegExp(/^[0-9]{9,18}$/);
  return regex.test(bank_account_number)
}

export const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: "60vw",
      transform: 'translate(-50%, -50%)',
      overflow: 'hidden'
    },
    overlay: {
        background: 'rgba(0,0,0,0.2)',
        zIndex: '9'
    }
};

export const custombulkModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: "581px",
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden'
  },
  overlay: {
      background: 'rgba(0,0,0,0.2)',
      zIndex: '9'
  }
};

export const customActionModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: "500px",
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden'
  },
  overlay: {
      background: 'rgba(0,0,0,0.2)',
      zIndex: '9'
  }
};

export const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
}

export const ROLES = Object.freeze({
  All: "All",
  Finance: "Finance",
  Dashboard: "Dashboard",
  Users: "Users",
  Profile: "Profile",
  Market: "Market",
  Report: "Report",
  Casino: "Casino",
  Notification: "Notification",
  SiteSetting: "Site Setting",
  AffiliateProgram: "Affiliate Program",
})

export const gridOptions = {
  pagination: true,
  paginationPageSize: 10,
  domLayout: 'autoHeight',
  defaultColDef: {
    resizable: true,
    filter: true, // Enable filters by default
  }
};

export const formatDateString = (inputDateString) => {
  const date = new Date(inputDateString);

  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  return new Intl.DateTimeFormat("en-IN", options).format(date);
}