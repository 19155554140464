import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { fetchAllEvents, sports_Status } from '../../../Api/api'
import baseUrl from '../../../Api/baseUrl'
import '../SportsSetting/SportsSetting.css'
import MainTemplate from '../../../components/MainTemplate'

function SportsSetting() {
    const [eventsData, setEventsData] = useState([])
    const [loader, setLoader] = useState(false)


    const getAllSportsData = async () => {
        setLoader(true)
        const res = await axios.get(baseUrl + fetchAllEvents)
        if (res.status == 200) {
            setLoader(false)
            setEventsData(res.data.data)

        } else {
            setLoader(false)
        }
    }


    const handleClickCheckBox = async (el) => {
        try {
            console.log(12312312312)
            const status = el?.status == false ? true : false
            const eventId = +el?.eventType
            const token = localStorage.getItem("token");
            const headers = {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": token,
                },
            };
            const responce = await axios.put(baseUrl + sports_Status + `/${status}/${eventId}`, {}, headers)
            if (responce.status == 200) {
                toast.success('Updated', { position: "top-center", autoClose: 2000 });
                getAllSportsData()
            } else {
                getAllSportsData()
            }
        } catch (error) {
            toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
            getAllSportsData()
        }
    }


    useEffect(() => {
        getAllSportsData()
    }, [])
    return (
        <MainTemplate
            categoryName="Sports Setting"
            categoryList={["Market Setting", "Sports Setting"]}
        >
        <div>
            <ToastContainer />
            <div className="">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="">
                                    <div className="table-responsive">
                                        <table className="table table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th> Sr.No. </th>
                                                    <th> Sports Name </th>
                                                    <th> Action </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !loader && eventsData.map((ele, ind) => {
                                                        return <tr key={ind}>
                                                            <td>{ind + 1}</td>
                                                            <td>{ele.name}</td>
                                                            <td className='d-flex' >
                                                                <label class="switch" checked={ele?.status == false ? false : true} onChange={() => handleClickCheckBox(ele)} >
                                                                    <input class="switch-input" type="checkbox" checked={ele?.status == false ? false : true} />
                                                                    <span class="switch-label"></span>
                                                                    <span class="switch-handle"></span>
                                                                </label>
                                                                <span>
                                                                    {
                                                                        ele?.status == false
                                                                            ?
                                                                            "UnChecked"
                                                                            :
                                                                            "Checked"
                                                                    }
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        {
                                            loader
                                                ?
                                                <div className="d-flex justify-content-center">
                                                    <div class="d-flex justify-content-center">
  <div class="spinner-border spinner-border-sm" role="status">
    <span class="sr-only"></span>
  </div>
</div>
                                                </div>
                                                :
                                                ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </MainTemplate>
    )
}

export default SportsSetting