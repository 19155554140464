import React from 'react'
import { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import baseUrl from '../../../Api/baseUrl';
import axios from 'axios'
import { fetchAllSports, getFancySettings, getMarketSettings, market_Status } from "../../../Api/api"
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../SportsSetting/SportsSetting.css'
import MainTemplate from '../../../components/MainTemplate';
import csvDownload from 'json-to-csv-export';
import { formatDateString, gridOptions } from '../../../utils/helperFunctin';
import { AgGridReact } from 'ag-grid-react';

function FancySettings() {

    let index = 0
    const [data, setData] = useState([])
    const [sportsData, setSportsData] = useState([])
    const [status, setStatus] = useState();
    const [selectedSports, setSelectedSports] = useState("")
    const [seriesData, setSeriesData] = useState([])
    const [selectedSeries, setSelectedSeries] = useState("")
    const [matchesData, setMatchesData] = useState([])
    const [selectedMatch, setSelectedMatch] = useState("")
    const [loader, setLoader] = useState(false)
    const [checkInData, setCheckInData] = useState({ CheckIn: false, data: "" })
    const [checkIn, setCheckIn] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [matchId, setMatchId] = useState('')
    const [selectionId, setSelectionId] = useState('')
    const [matchMarkets, setMatchMarkets] = useState({})
    const [declareList, setDeclareList] = useState({ data: [], loader: false })
    const [search, setSearch] = useState();
    const [date, setDate] = useState({});

    const handleClose = () => {
        setIsModalOpen(false);
    };
    const [selectedRunner, setSelectedRunner] = useState("");
    const [selectedRunnerError, setSelectedRunnerError] = useState("");

    function handleRunnerSelect(event) {
        setSelectedRunner(event.target.value);
    }

    async function handleSubmit(event) {
        event.preventDefault();
        if(!(declareList?.selected || declareList?.value)){
            toast.error('Declaration Required', { position: "top-center", autoClose: 2000 });
            return
        }
        try {
            

            setDeclareList({ ...declareList, loader: true })
            const payload = {
                selectionId: declareList?.data?.SelectionId,
                matchId: declareList?.data?.matchId,
                status:"declare",
                result: declareList?.value ? declareList?.value : (declareList?.selected === "Yes" ? 1 : 0),
                gtype: declareList?.data?.gtype
            }

            const Token = localStorage.getItem("token");
            const headers = {
                headers: {
                    "x-access-token": Token,
                },
            };

            let declareResult = await axios.post(baseUrl + '/admin/event/fancydeclare', payload, headers)
            if (declareResult.status = 200) {
                setDeclareList({ ...declareList, loader: false })
                toast.success('Result declared', { position: "top-center", autoClose: 2000 });
                handleClose()
                getData()
                handleCleare()
            }
            else {
                handleClose()
                setDeclareList({ ...declareList, loader: false })
                handleCleare()
            }

        } catch (error) {
            toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
            handleClose()
            setDeclareList({ ...declareList, loader: false })
            handleCleare()
        }
    }

    const handleCleare = () => {
        setSelectedRunnerError("")
        setMatchId("")
        setSelectionId("")
        setSelectedRunner("")
    }


    const getData = async () => {
        setLoader(true)
        const res = await axios.get(baseUrl + getFancySettings + `?` +
            `${status ? `status=${status === "true" ? true : status ==="false" ? false : "" }` : ""}&` +
            `${selectedSports?.competition?.id ? `seriousId=${selectedSports?.competition?.id}` : ""}` +
            `${search ? `&search=${search}` : ""}`+
            `${date?.dateFrom ? `&dateFrom=${date?.dateFrom}` : ""}`+
            `${date?.dateTo ? `&dateTo=${date?.dateTo}` : ""}`
        )
        if (res?.data?.data) {
            setData(res?.data?.data)
        }
        setLoader(false)
    }


    const getAllSportsData = async () => {
        const res = await axios.get(baseUrl + fetchAllSports)
        if (res?.data?.data) {
            setSportsData(res?.data?.data)
        }
    }

    const handleSportsChange = e => {
        const sportsId = e.target.value
        const foundSports = sportsData?.find(el => el?.competition?.id === sportsId)
        setSelectedSports(foundSports)
    }

    useEffect(() => {
        getAllSportsData()
    }, [])

    const handleCheck = async (ele) => {
        setCheckInData({ ...checkInData, CheckIn: !checkInData?.CheckIn, data: ele })
        setCheckIn(!checkIn)
    }

    const handleCheckVerify = async () => {
        const token = localStorage.getItem("token");
        const headers = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
            },
        };
        const responce = await axios.put(baseUrl + market_Status +
            `${checkInData?.data?.status == false ? `/true` : "/false"}` +
            `${checkInData?.data ? `/${checkInData?.data?.selectionId}` : ""}`, {},
            headers
        )
        if (responce.status == 200) {
            toast.success(responce?.data?.data, { position: "top-center", autoClose: 2000 });
            getData()
            setCheckInData({ ...checkInData, CheckIn: false, data: [] })
        } else {
            getData()
            setCheckInData({ ...checkInData, CheckIn: false, data: [] })
        }
    }

    const handleDeclaration = async (el) => {
        setSelectionId(el?.SelectionId)
        setMatchId(el?.matchId)
        setIsModalOpen(true)
        setDeclareList({ ...declareList, data: el })
    }

    useEffect(() => {
        if (selectedRunner) {
            setSelectedRunnerError("")
        }
    }, [selectedRunner])

    useEffect(() => {
        handleCheckVerify()
    }, [checkIn])

    // useEffect(() => {
    //     getMatchData()
    // }, [matchId])

    useEffect(() => {
        getData()
    }, [
        // selectedSports, status, search, date
        // selectedSeries, 
        // selectedMatch
    ])

    const handleCSVDownload = () => csvDownload({ 
		data: data?.map(el => ({
			id: el?._id,
			MatchName: el?.matchInfo.event.name,
			MatchDate: formatDateString(el?.matchInfo?.event?.openDate),
			RunnerName: el?.RunnerName,
			SelectionId: el?.SelectionId,
			MatchId: el?.matchId
		})),
		delimiter: ",", 
		filename:"Betkaro-fancySetting-"+formatDateString(new Date()) 
	})


    const columnDefs = [
        { headerName: 'Sr.No.', valueGetter: 'node.rowIndex+1', width: 80},
        { headerName: 'Match Name', field: 'matchInfo.event.name' },
        { headerName: 'Match Date', cellRenderer: (params) => formatDateString(params?.data?.matchInfo?.event?.openDate)},
        { headerName: 'Runner Name', field: 'RunnerName' },
        { headerName: 'Selection Id', field: 'SelectionId' },
        { headerName: 'Match Id', field: 'matchId' },
        {
          headerName: 'Declare Result',
          cellRenderer: (params) => (
            params?.data?.matchStatus === "declared"
            ?
            <button
              type="submit"
              disabled={true}
              className="btn btn-secondary btn-sm mr-2"
            >
              Declared
            </button>
            :
            <button
              type="submit"
              disabled={params.data.status === false}
              className="btn btn-warning btn-sm mr-2"
              onClick={() => handleDeclaration(params.data)}
            >
              Declare
            </button>
          ),
        },
    ];

    return (
        <MainTemplate
            categoryName="Fancy Setting"
            categoryList={["Market Setting", "Fancy Setting"]}
        >
            <div>
                <ToastContainer />
                <div>
                    <div className="">
                        <div className="row">
                            <div className="col-lg-12 mb-2">
                                <div className="col-lg-12 stretch-card">
                                    <div className="">
                                        <div className="table-responsive">
                                            <table className="table table-borderless mb-0">
                                                <thead>
                                                <tr>
                                                    <th> Match Search </th>
                                                    <th className="row m-0"> 
                                                        <span className="col-6">Date From</span> 
                                                        <span className="col-6">Date To</span> 
                                                    </th>
                                                    <th> </th>
                                                    <th> </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <input className="form-control " type="text" placeholder="Search" onChange={e => setSearch( e.target.value)}/>
                                                    </td>
                                                    <td className="d-flex">
                                                        <input className="form-control  me-2" type="date" onChange={e => setDate({...date, dateFrom: e.target.value})}/>
                                                        <input className="form-control " type="date" onChange={e => setDate({...date, dateTo: e.target.value})}/>
                                                    </td>
                                                    
                                                    <td>
                                                        <div className="d-flex align-items-start">
                                                            <button
                                                                type="submit"
                                                                onClick={getData}
                                                                className="btn btn-warning btn-sm w-100"
                                                            >
                                                            Search
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-start">
                                                            <button
                                                                type="submit"
                                                                onClick={handleCSVDownload}
                                                                className="btn btn-primary btn-sm w-100"
                                                            >
                                                            Export
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="col-lg-12 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="ag-theme-alpine" style={{ width: '100%' }}>
                                            <AgGridReact
                                                columnDefs={columnDefs}
                                                rowData={data}
                                                domLayout={gridOptions.domLayout}
                                                enableCellTextSelection={true}
                                                pagination={gridOptions.pagination}
                                                paginationPageSize={gridOptions.paginationPageSize}
                                                suppressCellSelection={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={isModalOpen} onHide={setIsModalOpen}>
                    <Modal.Header closeButton>
                        <Modal.Title>Declare Match Result</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form
                            onSubmit={e => e.preventDefault()}
                        >
                            <Form.Group>
                                <label htmlFor="exampleFormControlSelect3" className="col-form-label">Choose your answer to declare result</label>
                                {
                                    <div key={declareList?.data?.matchId}>
                                        <p><b>{declareList?.data?.matchInfo?.event?.name}</b></p>
                                        {
                                            declareList?.data?.gtype ==="fancy1"
                                            ?
                                            <select className={`${selectedRunnerError != "" ? 'border border-danger' : ''} form-control `} id={`exampleFormControlSelect3`} onChange={e => setDeclareList({...declareList, selected: e.target.value })}>
                                                <option value=''>select answer</option>
                                                <option value='Yes'>Yes</option>
                                                <option value='No'>No</option>
                                            </select>
                                            :
                                            ""
                                        }
                                        {
                                            declareList?.data?.gtype ==="session"
                                            ?
                                            <input className="form-control  w-100" type="number" value={declareList?.value} onChange={e => setDeclareList({...declareList, value: e.target.value})} placeholder="Type Number"  onInput={e => (e.target?.value?.length > e.target?.maxLength) ? e.target.value = e.target.value.slice(0, e.target.maxLength): ""} maxLength={8}/>
                                            :
                                            ""
                                        }
                                    </div>
                                }
                            </Form.Group>
                            <div className="row mt-3">
                                <div className="col-6 m-0">
                                    <button type="submit" className="btn btn-warning w-100" onClick={handleSubmit}>
                                        Declare {" "}
                                        {
                                            declareList.loader
                                            &&
                                            <div className="spinner-border spinner-border-sm spinner-border spinner-border-sm-sm" role="status"></div>
                                        }
                                    </button>
                                </div>
                                <div className="col-6 m-0">
                                    <button type="reset" className="btn btn-danger w-100" onClick={handleCleare}>Clear</button>
                                </div>
                            </div>
                            
                        </Form>
                    </Modal.Body>
                </Modal>
            </div >
        </MainTemplate>
    )
}

export default FancySettings