export const loginPath = "/admin/login"

export const getMarketSettings = "/admin/list_marketss"
export const getFancySettings = "/admin/list_fancy"
export const getBookmakerSettings = "/admin/list_bookmaker"
export const fetchAllSports = "/admin/fetch_all_serious"
export const getMatchSettings = "/admin/list_matches"
export const fetchAllSeries = "/admin/list_all_serious"
export const fetchAllEvents = '/admin/fetch_all_events'
export const bankDetails = '/admin/update_bank'
export const getBankDetails = '/user/account_details_of_admin'
export const withdraRequest = '/admin/withdraw_request_update'
export const withdraRequestList = '/admin/withdraw_request_list'
export const withdraRequestUpdate = '/admin/withdraw_request_update'
export const create_Messages = '/admin/create_messages'
export const get_Message_List = '/admin/list_messages'
export const get_Current_bets = '/admin/current_bets'
export const get_agent_Current_bets = '/admin/affiliate-user-currentbets'
export const get_Online_User = '/admin/online_user'
export const get_Request_Payment_List = '/admin/payment_request_list'
export const get_Wallet_history = '/admin/wallet_history'
export const get_Exposure_List = '/admin/exposure_list'
export const market_Status = '/admin/market_status'
export const sports_Status = '/admin/event_status'
export const roll_back = '/admin/rollback'
export const roll_back_list = '/admin/fetch_all_rollback_list'
export const withdraw_image_upload = '/admin/withdraw_pow'
export const match_details = '/admin/match_status'
export const manage_details = '/admin/series_status'
export const getMessages = '/admin/messages'
export const readMessages = '/admin/view_messages'
// export const current_bets = '/admin/current_bets'















